import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import { Box, Flex, Heading, IconButton, Spinner } from '@radix-ui/themes';
import { DialogButton } from 'components/common/dialogs/button';
import { GlobalContext, IGlobalContext } from 'contexts/global.context';
import { t } from 'i18next';
import { IFullDialog } from 'interfaces/i-dialogs';
import { RADIX } from 'lib_ts/enums/radix-ui';
import React from 'react';

const DEBUG_BORDERS = false;

interface IState {
  open: boolean;
}

export class CommonDialog extends React.Component<IFullDialog, IState> {
  constructor(props: IFullDialog) {
    super(props);

    this.state = {
      open: true,
    };

    this.renderFooter = this.renderFooter.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
  }

  componentDidMount() {
    if (!this.props.ignore) {
      (this.context as IGlobalContext).countDialog({
        id: this.props.identifier,
        open: true,
      });
    }
  }

  componentWillUnmount() {
    if (!this.props.ignore) {
      (this.context as IGlobalContext).countDialog({
        id: this.props.identifier,
        open: false,
      });
    }
  }

  render() {
    const isFlexing = !!this.props.vFlexHeight;

    const hasFooter =
      (this.props.buttons ?? []).filter((b) => !b.invisible).length > 0;

    return (
      <Dialog.Root
        open={this.state.open}
        onOpenChange={(value) => {
          this.setState(
            {
              // this view manages its own open/close so it can always be hidden if parent doesn't unmount it
              open: value,
            },
            () => {
              if (!value) {
                // tell the parent, e.g. so the component can be unmounted
                this.props.onClose();
              }
            }
          );
        }}
        // allows interaction with other elements on the screen while open (e.g. toasts on the side)
        modal={false}
      >
        <div
          className="DialogOverlay"
          onClick={() => {
            if (this.props.modal) {
              // while in modal mode (default), clicking on the overlay closes the dialog
              return;
            }

            const IS_TOUCH_DEVICE = navigator.maxTouchPoints > 0;
            if (IS_TOUCH_DEVICE) {
              // prevents interactions with inputs in the dialog from accidentally closing things
              return;
            }

            this.props.onClose();
          }}
        ></div>

        {this.props.description && (
          <Dialog.Description>{this.props.description}</Dialog.Description>
        )}

        <Dialog.Content
          aria-describedby={this.props.description}
          className={[
            'DialogContent',
            isFlexing ? RADIX.VFLEX.COLUMN : '',
          ].join(' ')}
          style={{
            // force width to match max
            width: this.props.width,
            maxWidth: RADIX.DIALOG.WIDTH.MAX,
            minWidth: RADIX.DIALOG.WIDTH.MIN,

            height: this.props.vFlexHeight,
            maxHeight: RADIX.DIALOG.HEIGHT.MAX,
            minHeight: RADIX.DIALOG.HEIGHT.MIN,

            overflowX: 'hidden',
            overflowY: 'auto',
            border: DEBUG_BORDERS ? '1px solid red' : undefined,
          }}
          // avoid dismissing when clicking on toasts
          onInteractOutside={(e) => e.preventDefault()}
        >
          <Flex
            direction="column"
            gap="5"
            style={{
              height: isFlexing ? '100%' : undefined,
              border: DEBUG_BORDERS ? '1px solid green' : undefined,
            }}
          >
            <Dialog.Title className="DialogTitle">
              {this.renderHeader()}
            </Dialog.Title>

            <Box
              flexGrow={isFlexing ? '1' : undefined}
              overflowX={isFlexing ? 'hidden' : undefined}
              overflowY={isFlexing ? 'auto' : undefined}
              style={{
                border: DEBUG_BORDERS ? '1px solid yellow' : undefined,
              }}
            >
              {this.props.content}
            </Box>

            {hasFooter && this.renderFooter()}
          </Flex>
        </Dialog.Content>
      </Dialog.Root>
    );
  }

  private renderHeader() {
    return (
      <Flex pt={RADIX.FLEX.PAD.XS} justify="between">
        <Flex gap={RADIX.FLEX.GAP.SM} minWidth="0">
          <Box>
            {typeof this.props.title === 'string' ? (
              <Heading size={RADIX.HEADING.SIZE.MODAL_TITLE} mb="0" truncate>
                {t(this.props.title)}
              </Heading>
            ) : (
              this.props.title
            )}

            {this.props.subtitle}
          </Box>

          {this.props.loading && (
            <Box className="valign-center" pb="1">
              <Spinner size="3" />
            </Box>
          )}
        </Flex>

        <IconButton
          color={RADIX.COLOR.NEUTRAL}
          variant="soft"
          className="btn-floating"
          onClick={this.props.onClose}
        >
          <Cross2Icon />
        </IconButton>
      </Flex>
    );
  }

  private renderFooter(): React.ReactNode {
    return (
      <Flex gap={RADIX.FLEX.GAP.SM} justify="between">
        <Box>{this.props.leftFooter}</Box>

        <Flex gap={RADIX.FLEX.GAP.SM}>
          {this.props.buttons
            ?.filter((b) => !b.invisible)
            .map((b, i) => <DialogButton key={i} {...b} />)}
        </Flex>
      </Flex>
    );
  }
}

CommonDialog.contextType = GlobalContext;
