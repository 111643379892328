export enum SectionName {
  // base
  GameInProgress = 'Game In Progress',
  NotFound = 'Not Found',
  Unavailable = 'Unavailable',

  // any user
  Analytics = 'Analytics',
  CalibrateMachine = 'Calibrate Machine',
  HitterLibrary = 'Hitter Library',
  Pitches = 'Pitches',
  QuickSession = 'Quick Session',
  VideoLibrary = 'Video Library',

  // any admin
  AdminPortal = 'Admin Portal',

  // super admin
  FeatureDemo = 'Feature Demo',
}

export enum SubSectionName {
  // pitches
  GameData = 'Game Data',
  Library = 'Library',
  Design = 'Design',
  List = 'List',
  Lists = 'Lists',
  Upload = 'Upload',

  // admin portal
  Users = 'Users',
  Machines = 'Machines',
  Teams = 'Teams',
  MachineModels = 'Machine Models',
  ErrorTypes = 'Error Types',
  StaticVideos = 'Static Videos',
  ApiKeys = 'API Keys',
  ServerControls = 'Server Controls',

  // analytics
  HittingReports = 'Hitting Reports',
  SessionHistory = 'Session History',
  HitterSession = 'Hitter Session',
}
