import { Button } from '@radix-ui/themes';
import { ContactSupportButton } from 'components/common/buttons/contact-support';
import { MachineUnavailableButton } from 'components/machine/buttons/unavailable';
import { MachineContext, MachineDialogMode } from 'contexts/machine.context';
import { CalibrateMode } from 'enums/calibration';
import { QueueState } from 'lib_ts/enums/machine-msg.enum';
import { RADIX, RadixBtnSize } from 'lib_ts/enums/radix-ui';
import { useContext, useMemo } from 'react';

interface IProps {
  /** applied to the button tag */
  className?: string;
  size?: RadixBtnSize;
}

export const MachineCalibrateButton = (props: IProps) => {
  const { calibrating, lastStatus, setDialog } = useContext(MachineContext);

  const mode = useMemo(() => {
    if (lastStatus.queueState !== QueueState.Active) {
      return CalibrateMode.Unavailable;
    }

    if (!lastStatus.can_calibrate) {
      return CalibrateMode.ContactSupport;
    }

    return CalibrateMode.Calibrate;
  }, [lastStatus]);

  switch (mode) {
    case CalibrateMode.Unavailable: {
      return (
        <MachineUnavailableButton
          size={props.size}
          className={props.className}
        />
      );
    }

    case CalibrateMode.ContactSupport: {
      return (
        <ContactSupportButton size={props.size} className={props.className} />
      );
    }

    case CalibrateMode.Calibrate:
    default: {
      if (calibrating) {
        return (
          <Button
            size={props.size}
            color={RADIX.COLOR.WARNING}
            className={props.className}
            disabled
          >
            Realigning...
          </Button>
        );
      }

      return (
        <Button
          size={props.size}
          color={RADIX.COLOR.WARNING}
          className={props.className}
          onClick={() => setDialog(MachineDialogMode.Realign)}
        >
          Realign Machine
        </Button>
      );
    }
  }
};
