import { PlayIcon } from '@radix-ui/react-icons';
import { IconButton } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { CommonTableHoC } from 'components/common/table';
import { HitterSessionHeader } from 'components/sections/analytics/hitter-session/header';
import { HitterSessionToolbar } from 'components/sections/analytics/hitter-session/toolbar';
import useAnalyticsStore from 'components/sections/analytics/store/use-analytics-store';
import { MachineContext } from 'contexts/machine.context';
import { SectionsContext } from 'contexts/sections.context';
import { ACTIONS_KEY, TABLES } from 'enums/tables';
import { t } from 'i18next';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITablePageable } from 'interfaces/tables/pagination';
import { ITableSortable } from 'interfaces/tables/sorting';
import { round } from 'lib_ts/classes/math.utilities';
import { tooltipPitchType } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IExpiringUrlDict } from 'lib_ts/interfaces/common/i-url-dict';
import {
  IFireEventDetails,
  ISessionHit,
} from 'lib_ts/interfaces/i-session-hit';
import { useContext, useEffect, useMemo, useState } from 'react';
import { MainService } from 'services/main.service';
import { HitterSessionVideoDialog } from './video-dialog';

const IDENTIFIER = TableIdentifier.HitterSession;
const PAGE_SIZES = TABLES.PAGE_SIZES.MD;

const roundToZero = (v?: number) => (v?.toFixed(1) ? round(v, 0) : null);
const roundToOne = (v?: number) => (v?.toFixed(1) ? round(v, 1) : null);

interface IGenerateHitterSessionColumnsParams {
  onPlay: (fire: IFireEventDetails) => void;
}

const generateHitterSessionColumns = ({
  onPlay,
}: IGenerateHitterSessionColumnsParams): ITableColumn[] => [
  {
    label: 'common.pitch',
    key: 'pitch',
    formatFn: ({ pitch }: ISessionHit) => pitch.name ?? '',
    sortRowsFn: (a: ISessionHit, b: ISessionHit, dir: number) =>
      (b.pitch.name ?? '').localeCompare(a.pitch.name ?? '') * dir,
  },
  {
    label: 'common.type',
    key: 'type',
    formatFn: ({ pitch }: ISessionHit) => pitch.type,
    tooltipFn: ({ pitch }: ISessionHit) =>
      t(tooltipPitchType(pitch.type)).toString(),
    sortRowsFn: (a: ISessionHit, b: ISessionHit, dir: number) =>
      b.pitch.type.localeCompare(a.pitch.type) * dir,
  },
  {
    label: 'common.speed',
    subLabel: 'mph',
    key: 'speed',
    align: 'right',
    formatFn: ({ pitch }: ISessionHit) => roundToOne(pitch.speedMPH) ?? '-',
    sortRowsFn: (a: ISessionHit, b: ISessionHit, dir: number) =>
      (a.pitch.speedMPH - b.pitch.speedMPH) * dir,
  },
  {
    label: 'common.v-break',
    subLabel: 'in',
    key: 'zBreakIN',
    align: 'right',
    formatFn: ({ pitch }: ISessionHit) => roundToOne(pitch.zBreakIN) ?? '-',
    sortRowsFn: (a: ISessionHit, b: ISessionHit, dir: number) =>
      ((a.pitch.zBreakIN ?? 0) - (b.pitch.zBreakIN ?? 0)) * dir,
  },
  {
    label: 'common.h-break',
    subLabel: 'in',
    key: 'xBreakIN',
    align: 'right',
    formatFn: ({ pitch }: ISessionHit) => roundToOne(pitch.xBreakIN) ?? '-',
    sortRowsFn: (a: ISessionHit, b: ISessionHit, dir: number) =>
      ((a.pitch.xBreakIN ?? 0) - (b.pitch.xBreakIN ?? 0)) * dir,
  },
  {
    label: 'common.result',
    key: 'result',
    formatFn: ({ hit }: ISessionHit) => hit.outcome || '-',
    sortRowsFn: (a: ISessionHit, b: ISessionHit, dir: number) =>
      (a.hit.outcome ?? '').localeCompare(b.hit.outcome ?? '') * dir,
  },
  {
    label: 'common.ev',
    subLabel: 'mph',
    key: 'exitMPH',
    align: 'right',
    formatFn: ({ hit }: ISessionHit) => roundToOne(hit.exitMPH) ?? '-',
    sortRowsFn: (a: ISessionHit, b: ISessionHit, dir: number) =>
      ((a.hit.exitMPH ?? 0) - (b.hit.exitMPH ?? 0)) * dir,
  },
  {
    label: 'common.la',
    subLabel: 'deg',
    key: 'vLaunchDEG',
    align: 'right',
    formatFn: ({ hit }: ISessionHit) => roundToOne(hit.vLaunchDEG) ?? '-',
    sortRowsFn: (a: ISessionHit, b: ISessionHit, dir: number) =>
      ((a.hit.vLaunchDEG ?? 0) - (b.hit.vLaunchDEG ?? 0)) * dir,
  },
  {
    label: 'common.dist',
    subLabel: 'ft',
    key: 'distanceFT',
    align: 'right',
    formatFn: ({ hit }: ISessionHit) => roundToZero(hit.distanceFT) ?? '-',
    sortRowsFn: (a: ISessionHit, b: ISessionHit, dir: number) =>
      ((a.hit.distanceFT ?? 0) - (b.hit.distanceFT ?? 0)) * dir,
  },
  {
    label: '',
    key: ACTIONS_KEY,
    formatFn: ({ fire }: ISessionHit) => (
      <IconButton
        variant="soft"
        size="1"
        disabled={!fire.video}
        onClick={() => onPlay(fire)}
      >
        <PlayIcon />
      </IconButton>
    ),

    disableSort: true,
  },
];

export const HitterSession = () => {
  const {
    loading,
    hitterSession,
    pitchTypeFilter,
    outcomeFilter,
    advancedFilters,
    fetchHitterSession,
  } = useAnalyticsStore();

  const machineCx = useContext(MachineContext);
  const sectionsCx = useContext(SectionsContext);

  const { active } = sectionsCx;
  const { fragments = [] } = active;
  const [sessionID, hitterID] = fragments;

  const [playbackDict, setPlaybackDict] = useState<IExpiringUrlDict>();

  useEffect(() => {
    const paths: string[] = hitterSession
      .map((m) => m.fire.video?.path ?? '')
      .filter((m) => m.length > 0);

    if (paths.length === 0) {
      return;
    }

    console.debug(`signing URLs for ${paths.length} video paths...`);

    MainService.getInstance()
      .signUrls(paths)
      .then((dict) => {
        setPlaybackDict(dict);
      });
  }, [hitterSession]);

  useEffect(() => {
    if (!hitterID) {
      return;
    }

    if (!sessionID) {
      return;
    }

    fetchHitterSession({
      hitterID,
      sessionID,
    });
  }, [fetchHitterSession, hitterID, sessionID]);

  const [playbackDialog, setPlaybackDialog] = useState<number>();

  const filteredHits = useMemo(
    () =>
      hitterSession.filter((m) => {
        if (!m.hit) {
          // shouldn't trigger but just in case the data is bad, it won't crash anymore
          return false;
        }

        const { exitMPH, vLaunchDEG, distanceFT, outcome } = m.hit;
        const { type, speedMPH, xBreakIN, zBreakIN } = m.pitch;

        // Filter by pitch type
        if (pitchTypeFilter.length > 0 && !pitchTypeFilter.includes(type)) {
          return false;
        }

        // Filter by outcome
        if (
          outcomeFilter.length > 0 &&
          (!outcome || !outcomeFilter.includes(outcome))
        ) {
          return false;
        }

        if (!advancedFilters) {
          return true;
        }

        const {
          speedMPH: speedMPHFilter,
          xBreakIN: xBreakINFilter,
          zBreakIN: zBreakINFilter,
          exitMPH: exitMPHFilter,
          vLaunchDEG: vLaunchDEGFilter,
          distanceFT: distanceFTFilter,
        } = advancedFilters;

        // Filter by advanced filter sliders
        if (
          speedMPHFilter.value &&
          (speedMPH === undefined ||
            speedMPH < speedMPHFilter.value[0] ||
            speedMPH > speedMPHFilter.value[1])
        ) {
          return false;
        }

        if (
          xBreakINFilter.value &&
          (xBreakIN === undefined ||
            xBreakIN < xBreakINFilter.value[0] ||
            xBreakIN > xBreakINFilter.value[1])
        ) {
          return false;
        }

        if (
          zBreakINFilter.value &&
          (zBreakIN === undefined ||
            zBreakIN < zBreakINFilter.value[0] ||
            zBreakIN > zBreakINFilter.value[1])
        ) {
          return false;
        }

        if (
          vLaunchDEGFilter.value &&
          (vLaunchDEG === undefined ||
            vLaunchDEG < vLaunchDEGFilter.value[0] ||
            vLaunchDEG > vLaunchDEGFilter.value[1])
        ) {
          return false;
        }

        if (
          exitMPHFilter.value &&
          (exitMPH === undefined ||
            exitMPH < exitMPHFilter.value[0] ||
            exitMPH > exitMPHFilter.value[1])
        ) {
          return false;
        }

        if (
          vLaunchDEGFilter.value &&
          (vLaunchDEG === undefined ||
            vLaunchDEG < vLaunchDEGFilter.value[0] ||
            vLaunchDEG > vLaunchDEGFilter.value[1])
        ) {
          return false;
        }

        if (
          distanceFTFilter.value &&
          (distanceFT === undefined ||
            distanceFT < distanceFTFilter.value[0] ||
            distanceFT > distanceFTFilter.value[1])
        ) {
          return false;
        }

        return true;
      }),
    [hitterSession, pitchTypeFilter, outcomeFilter, advancedFilters]
  );

  const hitsWithVideos = useMemo(
    () => filteredHits.filter((h) => h.fire.video?.path),
    [filteredHits]
  );

  // defines the starting hit for the dialog, when user clicks to play a specific video
  const [hitIndex, setHitIndex] = useState<number>(-1);

  const pagination: ITablePageable = {
    total: filteredHits.length,
    enablePagination: true,
    identifier: IDENTIFIER,
    pageSizes: PAGE_SIZES,
  };

  const sort: ITableSortable = {
    enableSort: true,
  };

  const columns = useMemo(
    () =>
      generateHitterSessionColumns({
        onPlay: async (fire) => {
          // if we want to enable the button and show toasts to upsell
          if (!fire.video) {
            if (!machineCx.machine.enable_trajekt_vision) {
              NotifyHelper.warning({
                message_md: t('common.enable-trajekt-vision-msg'),
              });
            }

            // machine can support stereo but there isn't a video (yet) associated with this shot
            NotifyHelper.warning({
              message_md: t('common.no-video-playback-msg'),
            });
            return;
          }

          // tells the dialog where to start, is not synced with next/prev triggers
          setHitIndex(hitsWithVideos.findIndex((h) => h.fire._id === fire._id));

          // show the dialog
          setPlaybackDialog(Date.now());
        },
      }),
    [hitsWithVideos, setHitIndex, setPlaybackDialog]
  );

  return (
    <ErrorBoundary componentName="HitterSession">
      <FlexTableWrapper
        gap={RADIX.FLEX.GAP.SECTION}
        // Breadcrumbs and summary rendered in HitterSesionHeader
        header={<HitterSessionHeader />}
        table={
          <CommonTableHoC
            id={IDENTIFIER}
            displayColumns={columns}
            displayData={filteredHits}
            loading={loading}
            toolbarContent={<HitterSessionToolbar />}
            {...pagination}
            {...sort}
            vFlex
          />
        }
      />

      {playbackDialog && hitIndex !== -1 && playbackDict && (
        <HitterSessionVideoDialog
          identifier="HitterSessionShotVideo"
          title="common.shot-data"
          defaultIndex={hitIndex}
          hits={hitsWithVideos}
          urls={playbackDict}
          onClose={() => setPlaybackDialog(undefined)}
        />
      )}
    </ErrorBoundary>
  );
};
