import { GameDataContext } from 'contexts/game-data.context';
import { MlbSportId } from 'lib_ts/enums/mlb-stats-api/base.enum';
import { useContext, useMemo } from 'react';

const LOGO_FALLBACK = true;

export const GameDataTeamLogo = (props: {
  isDark: boolean;
  // e.g. 48px
  size: string;
  teamPK: number | undefined;
  name?: string;
}) => {
  const { filterGlobal } = useContext(GameDataContext);

  const url = useMemo(() => {
    if (!props.teamPK) {
      if (!LOGO_FALLBACK) {
        // don't draw anything
        return '';
      }

      switch (filterGlobal.sportId) {
        case MlbSportId.MiLB: {
          return '/img/milb-logo-square.svg';
        }

        case MlbSportId.MLB: {
          return '/img/mlb-logo-square.svg';
        }

        default: {
          return '';
        }
      }
    }

    if (filterGlobal.sportId !== MlbSportId.MLB) {
      // non-MLB teams don't have different logos for light and dark bg
      return `https://www.mlbstatic.com/team-logos/${props.teamPK}.svg`;
    }

    const base = props.isDark
      ? 'https://www.mlbstatic.com/team-logos/team-cap-on-dark/'
      : 'https://www.mlbstatic.com/team-logos/team-cap-on-light/';

    return base + `${props.teamPK}.svg`;
  }, [props.teamPK, props.isDark, filterGlobal.sportId]);

  if (!url) {
    return <></>;
  }

  return (
    <img
      loading="lazy"
      height={props.size}
      width={props.size}
      alt={props.name ?? 'no team'}
      src={url}
    />
  );
};
