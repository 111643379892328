import { MagnifyingGlassIcon } from '@radix-ui/react-icons';
import { Button, Flex, Grid, Skeleton } from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import { GameDataPlayerCard } from 'components/sections/game-data/players/card';
import { GameDataPlayersFilters } from 'components/sections/game-data/players/filters';
import { GameDataContext } from 'contexts/game-data.context';
import { GameDataTab } from 'enums/game-data.enums';
import { t } from 'i18next';
import { isAppearanceDark } from 'index';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { HITTER_SIDES, HitterSide } from 'lib_ts/enums/hitters.enums';
import { PITCHER_HANDS, PitcherHand } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext, useMemo, useState } from 'react';

const COMPONENT_NAME = 'GameDataPlayers';

const ITEMS_PER_PAGE = 100;

export const GameDataPlayers = (props: { mode: GameDataTab }) => {
  const [pages, setPages] = useState(1);

  const [resetKey, setResetKey] = useState(Date.now());

  const [search, setSearch] = useState('');
  const searchKeys = useMemo(() => StringHelper.keyify(search), [search]);

  const { loading, seasonPlayers, filterPlayers, mergeFilterPlayers } =
    useContext(GameDataContext);

  const players = useMemo(() => {
    return seasonPlayers
      .filter((p) => {
        switch (props.mode) {
          case 'pitchers': {
            return p.isPitcher;
          }

          // every pitcher might be a batter (depends on league and we don't know)
          case 'hitters': {
            return true;
          }

          // show nothing for any other mode
          default: {
            return false;
          }
        }
      })
      .filter((p) => {
        switch (props.mode) {
          case 'pitchers': {
            return !filterPlayers.hand || filterPlayers.hand === p.pitchHand;
          }

          case 'hitters': {
            return !filterPlayers.side || filterPlayers.side === p.batSide;
          }

          default: {
            return true;
          }
        }
      })
      .filter((p) => !filterPlayers.teamPk || p.teamPk === filterPlayers.teamPk)
      .filter(
        (p) =>
          searchKeys.length === 0 ||
          ArrayHelper.hasSubstringIntersection(
            StringHelper.keyify(p.name),
            searchKeys
          )
      );
  }, [searchKeys, seasonPlayers, filterPlayers]);

  const isDark = isAppearanceDark();

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <Flex direction="column" gap="3">
        <GameDataPlayersFilters
          onResetFilters={() => {
            setSearch('');
            setResetKey(Date.now());
          }}
        >
          <CommonTextInput
            id="player-search"
            value={search}
            onChange={(v) => setSearch(v ?? '')}
            leftSlot={<MagnifyingGlassIcon />}
            placeholder="Search by name"
          />

          {props.mode === 'pitchers' && (
            <CommonSelectInput
              key={`hand-${resetKey}`}
              id="pitcher-hand"
              placeholder="common.hand"
              options={PITCHER_HANDS}
              value={filterPlayers.hand}
              onChange={(v) => mergeFilterPlayers({ hand: v as PitcherHand })}
              optional
            />
          )}

          {props.mode === 'hitters' && (
            <CommonSelectInput
              key={`side-${resetKey}`}
              id="batter-side"
              placeholder="common.side"
              options={HITTER_SIDES}
              value={filterPlayers.side}
              onChange={(v) => mergeFilterPlayers({ side: v as HitterSide })}
              optional
            />
          )}
        </GameDataPlayersFilters>

        <Skeleton loading={loading}>
          <Grid columns={{ initial: '1', md: '2', lg: '3' }} gap="3">
            {players
              .filter((_, i) => i < pages * ITEMS_PER_PAGE)
              .map((p) => (
                <GameDataPlayerCard
                  key={p._id}
                  isDark={isDark}
                  player={p}
                  mode={props.mode}
                />
              ))}

            {players.length > pages * ITEMS_PER_PAGE && (
              <Flex
                justify="center"
                gridColumn={{ initial: undefined, md: 'span 2', lg: 'span 3' }}
              >
                <Button
                  onClick={() => setPages(pages + 1)}
                  variant={RADIX.BUTTON.VARIANT.SECONDARY}
                >
                  {t('common.load-more')}
                </Button>
              </Flex>
            )}
          </Grid>
        </Skeleton>
      </Flex>
    </ErrorBoundary>
  );
};
