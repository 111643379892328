import { Badge, Box, Button, Card, Flex } from '@radix-ui/themes';
import { TrainingHelper } from 'classes/helpers/training-helper';
import { MachineIcon } from 'components/common/custom-icon/shorthands';
import { CommonNavMenu } from 'components/common/layout/nav-menu';
import { SidebarNavigationTrigger } from 'components/main/sidebar/nav-trigger';
import { MachineContext, MachineDialogMode } from 'contexts/machine.context';
import { InstallStep } from 'enums/installation';
import { InstallStepStatus } from 'lib_ts/enums/machine.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext, useMemo } from 'react';

interface IProps {
  expanded: boolean;
}

export const InstallReminder = (props: IProps) => {
  const { machine, setDialog } = useContext(MachineContext);

  const steps = useMemo(() => {
    if (!machine.last_installation) {
      return [];
    }

    return TrainingHelper.getInstallationSteps(
      machine.last_installation
    ).filter((m) => m.id !== InstallStep.End);
  }, [machine.last_installation]);

  const index = useMemo(() => {
    return steps.findIndex(
      (m) => !m.status || m.status === InstallStepStatus.Incomplete
    );
  }, [steps]);

  if (props.expanded) {
    return (
      <Card>
        <Flex direction="column" gap="2">
          <Flex align="center" gap="2">
            <MachineIcon />
            <Box flexGrow="1">Machine Install</Box>
            {index !== -1 && (
              <Badge variant="soft" color={RADIX.COLOR.NEUTRAL}>
                {index + 1} / {steps.length}
              </Badge>
            )}
          </Flex>

          <Box>
            <Button
              className="btn-block"
              onClick={() => setDialog(MachineDialogMode.Installation)}
            >
              Resume
            </Button>
          </Box>
        </Flex>
      </Card>
    );
  }

  return (
    <CommonNavMenu>
      <SidebarNavigationTrigger
        label="Machine Install"
        icon={<MachineIcon />}
        onClick={() => setDialog(MachineDialogMode.Installation)}
        active
      />
    </CommonNavMenu>
  );
};
