import { NotifyHelper } from 'classes/helpers/notify.helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { IMachineContext, MachineContext } from 'contexts/machine.context';
import { WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import {
  ICalibrateResponseMsg,
  IOverlaySummaryResponseMsg,
  IProcessQueryResponseMsg,
  IReadyMsg,
  ISoftRebootResponseMsg,
  ISystemRebootResponseMsg,
} from 'lib_ts/interfaces/i-machine-msg';
import { IMachineStatusMsg } from 'lib_ts/interfaces/machine-msg/i-machine-status';
import { SpecialMsPosition } from 'lib_ts/interfaces/machine-msg/i-special-mstarget';
import React, { useContext } from 'react';
import { WebSocketService } from 'services/web-socket.service';

const COMPONENT_NAME = 'MachineResponseListener';

const SHOW_ERROR_TOAST = false;

interface IProps {
  machineCx: IMachineContext;
}

interface IState {}

export const MachineResponseListener = () => {
  const machineCx = useContext(MachineContext);

  // wait for WS to come online
  if (!WebSocketService.getInstance()) {
    return <></>;
  }

  return <MachineResponseListenerBase machineCx={machineCx} />;
};

class MachineResponseListenerBase extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.handleCalibrationResponse = this.handleCalibrationResponse.bind(this);
    this.handleFireResponse = this.handleFireResponse.bind(this);
    this.handleMachineStatus = this.handleMachineStatus.bind(this);
    this.handleOverlayIDsResponse = this.handleOverlayIDsResponse.bind(this);
    this.handleQueryProcessesResponse =
      this.handleQueryProcessesResponse.bind(this);
    this.handleReadyMessage = this.handleReadyMessage.bind(this);
    this.handleSoftRebootResponse = this.handleSoftRebootResponse.bind(this);
    this.handleSystemRebootResponse =
      this.handleSystemRebootResponse.bind(this);
  }

  componentDidMount() {
    WebSocketHelper.on(WsMsgType.Misc_MachineStatus, this.handleMachineStatus);
    WebSocketHelper.on(
      WsMsgType.M2U_CalibrationResponse,
      this.handleCalibrationResponse
    );
    WebSocketHelper.on(WsMsgType.M2U_FireResponse, this.handleFireResponse);
    WebSocketHelper.on(
      WsMsgType.M2U_OverlaysResponse,
      this.handleOverlayIDsResponse
    );
    WebSocketHelper.on(
      WsMsgType.M2U_QueryProcesses,
      this.handleQueryProcessesResponse
    );
    WebSocketHelper.on(WsMsgType.M2U_SoftReboot, this.handleSoftRebootResponse);
    WebSocketHelper.on(
      WsMsgType.M2U_SystemRestart,
      this.handleSystemRebootResponse
    );
    WebSocketHelper.on(WsMsgType.M2U_ReadyToFire, this.handleReadyMessage);

    // now that we're listening, ask the server for our own machine status
    WebSocketService.send(
      WsMsgType.Misc_MachineStatus,
      undefined,
      COMPONENT_NAME
    );
  }

  componentWillUnmount() {
    WebSocketHelper.remove(
      WsMsgType.Misc_MachineStatus,
      this.handleMachineStatus
    );
    WebSocketHelper.remove(
      WsMsgType.M2U_CalibrationResponse,
      this.handleCalibrationResponse
    );
    WebSocketHelper.remove(WsMsgType.M2U_FireResponse, this.handleFireResponse);
    WebSocketHelper.remove(
      WsMsgType.M2U_OverlaysResponse,
      this.handleOverlayIDsResponse
    );
    WebSocketHelper.remove(
      WsMsgType.M2U_QueryProcesses,
      this.handleQueryProcessesResponse
    );
    WebSocketHelper.remove(
      WsMsgType.M2U_SoftReboot,
      this.handleSoftRebootResponse
    );
    WebSocketHelper.remove(
      WsMsgType.M2U_SystemRestart,
      this.handleSystemRebootResponse
    );
    WebSocketHelper.remove(WsMsgType.M2U_ReadyToFire, this.handleReadyMessage);
  }

  private handleMachineStatus(event: CustomEvent) {
    const data: IMachineStatusMsg = event.detail;
    this.props.machineCx.setLastStatus(data);
  }

  private handleCalibrationResponse(event: CustomEvent) {
    const data: ICalibrateResponseMsg = event.detail;

    this.props.machineCx.setCalibrated(!!data.status);
    this.props.machineCx.setCanCalibrate(!!data.can_calibrate);

    if (data.status) {
      this.props.machineCx.requestOverlayIDs('machine listener');
    }
  }

  private handleFireResponse(event: CustomEvent) {
    this.props.machineCx.setFiring(false);
  }

  private handleOverlayIDsResponse(event: CustomEvent) {
    const data: IOverlaySummaryResponseMsg = event.detail;
    this.props.machineCx.setOverlayIDs(data.overlay_ids);
  }

  private handleQueryProcessesResponse(event: CustomEvent) {
    const data: IProcessQueryResponseMsg = event.detail;
    this.props.machineCx.setProcessData(data);
  }

  private handleSoftRebootResponse(event: CustomEvent) {
    const data: ISoftRebootResponseMsg = event.detail;
    if (!data.success) {
      NotifyHelper.error({ message_md: 'Failed to soft reboot.' });
      return;
    }

    NotifyHelper.success({ message_md: 'Soft reboot started. Please wait' });
  }

  private handleSystemRebootResponse(event: CustomEvent) {
    const data: ISystemRebootResponseMsg = event.detail;

    if (!data.success) {
      NotifyHelper.error({
        message_md: `Restart failed on processes ${data.failed_list}.`,
      });
      return;
    }

    NotifyHelper.success({ message_md: 'Restart successful.' });
  }

  private handleReadyMessage(event: CustomEvent) {
    const data: IReadyMsg = event.detail;

    if (!data.timeout_processes || !data.timeout_duration_sec) {
      return;
    }

    if (!SHOW_ERROR_TOAST) {
      return;
    }

    NotifyHelper.error({
      message_md:
        'There is an issue with your machine, please perform a restart and/or contact support.',
      delay_ms: 0,
      buttons: [
        {
          label: 'common.restart',
          dismissAfterClick: true,
          onClick: () => this.props.machineCx.restartArc('r2f-timeout-toast'),
        },
        {
          label: 'common.lower-machine',
          dismissAfterClick: true,
          onClick: () =>
            this.props.machineCx.specialMstarget(SpecialMsPosition.lowered),
        },
      ],
    });
  }

  render() {
    return <></>;
  }
}
