import { ITrainingDot } from 'interfaces/i-canvas';

export const LIGHT_TRAINING_DOT_COLORS: {
  old: ITrainingDot;
  new: ITrainingDot;
} = {
  old: {
    background: '#F0F0F3',
    border: '#D9D9D9',
    label: '#646464',
  },
  new: {
    background: '#FFF7C2',
    border: '#F3D673',
    label: '#AB6400',
  },
};

export const DARK_TRAINING_DOT_COLORS: {
  old: ITrainingDot;
  new: ITrainingDot;
} = {
  old: {
    background: '#212225',
    border: '#3A3A3A',
    label: '#B4B4B4',
  },
  new: {
    background: '#302008',
    border: '#5C3D05',
    label: '#FFCA16',
  },
};

export const DOT_SIZE_SM = 6;
export const DOT_SIZE_MD = 12;
export const DOT_SIZE_LG = 16;

export const MAX_SHOT_OPACITY = 0.7;
export const SHOT_OPACITY_DELTA = 0.06;

export const getScaledLabelFont = (scale: number) =>
  `${Math.round(16 * scale)}px sans-serif`;

export const LIGHT_MODE_COLOR = '#CDCED6';

export const DARK_MODE_COLOR = '#43484E';

export const INVISIBLE_COLOR = 'rgba(0,0,0,0)';

export const BASEBALL_PNG_PX = 22;
