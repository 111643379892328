import { CaretDownIcon, CaretUpIcon } from '@radix-ui/react-icons';
import { Button, Card, Flex, Popover, Separator } from '@radix-ui/themes';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonSwitchInput } from 'components/common/form/switch';
import { CookiesContext } from 'contexts/cookies.context';
import { MachineContext } from 'contexts/machine.context';
import { CookieKey } from 'enums/cookies.enums';
import { t } from 'i18next';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext, useState } from 'react';

export const ImportSettingsPopover = () => {
  const { app, setCookie } = useContext(CookiesContext);
  const { buildOptions, getSupportedPriority } = useContext(MachineContext);
  const [openSettings, setOpenSettings] = useState(false);

  return (
    <Popover.Root onOpenChange={(open) => setOpenSettings(open)}>
      <Popover.Trigger>
        <Button variant={RADIX.BUTTON.VARIANT.SECONDARY}>
          {t('common.settings')}
          {openSettings ? <CaretUpIcon /> : <CaretDownIcon />}
        </Button>
      </Popover.Trigger>
      <Popover.Content asChild>
        <Card>
          <Flex direction="column" gap="3" p="2">
            <CommonSelectInput
              id="pitches-header-build-priority"
              label="common.import-method"
              options={buildOptions}
              value={getSupportedPriority(app.pitch_upload_options.priority)}
              onChange={(v) =>
                setCookie(CookieKey.app, {
                  pitch_upload_options: {
                    ...app.pitch_upload_options,
                    priority: v as BuildPriority,
                  },
                })
              }
            />

            <Separator size="4" />

            <CommonSwitchInput
              id="pitches-header-normalize"
              label="common.normalize-releases"
              defaultChecked={app.pitch_upload_options.average_release}
              onCheckedChange={(v) =>
                setCookie(CookieKey.app, {
                  pitch_upload_options: {
                    ...app.pitch_upload_options,
                    average_release: v,
                  },
                })
              }
            />
          </Flex>
        </Card>
      </Popover.Content>
    </Popover.Root>
  );
};
