import { CaretDownIcon, CaretUpIcon } from '@radix-ui/react-icons';
import {
  Badge,
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Popover,
  Spinner,
  Text,
} from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { HINT_DELIMITER } from 'components/common/form/text-hint';
import { CommonContentWithSidebar } from 'components/common/layout/content-with-sidebar';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { TableProvider } from 'components/common/table/context';
import { GameDataFooter } from 'components/sections/game-data/footer';
import { GameDataPitches } from 'components/sections/game-data/pitches';
import { GameDataPitchesSidebar } from 'components/sections/game-data/pitches/sidebar';
import { GameDataPlayerAvatar } from 'components/sections/game-data/players/avatar';
import { GameDataPitcherRepertoire } from 'components/sections/game-data/players/pitcher-repertoire';
import { GameDataTeamLogo } from 'components/sections/game-data/team-logo';
import { Breadcrumbs } from 'components/sections/header/breadcrumbs';
import { PitchListStoreProvider } from 'components/sections/pitch-list/store/use-pitch-list-store';
import { ImportSettingsPopover } from 'components/sections/pitches/import-settings-popover';
import { AimingProvider } from 'contexts/aiming.context';
import { GameDataContext } from 'contexts/game-data.context';
import { SectionsContext } from 'contexts/sections.context';
import { GameDataTab } from 'enums/game-data.enums';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { t } from 'i18next';
import { isAppearanceDark } from 'index';
import { HitterSide } from 'lib_ts/enums/hitters.enums';
import { MlbSportId } from 'lib_ts/enums/mlb-stats-api/base.enum';
import { PitcherHand } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMlbPitchExt } from 'lib_ts/interfaces/mlb-stats-api/i-pitch';
import { useContext, useEffect, useMemo, useState } from 'react';
import { MlbStatsService } from 'services/mlb-stats.service';

const COMPONENT_NAME = 'GameDataPlayer';

export const GameDataPlayer = (props: {
  playerID: string | undefined;
  mode: GameDataTab;
}) => {
  const { tryChangeSection } = useContext(SectionsContext);
  const { seasonPlayers } = useContext(GameDataContext);

  const player = useMemo(
    () => seasonPlayers.find((p) => p._id === props.playerID),
    [props.playerID, seasonPlayers]
  );

  const teamName = useMemo(() => {
    if (!player) {
      return t('common.loading');
    }

    if (player.teamCode && player.teamShortName) {
      return `${player.teamCode} ${player.teamShortName}`;
    }

    if (player.teamName) {
      return player.teamName;
    }

    return 'No Active Roster';
  }, [player]);

  const playerLabel = useMemo(() => {
    if (!player) {
      return '';
    }

    const league = (() => {
      switch (player.sportId) {
        case MlbSportId.MLB: {
          return 'MLB';
        }

        case MlbSportId.MiLB: {
          return 'MiLB';
        }

        default: {
          return '';
        }
      }
    })();

    return `${league} ${HINT_DELIMITER} ${player.season}`;
  }, [player]);

  const [pitches, setPitches] = useState<IMlbPitchExt[]>();
  const [repertoireOpen, setRepertoireOpen] = useState(false);

  useEffect(() => {
    if (pitches) {
      // we've already loaded data
      return;
    }

    if (!player) {
      return;
    }

    if (!props.mode) {
      return;
    }

    MlbStatsService.getInstance()
      .getPlayerPitches({
        mode: props.mode === 'hitters' ? 'hitter' : 'pitcher',
        playerID: player._id,
      })
      .then((result) => setPitches(result));
  }, [props.mode, player, pitches]);

  if (!player) {
    return <Spinner size="3" />;
  }

  if (!pitches) {
    return <Spinner size="3" />;
  }

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <PitchListStoreProvider>
        <AimingProvider>
          <CommonContentWithSidebar
            left={
              <FlexTableWrapper
                header={
                  <>
                    <Breadcrumbs
                      items={[
                        {
                          label: 'common.pitches',
                          onClick: () =>
                            tryChangeSection({
                              section: SectionName.Pitches,
                              subsection: SubSectionName.Library,
                              trigger: `${COMPONENT_NAME} > breadcrumbs`,
                            }),
                        },
                        {
                          label: 'main.game-data',
                          onClick: () =>
                            tryChangeSection({
                              section: SectionName.Pitches,
                              subsection: SubSectionName.GameData,
                              trigger: `${COMPONENT_NAME} > breadcrumbs`,
                            }),
                        },
                        {
                          label: player.name,
                        },
                      ]}
                    />

                    <Flex gap="3" align="center">
                      <GameDataPlayerAvatar
                        size="7"
                        name={player.name}
                        playerPk={player.playerPk}
                      />

                      <Flex flexGrow="1" direction="column" gap="1">
                        <Flex gap="2" align="center">
                          <Box minWidth="0">
                            <Heading size="6" truncate>
                              {player.name} ({playerLabel})
                            </Heading>
                          </Box>

                          {props.mode === 'pitchers' && (
                            <Badge
                              color={
                                player.pitchHand === PitcherHand.LHP
                                  ? RADIX.COLOR.LEFT
                                  : RADIX.COLOR.RIGHT
                              }
                            >
                              {player.pitchHand}
                            </Badge>
                          )}

                          {props.mode === 'hitters' && (
                            <Badge
                              color={
                                player.batSide === HitterSide.LHH
                                  ? RADIX.COLOR.LEFT
                                  : RADIX.COLOR.RIGHT
                              }
                            >
                              {player.batSide}
                            </Badge>
                          )}
                        </Flex>

                        <Flex gap="2" align="center">
                          <GameDataTeamLogo
                            size="20px"
                            teamPK={player.teamPk}
                            name={player.teamName}
                            isDark={isAppearanceDark()}
                          />

                          <Box minWidth="0">
                            <Text color={RADIX.COLOR.SECONDARY} truncate>
                              {teamName}
                            </Text>
                          </Box>
                        </Flex>
                      </Flex>

                      <ImportSettingsPopover />

                      {props.mode === 'pitchers' && (
                        <Popover.Root
                          onOpenChange={(open) => setRepertoireOpen(open)}
                        >
                          <Popover.Trigger>
                            <Button>
                              Repertoire{' '}
                              {repertoireOpen ? (
                                <CaretUpIcon />
                              ) : (
                                <CaretDownIcon />
                              )}
                            </Button>
                          </Popover.Trigger>
                          <Popover.Content asChild>
                            <Card>
                              <GameDataPitcherRepertoire pitches={pitches} />
                            </Card>
                          </Popover.Content>
                        </Popover.Root>
                      )}
                    </Flex>
                  </>
                }
                table={
                  <TableProvider>
                    <GameDataPitches
                      mode={props.mode}
                      playerPk={player.playerPk}
                      pitches={pitches}
                    />
                  </TableProvider>
                }
                footer={<GameDataFooter />}
              />
            }
            right={<GameDataPitchesSidebar />}
            vFlex
          />
        </AimingProvider>
      </PitchListStoreProvider>
    </ErrorBoundary>
  );
};
