import { Badge, Flex } from '@radix-ui/themes';
import { MlbStatsHelper } from 'classes/helpers/mlb-stats.helper';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PitchDesignHelper } from 'classes/helpers/pitch-design.helper';
import { CopyPitchesDialogHoC } from 'components/common/dialogs/copy-pitches';
import { AuthContext } from 'contexts/auth.context';
import { GameDataTab } from 'enums/game-data.enums';
import { t } from 'i18next';
import {
  IGameFilter,
  IPitchFilter,
  IPlayerFilter,
} from 'interfaces/i-mlb-browse';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { getMergedMSDict } from 'lib_ts/classes/ms.helper';
import { MlbSportId } from 'lib_ts/enums/mlb-stats-api/base.enum';
import { CURRENT_MLB_SEASON } from 'lib_ts/enums/mlb.enums';
import {
  BuildPriority,
  lookupPitchType,
  tooltipPitchType,
} from 'lib_ts/enums/pitches.enums';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IMlbGame } from 'lib_ts/interfaces/mlb-stats-api/i-game';
import { IMlbPitchExt } from 'lib_ts/interfaces/mlb-stats-api/i-pitch';
import { IMlbPlayerExt } from 'lib_ts/interfaces/mlb-stats-api/i-player';
import { IMlbTeamExt } from 'lib_ts/interfaces/mlb-stats-api/i-team';
import { IBuildPitchChars, IPitch } from 'lib_ts/interfaces/pitches';
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { MlbStatsService } from 'services/mlb-stats.service';
import { StateTransformService } from 'services/state-transform.service';
import { AimingContext } from './aiming.context';
import { CookiesContext } from './cookies.context';
import { MachineContext } from './machine.context';

const CONTEXT_NAME = 'GameDataContext';

interface IGlobalFilter {
  sportId: MlbSportId;
  season: number;
}

interface IBuildConfig {
  priority: BuildPriority;
  pitches: IMlbPitchExt[];
  isAverage?: boolean;
}

export interface IGameDataContext {
  loading: boolean;

  tab: GameDataTab;
  readonly setTab: (value: GameDataTab) => void;

  filterGlobal: IGlobalFilter;
  readonly mergeFilterGlobal: (value: Partial<IGlobalFilter>) => void;

  filterGames: Partial<IGameFilter>;
  readonly mergeFilterGames: (value: Partial<IGameFilter>) => void;

  filterPlayers: Partial<IPlayerFilter>;
  readonly mergeFilterPlayers: (value: Partial<IPlayerFilter>) => void;

  filterPitches: Partial<IPitchFilter>;
  readonly mergeFilterPitches: (value: Partial<IPitchFilter>) => void;

  seasons: IOption[];
  seasonGames: IMlbGame[];
  seasonPlayers: IMlbPlayerExt[];
  seasonTeams: IMlbTeamExt[];

  // callback is run if the toast is shown and the user presses accept
  readonly checkLaneWarning: (onAccept: () => void) => boolean;

  readonly buildPitches: (config: IBuildConfig) => Promise<IPitch[]>;
  readonly openAddDialog: (config: {
    guids?: IMlbPitchExt[];
    pitches?: IPitch[];
    pitcherPk?: number;
    isAverage?: boolean;
  }) => void;
}

const DEFAULT: IGameDataContext = {
  loading: false,

  tab: 'pitchers',
  setTab: () => console.error(`${CONTEXT_NAME}: not init`),

  filterGlobal: {
    sportId: MlbSportId.MLB,
    season: CURRENT_MLB_SEASON,
  },
  mergeFilterGlobal: () => console.error(`${CONTEXT_NAME}: not init`),

  filterGames: {},
  mergeFilterGames: () => console.error(`${CONTEXT_NAME}: not init`),

  filterPlayers: {},
  mergeFilterPlayers: () => console.error(`${CONTEXT_NAME}: not init`),

  filterPitches: {},
  mergeFilterPitches: () => console.error(`${CONTEXT_NAME}: not init`),

  seasons: [],

  seasonGames: [],

  seasonPlayers: [],

  seasonTeams: [],

  checkLaneWarning: () => false,

  buildPitches: () => new Promise(() => []),
  openAddDialog: () => console.error(`${CONTEXT_NAME}: not init`),
};

export const GameDataContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const GameDataProvider: FC<IProps> = (props) => {
  const { app } = useContext(CookiesContext);
  const { current } = useContext(AuthContext);
  const { machine, getSupportedPriority } = useContext(MachineContext);

  const { pitch } = useContext(AimingContext);

  const [_warningAccepted, _setWarningAccepted] = useState(false);
  const [_warningVisible, _setWarningVisible] = useState(false);

  const [_loading, _setLoading] = useState(DEFAULT.loading);

  const [_filterGlobal, _setFilterGlobal] = useState(DEFAULT.filterGlobal);
  const [_filterGames, _setFilterGames] = useState(DEFAULT.filterGames);
  const [_filterPlayers, _setFilterPlayers] = useState(DEFAULT.filterPlayers);
  const [_filterPitches, _setFilterPitches] = useState(DEFAULT.filterPitches);

  const [_seasons, _setSeasons] = useState(DEFAULT.seasons);
  const [_seasonGames, _setSeasonGames] = useState(DEFAULT.seasonGames);

  const [_seasonTeams, _setSeasonTeams] = useState(DEFAULT.seasonTeams);
  const [_seasonPlayers, _setSeasonPlayers] = useState(DEFAULT.seasonPlayers);

  const _reloadGlobal = useCallback(
    async (filter: IGlobalFilter, sportChanged: boolean) => {
      try {
        _setLoading(true);

        if (sportChanged) {
          const seasons = await MlbStatsService.getInstance().countGames({
            sportId: filter.sportId,
          });

          _setSeasons(
            seasons
              .filter((s) => s.total > 0)
              .sort((a, b) => (a.season > b.season ? -1 : 1))
              .map((o) => ({
                label: o.season.toString(),
                value: o.season.toString(),
              }))
          );
        }

        const teams = await MlbStatsService.getInstance().getTeams({
          season: filter.season,
          sportId: filter.sportId,
        });
        _setSeasonTeams(teams);

        const players = await MlbStatsService.getInstance().getPlayers({
          season: filter.season,
          sportId: filter.sportId,
          teams: teams,
        });

        _setSeasonPlayers(players);

        const games = await MlbStatsService.getInstance().getGames({
          season: filter.season,
          sportId: filter.sportId,
        });

        games.sort((a, b) => -a.officialDate.localeCompare(b.officialDate));
        _setSeasonGames(games);
      } catch (e) {
        console.error(e);
      } finally {
        _setLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    if (!current.mlb_stats_api) {
      // do nothing
      return;
    }

    _reloadGlobal(_filterGlobal, true);
  }, [current.mlb_stats_api]);

  useEffect(() => {
    _setFilterPlayers({
      ..._filterPlayers,
      teamPk: undefined,
    });
  }, [_filterGlobal.sportId, _filterGlobal.season]);

  const [_tab, _setTab] = useState(DEFAULT.tab);

  const [managePitches, setManagePitches] = useState<IPitch[]>();
  const [dialogAdd, setDialogAdd] = useState<number>();
  const [dialogAddAverages, setDialogAddAverages] = useState<number>();

  // for pitcher name badge in add dialog
  const [addPlayer, setAddPlayer] = useState<IMlbPlayerExt>();

  const _buildPitches = useCallback(
    async (config: IBuildConfig): Promise<IPitch[]> => {
      const validGuids: IMlbPitchExt[] = [];
      const errors: string[] = [];

      config.pitches.forEach((g) => {
        const errs = MlbStatsHelper.getErrors(g);
        if (errs.length === 0) {
          validGuids.push(g);
        } else {
          errors.push(...errs);
        }
      });

      if (errors.length > 0) {
        NotifyHelper.warning({
          message_md: [
            'Invalid data detected:',
            ArrayHelper.unique(errors)
              .map((e) => ` - ${e}`)
              .join('\n'),
            `${
              config.pitches.length === 1 ? 'This pitch' : 'Affected pitches'
            } cannot be constructed at this time.`,
          ].join('\n\n'),
        });
      }

      if (validGuids.length === 0) {
        return [];
      }

      const convertedResults = validGuids.map((g) =>
        // average pitches all point to the default plate
        MlbStatsHelper.convertToChars(g, config.priority, !!config.isAverage)
      );

      const withWarnings = convertedResults.filter(
        (b) => b.warnings.length > 0
      );

      if (withWarnings.length > 0) {
        const msgs = ArrayHelper.unique(
          withWarnings.flatMap((w) => w.warnings)
        ).map((txt) => ` - ${txt}`);

        NotifyHelper.warning({
          message_md: [
            `${
              withWarnings.length > 1 ? 'Some pitches' : 'One pitch'
            } required minor adjustment(s) to be usable on ${
              machine.machineID
            }:`,
            msgs.join('\n'),
          ].join('\n\n'),
        });
      }

      if (app.pitch_upload_options.average_release) {
        MlbStatsHelper.averageReleasesByPitcher(validGuids, convertedResults);
      }

      const buildableResults = convertedResults.filter((g) => !!g.chars);

      if (buildableResults.length === 0) {
        return [];
      }

      if (buildableResults.length > 1) {
        // avoid showing loading for single pitch builds
        _setLoading(true);
      }

      const chars = await StateTransformService.getInstance()
        .buildPitches({
          machine: machine,
          pitches: buildableResults.map(
            (r) => r.chars
          ) as Partial<IBuildPitchChars>[],
          notifyError: true,
        })
        .finally(() => _setLoading(false));

      return chars
        .map((char) => {
          if (!char.bs) {
            return;
          }

          if (!char.traj) {
            return;
          }

          if (!char.ms) {
            return;
          }

          const play = validGuids.find((g) => g.guid === char.mongo_id);
          if (!play) {
            return;
          }

          const gamePk = play.gamePk;
          const game = _seasonGames.find((g) => g.gamePk === gamePk);

          const pitchType = lookupPitchType(play.type);

          const pitch: IPitch = {
            mlb_gamePk: play.gamePk,
            mlb_guid: play.guid,

            hitter: config.isAverage ? undefined : play.batter,
            game: game
              ? `${game.officialDate}: ${game.away.name} @ ${game.home.name}`
              : undefined,
            outcome: config.isAverage ? undefined : play.outcome,

            bs: char.bs,
            traj: char.traj,
            msDict: getMergedMSDict(machine, [char.ms]),
            plate_loc_backup: char.plate,
            seams: char.seams,
            breaks: char.breaks,

            priority: config.priority,

            name: [
              config.isAverage ? 'Avg.' : undefined,
              play.pitcher,
              pitchType,
              // char.bs.vnet.toFixed(0),
            ]
              .filter((s) => !!s)
              .join(' '),

            type: pitchType,

            // year: this.props.browseCx.gameFilter.season,

            _id: play.guid,
            _created: new Date().toISOString(),
            _changed: new Date().toISOString(),
            _parent_def: '',
            _parent_id: '',
          };

          return pitch;
        })
        .filter((c) => !!c) as IPitch[];
    },
    [app, machine, _seasonGames]
  );

  const state: IGameDataContext = {
    loading: _loading,

    tab: _tab,
    setTab: _setTab,

    filterGlobal: _filterGlobal,
    mergeFilterGlobal: async (value) => {
      const nextValue: IGlobalFilter = {
        ..._filterGlobal,
        ...value,
      };

      // update inputs
      _setFilterGlobal(nextValue);

      // update data
      _reloadGlobal(nextValue, _filterGlobal.sportId !== nextValue.sportId);
    },

    filterGames: _filterGames,
    mergeFilterGames: (value) =>
      _setFilterGames({
        ..._filterGames,
        ...value,
      }),

    filterPlayers: _filterPlayers,
    mergeFilterPlayers: (value) =>
      _setFilterPlayers({
        ..._filterPlayers,
        ...value,
      }),

    filterPitches: _filterPitches,
    mergeFilterPitches: (value) =>
      _setFilterPitches({
        ..._filterPitches,
        ...value,
      }),

    seasons: _seasons,
    seasonGames: _seasonGames,
    seasonPlayers: _seasonPlayers,
    seasonTeams: _seasonTeams,

    checkLaneWarning: (onAccept) => {
      if (_warningAccepted) {
        // allow user to S2M
        return true;
      }

      if (_warningVisible) {
        // toast is already visible
        return false;
      }

      // prevents subsequent triggers while toast is open from spawning additional toasts
      _setWarningVisible(true);

      NotifyHelper.warning({
        message_md: `Before test firing, please ensure the lane is clear for safety reasons.`,
        delay_ms: 0,
        // if the user dismisses without accepting, the next trigger will resurface the toast
        onClose: () => _setWarningVisible(false),
        buttons: [
          {
            label: t('common.accept'),
            dismissAfterClick: true,
            onClick: () => {
              // user will not be prompted again for the rest of the session
              _setWarningAccepted(true);

              // e.g. re-trigger S2M
              onAccept();
            },
          },
        ],
      });

      return false;
    },

    buildPitches: _buildPitches,

    openAddDialog: async (config) => {
      const built: IPitch[] = [];

      if (config.pitches) {
        built.push(...config.pitches);
      }

      if (config.guids) {
        const builtGuids = await _buildPitches({
          priority: getSupportedPriority(app.pitch_upload_options.priority),
          pitches: config.guids,
          isAverage: config.isAverage,
        });

        built.push(...builtGuids);
      }

      if (built.length === 0) {
        return;
      }

      // fix the video if necessary
      if (pitch?.video_id) {
        const selected = built.find((p) => p.mlb_guid === pitch?.mlb_guid);

        if (selected) {
          selected.video_id = pitch.video_id;
        }
      }

      setAddPlayer(_seasonPlayers.find((p) => p.playerPk === config.pitcherPk));

      setManagePitches(built);

      if (config.isAverage) {
        setDialogAddAverages(Date.now());
      } else {
        setDialogAdd(Date.now());
      }
    },
  };

  const pitchType =
    managePitches?.length === 1 ? managePitches[0].type : undefined;

  const buildPriority = PitchDesignHelper.getDisplayBuildPriority(
    app.pitch_upload_options.priority
  );

  return (
    <GameDataContext.Provider value={state}>
      {props.children}

      {dialogAdd && managePitches && (
        <CopyPitchesDialogHoC
          key={dialogAdd}
          identifier={`${CONTEXT_NAME}AddDialog`}
          title={
            <Flex gap="2" align="center">
              {t('pd.add-to-pitch-list')}
              {addPlayer && <Badge>{addPlayer.name}</Badge>}
              {pitchType && (
                <Badge title={t(tooltipPitchType(pitchType)).toString()}>
                  {pitchType}
                </Badge>
              )}
              <Badge>{buildPriority}</Badge>
            </Flex>
          }
          description={t('pl.select-pitch-list-to-copy-into').toString()}
          pitches={managePitches ?? []}
          onCreated={() => setDialogAdd(undefined)}
          onClose={() => setDialogAdd(undefined)}
        />
      )}

      {dialogAddAverages && managePitches && (
        <CopyPitchesDialogHoC
          key={dialogAddAverages}
          identifier={`${CONTEXT_NAME}AddAveragesDialog`}
          title={
            <Flex gap="2" align="center">
              {t('pd.add-averages-to-pitch-list')}
              {addPlayer && <Badge>{addPlayer.name}</Badge>}
              <Badge>{buildPriority}</Badge>
            </Flex>
          }
          description={t('pl.select-pitch-list-to-copy-into').toString()}
          pitches={managePitches ?? []}
          onCreated={() => setDialogAddAverages(undefined)}
          onClose={() => setDialogAddAverages(undefined)}
        />
      )}
    </GameDataContext.Provider>
  );
};
