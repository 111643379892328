import { QuestionMarkCircledIcon } from '@radix-ui/react-icons';
import { HELP_URLS } from 'classes/helpers/url.helper';
import { AuthContext } from 'contexts/auth.context';
import { SessionEventsContext } from 'contexts/session-events.context';
import { SessionDialogMode } from 'enums/session.enums';
import { t } from 'i18next';
import { useContext } from 'react';
import { useIntercom } from 'react-use-intercom';
import { SidebarNavigationTrigger } from '../nav-trigger';

export const SupportMenu = () => {
  const { showNewMessage } = useIntercom();
  const { current, showTermsOfService } = useContext(AuthContext);
  const { showDialog } = useContext(SessionEventsContext);

  return (
    <SidebarNavigationTrigger
      icon={<QuestionMarkCircledIcon />}
      label="main.support"
      actions={[
        {
          label: 'main.current-session',
          onClick: () => {
            showDialog({
              session: current.session,
              mode: SessionDialogMode.edit,
              key: Date.now(),
            });
          },
        },
        {
          label: 'main.help-center',
          onClick: () =>
            window.open(t('common.intercom-url') + HELP_URLS.LANDING),
        },
        {
          label: 'common.contact-support',
          onClick: () => showNewMessage(),
        },
        {
          label: 'main.terms-of-service',
          onClick: () => showTermsOfService(),
        },
      ]}
      small
    />
  );
};
