import { BreakCanvas } from 'classes/break-canvas';
import { StringHelper } from 'classes/helpers/string.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { DOT_SIZE_MD, DOT_SIZE_SM } from 'enums/canvas';
import { isAppearanceDark } from 'index';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { ITrajektRefBreak } from 'lib_ts/interfaces/pitches';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import React, { CSSProperties } from 'react';
import Slider from 'react-input-slider';

interface IProps {
  actual_x_in: number;
  actual_z_in: number;

  target_x_in: number;
  target_z_in: number;

  shots: IMachineShot[];
  newShotID?: string;

  // not used if disabled
  onUpdate?: (result: ITrajektRefBreak) => void;

  sliderStyle?: CSSProperties;
  sizerStyle?: CSSProperties;

  overriding?: boolean;
  disabled?: boolean;

  // e.g. causes breaks to be rendered larger with index labels
  training?: boolean;
}

interface IState {
  /** inches */
  slider_x: number;
  /** inches */
  slider_y: number;
}

export class BreakView extends React.Component<IProps, IState> {
  private init = false;

  private break_canvas = BreakCanvas.makeSquare(isAppearanceDark());

  private mainCanvasNode?: HTMLCanvasElement;
  private shotsCanvasNode?: HTMLCanvasElement;

  constructor(props: IProps) {
    super(props);

    const DEFAULT_STATE: IState = {
      slider_x: this.props.target_x_in,
      slider_y: this.props.target_z_in,
    };

    this.state = DEFAULT_STATE;

    this.drawMain = this.drawMain.bind(this);
    this.drawBreaks = this.drawBreaks.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.targetChanged = this.targetChanged.bind(this);
  }

  componentDidMount() {
    if (!this.init) {
      this.init = true;
      this.drawMain('componentDidMount');
      this.drawBreaks('componentDidMount');
      this.onDragEnd();
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (this.props.shots !== prevProps.shots) {
      this.drawBreaks('shots changed');
    }

    if (
      this.props.actual_x_in !== prevProps.actual_x_in ||
      this.props.actual_z_in !== prevProps.actual_z_in ||
      this.props.overriding !== prevProps.overriding
    ) {
      this.drawBreaks('actual or overriding changed');
    }

    if (
      this.props.target_x_in !== prevProps.target_x_in ||
      this.props.target_z_in !== prevProps.target_z_in
    ) {
      this.targetChanged();
    }
  }

  private targetChanged() {
    this.setState(
      {
        slider_x: this.props.target_x_in,
        slider_y: this.props.target_z_in,
      },
      () => this.drawBreaks('target changed')
    );
  }

  private drawMain(source: string) {
    console.debug(`drawMain (${source})`);

    const canvas = this.mainCanvasNode;
    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    this.break_canvas.drawRulers(ctx);

    this.break_canvas.drawTarget(ctx, {
      xInches: this.state.slider_x,
      zInches: this.state.slider_y,
    });
  }

  private drawBreaks(source: string) {
    console.debug(`drawBreaks (${source})`);

    const canvas = this.shotsCanvasNode;
    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (this.props.overriding) {
      // only draw one green dot for the actual override
      this.break_canvas.drawBreak(
        ctx,
        {
          xInches: this.props.actual_x_in,
          zInches: this.props.actual_z_in,
        },
        {
          isNew: false,
          size: DOT_SIZE_SM,
          index: 0,
        }
      );
      return;
    }

    // draw a dot for each shot w/ breaks, newest last
    const sortedShots = this.props.shots.sort((a, b) =>
      a._created.localeCompare(b._created)
    );

    sortedShots.forEach((s, i) => {
      const isNew = this.props.newShotID === s._id;

      const breaks = TrajHelper.getBreaksFromShot(s);

      if (this.props.training) {
        this.break_canvas.drawTrainingBreak(
          ctx,
          {
            xInches: -1 * breaks.xInches,
            zInches: breaks.zInches,
          },
          {
            isNew: isNew,
            size: DOT_SIZE_MD,
            index: i + 1,
          }
        );
        return;
      }

      this.break_canvas.drawBreak(
        ctx,
        {
          xInches: -1 * breaks.xInches,
          zInches: breaks.zInches,
        },
        {
          isNew: isNew,
          size: DOT_SIZE_SM,
          index: i + 1,
        }
      );
    });
  }

  private onChange(pos: { x: number; y: number }) {
    this.setState({
      slider_x: pos.x,
      slider_y: pos.y,
    });
  }

  private onDragEnd() {
    this.props.onUpdate?.({
      xInches: this.state.slider_x,
      zInches: this.state.slider_y,
    });
  }

  render() {
    return (
      <ErrorBoundary componentName="BreakView">
        <div className="slider" style={this.props.sliderStyle}>
          <div
            className="sizer"
            style={
              this.props.sizerStyle ?? {
                aspectRatio: '1.00',
                maxHeight: '400px',
                minHeight: '100px',
              }
            }
          >
            <canvas
              ref={(node) => (this.mainCanvasNode = node as HTMLCanvasElement)}
              data-identifier="main-canvas"
              width={this.break_canvas.CONFIG.canvas.width_px}
              height={this.break_canvas.CONFIG.canvas.height_px}
            />
            <canvas
              ref={(node) => (this.shotsCanvasNode = node as HTMLCanvasElement)}
              data-identifier="shots-canvas"
              width={this.break_canvas.CONFIG.canvas.width_px}
              height={this.break_canvas.CONFIG.canvas.height_px}
            />
            <div
              className={StringHelper.classNames([
                'slider-wrapper',
                this.props.disabled ? undefined : 'animate-fade',
              ])}
            >
              <Slider
                data-testid="BreakViewSlider"
                data-xcord={this.state.slider_x}
                data-ycord={this.state.slider_y}
                axis="xy"
                xstep={this.break_canvas.CONFIG.x.step}
                xmin={this.break_canvas.CONFIG.x.min_in}
                xmax={this.break_canvas.CONFIG.x.max_in}
                x={this.state.slider_x}
                ystep={this.break_canvas.CONFIG.y.step}
                ymin={this.break_canvas.CONFIG.y.min_in}
                ymax={this.break_canvas.CONFIG.y.max_in}
                y={this.state.slider_y}
                disabled={this.props.disabled}
                onChange={this.onChange}
                onDragEnd={this.onDragEnd}
                styles={{
                  track: {
                    display: 'none',
                    backgroundColor: 'rgba(0, 0, 255, 0)',
                    width: '100%',
                    height: '100%',
                  },
                  thumb: {
                    display: this.props.disabled ? 'none' : undefined,
                    backgroundColor: 'rgba(0, 255, 0, 0)',
                    backgroundImage: 'url(/img/crosshair.svg)',
                    backgroundSize: 'cover',
                    boxShadow: 'none',
                    height: 32,
                    width: 32,
                    margin: '-16px',
                  },
                }}
                yreverse
              />
            </div>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}
