import { CopyIcon, UpdateIcon } from '@radix-ui/react-icons';
import { Box, Flex, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonCallout } from 'components/common/callouts';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { usePitchListStore } from 'components/sections/pitch-list/store/use-pitch-list-store';
import { IMachineContext } from 'contexts/machine.context';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { IMatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { t } from 'i18next';
import { FT_TO_INCHES } from 'lib_ts/classes/math.utilities';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { getMSFromMSDict, getMergedMSDict } from 'lib_ts/classes/ms.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import {
  IBallMovement,
  IBallState,
  IPitch,
  ITrajectory,
  ITrajektRefBreak,
} from 'lib_ts/interfaces/pitches';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { PitchListsService } from 'services/pitch-lists.service';
import { StateTransformService } from 'services/state-transform.service';
import { useShallow } from 'zustand/react/shallow';

const COMPONENT_NAME = 'OptimizePitchDialog';

interface IProps {
  pitch: IPitch;
  machineCx: IMachineContext;
  matchingCx: IMatchingShotsContext;
  onClose: () => void;

  // hides the update button
  readonly: boolean;
}

// TODO: I don't think we need this many useCallbacks
export const OptimizePitchDialog = (props: IProps) => {
  const init = useRef(false);

  const listsCx = useContext(PitchListsContext);
  const listStore = usePitchListStore(
    useShallow(({ updatePitches, reloadPitches }) => ({
      updatePitches,
      reloadPitches,
    }))
  );

  const getShots = useCallback(() => {
    return props.matchingCx.safeGetShotsByPitch(props.pitch);
  }, [props.matchingCx, props.pitch]);

  const getMedianBS = useCallback((): IBallState | undefined => {
    const ballStates = getShots()
      .filter((s) => !!s.bs)
      .map((s) => s.bs as IBallState);

    return ballStates.length > 0
      ? (MiscHelper.getMedianObject(ballStates) as IBallState)
      : undefined;
  }, [getShots]);

  const getMedianBreaks = useCallback((): ITrajektRefBreak | undefined => {
    const breaks = getShots().map((s) => TrajHelper.getBreaksFromShot(s));

    if (breaks.length === 0) {
      return undefined;
    }

    return MiscHelper.getMedianObject(breaks) as unknown as ITrajektRefBreak;
  }, [getShots]);

  const getMedianTraj = useCallback((): ITrajectory | undefined => {
    const trajs = props.matchingCx
      .safeGetShotsByPitch(props.pitch)
      .filter((s) => !!s.traj)
      .map((s) => s.traj as ITrajectory);

    if (trajs.length === 0) {
      return undefined;
    }

    return MiscHelper.getMedianObject(trajs) as ITrajectory;
  }, [props.matchingCx, props.pitch]);

  const initialize = useCallback(async () => {
    if (getShots().length === 0) {
      await props.matchingCx.updatePitch(
        {
          pitch: props.pitch,
          includeHitterPresent: false,
          includeLowConfidence: false,
        },
        true
      );
    }
  }, [getShots, props.matchingCx, props.pitch]);

  useEffect(() => {
    if (!init.current) {
      init.current = true;
      initialize();
    }
  }, [initialize]);

  const submit = useCallback(
    async (mode: 'copy' | 'update') => {
      if (mode === 'update' && props.readonly) {
        NotifyHelper.error({
          message_md: 'Updating is not allowed in read-only mode.',
        });
        return;
      }

      const median_bs = getMedianBS();
      const median_breaks = getMedianBreaks();
      const median_traj = getMedianTraj();

      if (props.pitch.priority === 'Spins' && !median_bs) {
        NotifyHelper.error({
          message_md: 'Optimize pitch with spin priority requires median BS.',
        });
        return;
      }

      // in trajekt frame of ref
      const pitch_breaks =
        props.pitch.breaks ?? TrajHelper.getBreaks(props.pitch.traj);

      if (
        props.pitch.priority === 'Breaks' &&
        (!median_breaks || !pitch_breaks)
      ) {
        NotifyHelper.error({
          message_md:
            'Optimize pitch with break priority requires median breaks.',
        });
        return;
      }

      if (!median_traj) {
        NotifyHelper.error({
          message_md: 'There is no median trajectory.',
        });
        return;
      }

      const msResult = getMSFromMSDict(props.pitch, props.machineCx.machine);

      if (!msResult.ms) {
        NotifyHelper.error({ message_md: 'There is no machine state.' });
        return;
      }

      const target_mvmt = pitch_breaks
        ? ({
            break_x_ft: pitch_breaks.xInches / FT_TO_INCHES,
            break_z_ft: pitch_breaks.zInches / FT_TO_INCHES,
          } as IBallMovement)
        : undefined;

      const actual_mvmt = median_breaks
        ? ({
            break_x_ft: median_breaks.xInches / FT_TO_INCHES,
            break_z_ft: median_breaks.zInches / FT_TO_INCHES,
          } as IBallMovement)
        : undefined;

      const result = (
        await StateTransformService.getInstance().buildClosedLoop({
          machineID: props.machineCx.machine.machineID,
          pitches: [
            {
              mongo_id: props.pitch._id,
              ms: msResult.ms,
              target_bs: props.pitch.bs,
              actual_bs: median_bs ?? props.pitch.bs,
              traj: median_traj,
              priority: props.pitch.priority,
              target_mvmt: target_mvmt,
              actual_mvmt: actual_mvmt,
              use_gradient: true,
            },
          ],
          stepSize: 1,
          notifyError: true,
        })
      ).find((p) => p.mongo_id === props.pitch._id);

      if (!result) {
        NotifyHelper.error({
          message_md: 'Failed to find adjusted pitch by ID.',
        });
        return;
      }

      const nextMSDict = getMergedMSDict(
        props.machineCx.machine,
        [result.ms],
        props.pitch.msDict
      );

      switch (mode) {
        case 'copy': {
          const payload: Partial<IPitch> = {
            ...props.pitch,
            bs: result.target_bs,
            msDict: nextMSDict,
            name: `${props.pitch.name} (OPT)`,
          };

          delete payload._id;

          await PitchListsService.getInstance()
            .postPitchesToList({
              listID: props.pitch._parent_id,
              data: [payload],
            })
            .then((result) => {
              if (!result.success) {
                NotifyHelper.warning({
                  message_md: result.error ?? 'Pitch could not be created.',
                });
                return;
              }

              if (props.pitch._parent_id === listsCx.active?._id) {
                listStore.reloadPitches();
              }
            });

          break;
        }

        case 'update': {
          const payload: Partial<IPitch> = {
            _id: props.pitch._id,
            msDict: nextMSDict,
          };
          await listStore.updatePitches({
            payloads: [payload],
          });
          break;
        }

        default: {
          break;
        }
      }

      props.onClose();
    },
    [
      getMedianBS,
      getMedianBreaks,
      getMedianTraj,
      props.machineCx,
      props.matchingCx,
      props.onClose,
      props.pitch,
      props.readonly,
      listsCx.active,
      listStore.updatePitches,
      listStore.reloadPitches,
    ]
  );

  const canSave =
    (props.pitch.priority == 'Spins' && getMedianBS()) ||
    (props.pitch.priority == 'Breaks' &&
      getMedianBreaks() &&
      props.pitch.breaks &&
      getMedianTraj());

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <CommonDialog
        identifier={COMPONENT_NAME}
        width={RADIX.DIALOG.WIDTH.MD}
        title={`${t('pl.optimize-pitch')} (BETA)`}
        content={
          <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
            <Box>
              <Text>
                If a trained pitch has a different speed or spin rate than the
                uploaded target, this is the best method to approach the target.
              </Text>
            </Box>

            <CommonCallout text="Updating pitch characteristics will require retraining the pitch before it can be used." />
          </Flex>
        }
        buttons={[
          {
            icon: <CopyIcon />,
            label: 'common.copy',
            color: RADIX.COLOR.SUCCESS,
            disabled: !canSave,
            onClick: () => submit('copy'),
          },
          {
            invisible: props.readonly,
            icon: <UpdateIcon />,
            label: 'common.update',
            color: RADIX.COLOR.WARNING,
            disabled: !canSave,
            onClick: () => submit('update'),
          },
        ]}
        onClose={props.onClose}
      />
    </ErrorBoundary>
  );
};
