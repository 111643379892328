import { ErrorBoundary } from 'components/common/error-boundary';
import { SpinView } from 'components/common/spin-view';
import { CommonTabs } from 'components/common/tabs';
import { TrajectoryView } from 'components/common/trajectory-view';
import { IInputModel } from 'components/common/trajectory-view/helpers';
import { IAuthContext } from 'contexts/auth.context';
import { ISeamOrientation, ISpin } from 'lib_ts/interfaces/pitches/i-base';
import React from 'react';

enum TabKey {
  Spin = 'Spin',
  Trajectory = 'Trajectory',
}

interface IProps {
  // update this to restart the traj animation
  animateKey: number;

  authCx: IAuthContext;

  /** for spin tab */
  spin: ISpin;
  /** for spin tab */
  orientation: ISeamOrientation;
  /** for trajectory tab */
  trajModels: IInputModel[];
}

interface IState {
  activeTab: TabKey;
}

export class BallPreview extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      activeTab: TabKey.Spin,
    };
  }

  render() {
    const spinView = (
      <SpinView
        wx={this.props.spin.wx}
        wy={this.props.spin.wy}
        wz={this.props.spin.wz}
        latitude_deg={this.props.orientation.latitude_deg}
        longitude_deg={this.props.orientation.longitude_deg}
      />
    );

    // tabs are not necessary if traj view isn't shown, also accounts for turning beta off while on the traj tab
    const content = this.props.authCx.showBeta ? (
      <CommonTabs
        value={this.state.activeTab}
        onValueChange={(value) => {
          this.setState({ activeTab: value as TabKey });
        }}
        tabs={[
          {
            value: TabKey.Spin,
            label: 'common.spin',
            content: spinView,
          },
          {
            value: TabKey.Trajectory,
            label: 'common.trajectory',
            content: (
              <TrajectoryView
                animateKey={this.props.animateKey}
                models={this.props.trajModels}
                startingZoom={1.75}
              />
            ),
          },
        ]}
      />
    ) : (
      spinView
    );

    return <ErrorBoundary componentName="BallPreview">{content}</ErrorBoundary>;
  }
}
