import { NotifyHelper } from 'classes/helpers/notify.helper';
import { addMonths, startOfToday } from 'date-fns';
import { t } from 'i18next';
import { MetricInterval } from 'lib_ts/enums/machine-models.enums';
import { BallType } from 'lib_ts/enums/machine.enums';
import { IServerResponse } from 'lib_ts/interfaces/common/i-server-response';
import {
  IEvalModelResult,
  IPythonEvalModelsResult,
} from 'lib_ts/interfaces/modelling/i-eval-models';
import {
  IDataCollectionEvalQuery,
  IGatherShotDataQuery,
  IGatherSummary,
} from 'lib_ts/interfaces/modelling/i-gather-shot-data';
import { IMachineModel } from 'lib_ts/interfaces/modelling/i-machine-model';
import {
  IRealMachineMetric,
  IRealMachinePerformance,
} from 'lib_ts/interfaces/modelling/i-real-machine-metric';
import {
  ITrainCollectionModelRequest,
  ITrainModelsRequest,
} from 'lib_ts/interfaces/modelling/i-train-model';
import { BaseRESTService } from 'services/_base-rest.service';

export const DEFAULT_MIN_GROUPS = 20;
export const DEFAULT_MAX_GROUPS = 300;
export const DEFAULT_RECORD_LIMIT = 8_000;

export const getDefaultDataFilter = (
  start: Date = addMonths(startOfToday(), -3),
  end: Date = new Date()
): IGatherShotDataQuery => ({
  ball_type: BallType.MLB,
  start_date: start.toISOString(),
  end_date: end.toISOString(),
  min_groups: DEFAULT_MIN_GROUPS,
  max_groups: DEFAULT_MAX_GROUPS,
  limit: DEFAULT_RECORD_LIMIT,
  min_group_size: 3,
  speed_stdev_limit_mph: 1.4,
  spin_stdev_limit_rpm: 600,
  break_stdev_limit_ft: 0.4,
});

export class AdminMachineModelsService extends BaseRESTService {
  private static instance: AdminMachineModelsService;
  static getInstance(): AdminMachineModelsService {
    if (!AdminMachineModelsService.instance) {
      AdminMachineModelsService.instance = new AdminMachineModelsService();
    }

    return AdminMachineModelsService.instance;
  }

  private constructor() {
    super({
      controller: 'machine-models',
    });
  }

  /** get all teams, limited to user's team if not a super admin */
  async getAllModels(): Promise<IMachineModel[]> {
    return await this.get({
      uri: 'all',
    })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md:
              result.error ?? 'There was an error while fetching all models.',
          });
          return [];
        }

        return result.data as IMachineModel[];
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: 'There was an error while fetching all models.',
        });
        return [];
      });
  }

  // machine._id expected, not machine.machineID
  async getMetricsForMachine(config: {
    machine_id: string;
    job_interval: MetricInterval;
    start_date?: Date;
  }): Promise<IRealMachineMetric[]> {
    const results: IRealMachineMetric[] = await this.get({
      uri: 'machine/metrics',
      params: {
        machine_id: config.machine_id,
        job_interval: config.job_interval,
        start_date: (
          config.start_date ?? addMonths(new Date(), -3)
        ).toISOString(),
      } as any,
    })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md:
              result.error ??
              'There was an error while fetching machine performance.',
          });
          return [];
        }

        return result.data as IRealMachineMetric[];
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: 'There was an error while fetching machine performance.',
        });
        return [];
      });

    return results;
  }

  /** get models created by the current admin for the current machine */
  async getCreatedModels(): Promise<IMachineModel[]> {
    return await this.get({
      uri: 'created',
    })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md:
              result.error ??
              'There was an error while fetching created models.',
          });
          return [];
        }

        return result.data as IMachineModel[];
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: 'There was an error while fetching created models.',
        });
        return [];
      });
  }

  /** inserts a new record */
  async copyModel(
    data: Partial<IMachineModel>
  ): Promise<IMachineModel | undefined> {
    return await this.post({ uri: 'copy' }, data)
      .then((result: IServerResponse) => {
        if (!result.success) {
          console.warn(result.error);
          NotifyHelper.warning({
            message_md: result.error ?? t('common.request-failed-msg'),
          });
          return undefined;
        }

        const response: IMachineModel = result.data;

        NotifyHelper.success({
          message_md: `${response.name} successfully created!`,
        });

        return response;
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });
        return undefined;
      });
  }

  /** creation may take time, so the server will just respond with success/fail */
  async trainModels(
    data: ITrainModelsRequest
  ): Promise<IPythonEvalModelsResult | undefined> {
    return await this.post(
      {
        uri: 'train/models',
      },
      data
    )
      .then((result: IServerResponse) => {
        if (!result.success) {
          console.warn(result.error);
          NotifyHelper.warning({
            message_md: result.error ?? t('common.request-failed-msg'),
          });
          return undefined;
        }

        const response: IPythonEvalModelsResult = result.data;

        const errors = response.errors;
        if (errors && errors.length > 0) {
          NotifyHelper.warning({
            message_md: [
              'Model creation completed with one or more errors:',
              ...errors.map((err) => ` - ${err}`),
            ].join('\n'),
            inbox: true,
            delay_ms: 0,
          });
        }

        return response;
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });
        return undefined;
      });
  }

  /** creation may take time, so the server will just respond with success/fail */
  async trainCollectionModel(
    data: ITrainCollectionModelRequest
  ): Promise<IMachineModel | undefined> {
    return await this.post(
      {
        uri: 'train/collection/model',
      },
      data
    )
      .then((result: IServerResponse) => {
        if (!result.success) {
          console.warn(result.error);
          NotifyHelper.warning({
            message_md: result.error ?? t('common.request-failed-msg'),
          });
          return undefined;
        }

        const response: IMachineModel = result.data;
        return response;
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });
        return undefined;
      });
  }

  async gatherSummary(data: IGatherShotDataQuery): Promise<IGatherSummary[]> {
    return await this.post(
      {
        uri: 'gather/summary',
      },
      data
    )
      .then((result: IServerResponse) => {
        if (!result.success) {
          console.warn(result.error);
          NotifyHelper.warning({
            message_md: result.error ?? t('common.request-failed-msg'),
          });
          return [];
        }

        const response: IGatherSummary[] = result.data;
        return response;
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });
        return [];
      });
  }

  async evalModelMetrics(
    modelIDs: string[],
    query: IGatherShotDataQuery
  ): Promise<IEvalModelResult[]> {
    return await this.post(
      {
        uri: 'evaluate/model/metrics',
        params: {
          model_ids: modelIDs.join(','),
        } as any,
      },
      query
    )
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? t('common.request-failed-msg'),
          });
          console.warn(result.error);
          return [];
        }

        const response: IPythonEvalModelsResult = result.data;

        if (response.errors && response.errors.length > 0) {
          NotifyHelper.warning({
            message_md: [
              'Model evaluation completed with one or more errors:',
              ...response.errors.map((err) => ` - ${err}`),
            ].join('\n'),
            inbox: true,
            delay_ms: 0,
          });
        }

        return response.results ?? [];
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });
        return [];
      });
  }

  async evalCollectionModel(
    query: IDataCollectionEvalQuery
  ): Promise<IEvalModelResult | undefined> {
    return await this.post({ uri: 'evaluate/collection/model' }, query)
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? t('common.request-failed-msg'),
          });
          console.warn(result.error);
          return undefined;
        }

        const response: IPythonEvalModelsResult = result.data;

        if (response.errors && response.errors.length > 0) {
          NotifyHelper.warning({
            message_md: [
              'Model evaluation completed with one or more errors:',
              ...response.errors.map((err) => ` - ${err}`),
            ].join('\n'),
            inbox: true,
            delay_ms: 0,
          });
        }

        return response.results?.[0];
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });
        return undefined;
      });
  }

  async evalCollectionMachine(
    query: IDataCollectionEvalQuery
  ): Promise<IRealMachinePerformance | undefined> {
    return await this.post({ uri: 'evaluate/collection/machine' }, query)
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? t('common.request-failed-msg'),
          });
          console.warn(result.error);
          return undefined;
        }

        const response: IRealMachinePerformance = result.data;

        return response;
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });
        return undefined;
      });
  }

  async evalMachineMetrics(
    machineIDs: string[]
  ): Promise<Partial<IRealMachineMetric>[]> {
    return await this.post(
      {
        uri: 'evaluate/machine/metrics',
      },
      machineIDs
    )
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? t('common.request-failed-msg'),
          });
          console.warn(result.error);
          return [];
        }

        return result.data as Partial<IRealMachineMetric>[];
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });
        return [];
      });
  }

  async getMetricsForModel(config: {
    modelID: string;
    interval: MetricInterval;
  }): Promise<IRealMachineMetric[]> {
    return await this.get({
      uri: 'model/metrics',
      params: {
        model_id: config.modelID,
        job_interval: config.interval,
      } as any,
    })
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? t('common.request-failed-msg'),
          });
          console.warn(result.error);
          return [];
        }

        return result.data as IRealMachineMetric[];
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: t('common.request-failed-msg'),
        });
        return [];
      });
  }

  async updateModel(
    data: Partial<IMachineModel>,
    silently?: boolean
  ): Promise<IMachineModel | undefined> {
    if (!data._id) {
      throw new Error('Cannot put without id');
    }

    return await this.put(
      {
        uri: '',
        params: {
          model_id: data._id,
        } as any,
      },
      data
    )
      .then((result: IServerResponse) => {
        if (result.success) {
          const output = result.data as IMachineModel;

          if (!silently) {
            NotifyHelper.success({
              message_md: `Model "${output.name}" updated successfully!`,
            });
          }
          return output;
        }

        console.warn(result.error);

        if (!silently) {
          NotifyHelper.warning({
            message_md: result.error ?? t('common.request-failed-msg'),
          });
        }

        return undefined;
      })
      .catch((reason) => {
        console.error(reason);

        if (!silently) {
          NotifyHelper.error({
            message_md: t('common.request-failed-msg'),
          });
        }

        return undefined;
      });
  }

  async archiveModels(ids: string[]): Promise<boolean> {
    return await this.post(
      {
        uri: 'archive',
      },
      ids
    )
      .then((result: IServerResponse) => {
        if (result.success) {
          NotifyHelper.success({ message_md: 'Archiving successful!' });
          return true;
        }

        console.warn(result.error);
        NotifyHelper.warning({
          message_md: result.error ?? 'Archiving failed.',
        });
        return false;
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({ message_md: 'Archiving failed.' });
        return false;
      });
  }

  async archiveUnused(): Promise<void> {
    return await this.get({
      uri: 'archive/unused',
    })
      .then((result: IServerResponse) => {
        if (!result.success) {
          console.warn(result.error);
          NotifyHelper.warning({
            message_md:
              result.error ?? 'Failed to archive unused machine models.',
          });
          return;
        }

        const data = result.data as { matches: number };
        if (data.matches === 0) {
          NotifyHelper.info({
            message_md: 'No unused machine models to archive.',
          });
          return;
        }

        NotifyHelper.success({
          message_md: `Successfully archived ${data.matches} unused machine ${
            data.matches === 1 ? 'model' : 'models'
          }!`,
        });
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: 'Failed to archive unused machine models.',
        });
      });
  }

  async createMachineMetric(config: {
    query: IGatherShotDataQuery;
    job_interval: MetricInterval;
    list_length: number;
  }): Promise<IRealMachineMetric | undefined> {
    return await this.post(
      {
        uri: 'machine/metric',
        params: {
          job_interval: config.job_interval,
          list_length: config.list_length,
        } as any,
      },
      config.query
    )
      .then((result: IServerResponse) => {
        if (!result.success) {
          NotifyHelper.warning({
            message_md: result.error ?? 'Failed to create real machine metric.',
          });
          return undefined;
        }

        return result.data as IRealMachineMetric;
      })
      .catch((reason) => {
        console.error(reason);
        NotifyHelper.error({
          message_md: 'Failed to create real machine metric.',
        });
        return undefined;
      });
  }
}
