import { Button, Strong } from '@radix-ui/themes';
import { CustomIcon } from 'components/common/custom-icon';
import { DialogButton } from 'components/common/dialogs/button';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { ErrorBoundary } from 'components/common/error-boundary';
import { MachineContext } from 'contexts/machine.context';
import { CustomIconPath } from 'enums/custom.enums';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useCallback, useContext, useMemo, useState } from 'react';
import { SessionEventsService } from 'services/session-events.service';

interface IProps {
  className?: string;
  // i.e. confirm before enabling auto-fire (e.g. for non-SA)
  confirm?: boolean;
  as?: 'dialog-button';

  // i.e. value after toggling
  beforeToggleFn?: (newValue: boolean) => void;
}

export const MachineAutoFireButton = (props: IProps) => {
  const { autoFire, setAutoFire } = useContext(MachineContext);
  const [confirmed, setConfirmed] = useState(false);
  const [dialogConfirm, setDialogConfirm] = useState<number>();

  const handleClick = useCallback(() => {
    const nextValue = !autoFire;

    if (props.confirm && nextValue && !confirmed) {
      setDialogConfirm(Date.now());
      return;
    }

    props.beforeToggleFn?.(nextValue);

    SessionEventsService.postEvent({
      category: 'machine',
      tags: 'training',
      data: {
        event: 'toggle auto-fire',
        auto: nextValue,
      },
    });

    setAutoFire(nextValue);
  }, [props.confirm, props.beforeToggleFn, autoFire, confirmed]);

  const button = useMemo(() => {
    switch (props.as) {
      case 'dialog-button': {
        return (
          <DialogButton
            className={props.className}
            color={autoFire ? RADIX.COLOR.WARNING : RADIX.COLOR.NEUTRAL}
            variant="soft"
            onClick={handleClick}
            label="common.auto"
            suffixIcon={
              <CustomIcon
                icon={
                  autoFire ? CustomIconPath.SwitchOn : CustomIconPath.SwitchOff
                }
              />
            }
          />
        );
      }

      default: {
        return (
          <Button
            size={RADIX.BUTTON.SIZE.SM}
            className={props.className}
            color={autoFire ? RADIX.COLOR.WARNING : RADIX.COLOR.NEUTRAL}
            variant="soft"
            onClick={handleClick}
          >
            {t('common.auto')}
            &nbsp;
            <CustomIcon
              icon={
                autoFire ? CustomIconPath.SwitchOn : CustomIconPath.SwitchOff
              }
            />
          </Button>
        );
      }
    }
  }, [props.className, props.as, autoFire, handleClick]);

  return (
    <ErrorBoundary componentName="MachineAutoFireButton">
      {button}
      {dialogConfirm && (
        <CommonConfirmationDialog
          key={dialogConfirm}
          identifier="MachineAutoFireButtonConfirm"
          title="common.enable-auto-firing"
          content={
            <p>
              You are about to enable <Strong>Auto Firing</Strong>. Please
              ensure the following:
              <ol>
                <li>Wear a helmet while hitting off Arc.</li>
                <li>Do not leave the machine or app unattended.</li>
                <li>
                  None of the pitches in your queue are aimed inside toward the
                  batter.
                </li>
              </ol>
              Be aware that misuse can result in serious injury.{' '}
              <Strong>Are you sure you want to enable Auto Firing?</Strong>
            </p>
          }
          action={{
            label: 'common.enable-auto-firing',
            onClick: async () => {
              setConfirmed(true);
              setDialogConfirm(undefined);

              props.beforeToggleFn?.(true);

              setAutoFire(true);

              SessionEventsService.postEvent({
                category: 'machine',
                tags: 'training',
                data: {
                  event: 'toggle auto-fire (confirm)',
                  auto: true,
                },
              });
            },
          }}
        />
      )}
    </ErrorBoundary>
  );
};
