import { Box, Flex } from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { Content } from 'components/main/content';
import { Maintenance } from 'components/main/landing/forms/maintenance';
import { Login } from 'components/main/landing/login';
import { MachineResponseListener } from 'components/main/listeners/machine-response';
import { NotificationListener } from 'components/main/listeners/notification';
import { QueueListenerHoC } from 'components/main/listeners/queue';
import { Sidebar } from 'components/main/sidebar';
import env from 'config';
import { AuthContext } from 'contexts/auth.context';
import { CookiesContext } from 'contexts/cookies.context';
import { HittersProvider } from 'contexts/hitters.context';
import { InboxContext } from 'contexts/inbox';
import { MachineProvider } from 'contexts/machine.context';
import { PitchListsProvider } from 'contexts/pitch-lists/lists.context';
import { MatchingShotsProvider } from 'contexts/pitch-lists/matching-shots.context';
import { PitchDesignProvider } from 'contexts/pitch-lists/pitch-design.context';
import { SectionsProvider } from 'contexts/sections.context';
import { SessionEventsProvider } from 'contexts/session-events.context';
import { VideosProvider } from 'contexts/videos/videos.context';
import { SidebarState } from 'enums/cookies.enums';
import { useContext } from 'react';

const AuthorizedMain = () => {
  const { app } = useContext(CookiesContext);

  return (
    <ErrorBoundary componentName="AuthorizedMain" hideIntercom>
      <HittersProvider>
        <SessionEventsProvider>
          <MachineProvider>
            <VideosProvider>
              <SectionsProvider>
                <MatchingShotsProvider>
                  <PitchListsProvider>
                    <PitchDesignProvider>
                      <Flex
                        className="Main"
                        style={{
                          position: 'fixed',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                        }}
                      >
                        {/* sidebar */}
                        <Box
                          className={StringHelper.classNames([
                            'MainSidebar',
                            app.sidebar_state === SidebarState.full
                              ? 'full'
                              : 'mini',
                          ])}
                        >
                          <Sidebar />
                        </Box>
                        {/* content */}
                        <Box className="MainContent" flexGrow="1">
                          <Content />
                        </Box>
                      </Flex>
                      <QueueListenerHoC />
                      <MachineResponseListener />
                    </PitchDesignProvider>
                  </PitchListsProvider>
                </MatchingShotsProvider>
              </SectionsProvider>
            </VideosProvider>
          </MachineProvider>
        </SessionEventsProvider>
      </HittersProvider>
    </ErrorBoundary>
  );
};
const UnauthorizedMain = () => {
  const cookiesCx = useContext(CookiesContext);
  const authCx = useContext(AuthContext);
  const inboxCx = useContext(InboxContext);

  return (
    <ErrorBoundary componentName="UnauthorizedMain" hideIntercom>
      <NotificationListener cookiesCx={cookiesCx} inboxCx={inboxCx} />
      <Login authCx={authCx} />
    </ErrorBoundary>
  );
};

export const Main = () => {
  const { current } = useContext(AuthContext);

  if (env.maintenance) {
    return <Maintenance />;
  }

  if (current.auth) {
    return <AuthorizedMain />;
  }

  return <UnauthorizedMain />;
};
