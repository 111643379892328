import { Box, Flex, Text } from '@radix-ui/themes';
import { CommonCallout } from 'components/common/callouts';
import { ErrorBoundary } from 'components/common/error-boundary';
import { InstallationContext } from 'components/machine/dialogs/installation/context';
import { CommonStepHeader } from 'components/machine/dialogs/installation/header';
import { DataCollectorHoC } from 'components/machine/dialogs/installation/steps/data-collector';
import { MachineContext } from 'contexts/machine.context';
import { SubStepState } from 'enums/installation';
import { ReferenceListType } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPitch } from 'lib_ts/interfaces/pitches';
import { useContext, useEffect, useState } from 'react';
import { PitchesService } from 'services/pitches.service';

const COMPONENT_NAME = 'StepRepeatability';

interface IProps {
  state: SubStepState;
  onComplete: () => void;
}

export const StepRepeatability = (props: IProps) => {
  const { details, updateDetails } = useContext(InstallationContext);
  const { machine, update } = useContext(MachineContext);
  const [pitches, setPitches] = useState<IPitch[]>();

  useEffect(() => {
    PitchesService.getInstance()
      .getReferenceListPitches(ReferenceListType.Repeatability)
      .then((result) => {
        setPitches(result);

        if (result.length === 0) {
          return;
        }

        if (!machine.last_installation) {
          return;
        }

        if (details.repeatability_list_id) {
          return;
        }

        // fix the list id to match
        updateDetails({
          repeatability_list_id: result[0]._parent_id,
        });
      });
  }, []);

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <Flex direction="column" gap="4">
        <CommonStepHeader label="Repeatability Testing" />

        {props.state === SubStepState.NotStarted && (
          <Box>
            <Text color={RADIX.COLOR.SECONDARY}>
              You're about to fire the Arc to test its repeatability with the
              selected ball type. This ensures consistent and reliable pitch
              performance. Please make sure only {details.ball_type} are placed
              in the Arc. Press fire when ready to begin the repeatability test.
            </Text>
          </Box>
        )}

        {props.state !== SubStepState.NotStarted &&
          pitches &&
          (pitches.length === 0 ? (
            <CommonCallout text="Failed to find any pitches for repeatability, please contact support." />
          ) : (
            <DataCollectorHoC
              // for drawing error instead of complete
              state={props.state}
              collectionID={details.repeatability_id}
              pitches={pitches}
              refType={ReferenceListType.Repeatability}
              onComplete={props.onComplete}
            />
          ))}
      </Flex>
    </ErrorBoundary>
  );
};
