import { Skeleton } from '@radix-ui/themes';
import { PitchDesign } from 'components/sections/pitch-design';
import { AuthContext } from 'contexts/auth.context';
import { MachineContext } from 'contexts/machine.context';
import { useContext } from 'react';

export const Design = () => {
  const { current } = useContext(AuthContext);
  const { activeModel } = useContext(MachineContext);

  // wait for auth and machine context before rendering anything
  if (!current.auth || !activeModel) {
    return <Skeleton height="50%" />;
  }

  return <PitchDesign />;
};
