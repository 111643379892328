import { CardStackPlusIcon } from '@radix-ui/react-icons';
import { Box, Button, Card, Flex, Heading, IconButton } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { AddPitchIcon } from 'components/common/custom-icon/shorthands';
import { CopyPitchesDialogHoC } from 'components/common/dialogs/copy-pitches';
import { ErrorBoundary } from 'components/common/error-boundary';
import { SearchHitter } from 'components/common/hitters/search-hitter';
import { PlateView } from 'components/common/plate-view';
import { CommonVideoPreview } from 'components/common/video/preview';
import { PitchListSidebarControls } from 'components/sections/pitch-list/sidebar/controls';
import { PitchListSidebarSectionTitle } from 'components/sections/pitch-list/sidebar/section-title';
import { usePitchListStore } from 'components/sections/pitch-list/store/use-pitch-list-store';
import { AimingContext } from 'contexts/aiming.context';
import { AuthContext } from 'contexts/auth.context';
import { CookiesContext } from 'contexts/cookies.context';
import { HittersContext, IHittersContext } from 'contexts/hitters.context';
import { MachineContext } from 'contexts/machine.context';
import { MatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { VideosContext } from 'contexts/videos/videos.context';
import { t } from 'i18next';
import {
  GAME_STATUS_DISABLE_ACTION_MSG,
  GameStatus,
} from 'lib_ts/enums/mlb.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IVideoPlayback } from 'lib_ts/interfaces/i-video';
import { IPitch } from 'lib_ts/interfaces/pitches';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

const COMPONENT_NAME = 'PitchListSidebar';

interface IProps {
  // e.g. when a user clicks a new pitch or aims, ignore auto-fire until manual fire
  ignoreAutoFireKey?: number;

  // while training, plate view should not be provided any matching context to prevent re-renders as shots come in
  training?: boolean;

  // whether fire controls are shown
  enableFire?: boolean;

  // whether add to PL button at the bottom is shown
  enableAddToList?: boolean;
  // whether save as button near plate is shown
  enableSaveAs?: boolean;

  handleSave?: (pitch: IPitch) => void;

  onMatchesChanged: (newPitch: boolean) => void;
  onVideoChanged: (video_id: string | undefined) => void;

  handleTrainPitches: (config: {
    ids: string[];
    promptRefresh: boolean;
  }) => void;
}

export const PitchListSidebar = (props: IProps) => {
  const listStore = usePitchListStore(
    useShallow(({ reloadPitches }) => ({
      reloadPitches,
    }))
  );

  const machineCx = useContext(MachineContext);
  const matchingCx = useContext(MatchingShotsContext);
  const videosCx = useContext(VideosContext);
  const aimingCx = useContext(AimingContext);
  const authCx = useContext(AuthContext);
  const cookiesCx = useContext(CookiesContext);

  // This sidebar component is also used by MlbStatsBrowse, where HittersContext is not provided
  const hittersCx: IHittersContext | undefined = useContext(HittersContext);

  const lastVideoId = useRef<string | undefined>();

  const [videoPlayback, setVideoPlayback] = useState<IVideoPlayback>();
  const [dialogSaveAs, setDialogSaveAs] = useState<number>();

  const updateVideo = async (id?: string) => {
    lastVideoId.current = id;

    if (!id) {
      setVideoPlayback(undefined);
      return;
    }

    const playback = await videosCx.getCachedPlayback(id);

    if (id !== lastVideoId.current) {
      // handles when user changes pitches rapidly and the playback can't load in time
      return;
    }

    setVideoPlayback(playback);
  };

  useEffect(() => {
    // Make sure this is only called when changed and on mount
    updateVideo(aimingCx.pitch?.video_id);
  }, [aimingCx.pitch?.video_id]);

  const aimed = useMemo(() => {
    if (!aimingCx.pitch) {
      return undefined;
    }

    return aimingCx.getAimed({
      training: false,
      usingShots: [],
    });
  }, [aimingCx.pitch, aimingCx.plate]);

  const disabled = !aimed;

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <Card size="2" data-identifier="PLSidebar">
        <Box
          // for truncation
          minWidth="0"
          mb="1"
        >
          <Heading size="3" truncate>
            {aimed
              ? aimed.pitch.name ?? t('pl.unnamed-pitch')
              : t('pl.select-a-pitch')}
          </Heading>
        </Box>

        <Flex direction="column" gap="4">
          {/* plate */}
          <Flex direction="column" gap="2">
            <Flex gap={RADIX.FLEX.GAP.SM} justify="between">
              <PitchListSidebarSectionTitle
                label="pd.plate-location"
                title={t('pd.batters-pov').toString()}
                className="cursor-help"
              />

              {props.enableSaveAs && (
                <Box>
                  <IconButton
                    size="1"
                    className="btn-floating"
                    title={t('common.save-as').toString()}
                    color={RADIX.COLOR.NEUTRAL}
                    variant={RADIX.BUTTON.VARIANT.BORDERLESS}
                    disabled={disabled}
                    onClick={() => {
                      if (authCx.gameStatus === GameStatus.InProgress) {
                        NotifyHelper.warning({
                          message_md: GAME_STATUS_DISABLE_ACTION_MSG,
                        });
                        return;
                      }

                      setDialogSaveAs(Date.now());
                    }}
                  >
                    <CardStackPlusIcon />
                  </IconButton>
                </Box>
              )}
            </Flex>

            <Flex justify="center">
              <Box>
                <PlateView
                  cookiesCx={cookiesCx}
                  authCx={authCx}
                  machineCx={machineCx}
                  matchingCx={matchingCx}
                  drawShots={!props.training}
                  pitch={aimingCx.pitch}
                  hitter={hittersCx?.active}
                  onUpdate={(location) => aimingCx.setPlate(location)}
                  onMatchesChanged={props.onMatchesChanged}
                  disabled={disabled}
                  border
                />
              </Box>
            </Flex>
          </Flex>

          {/* hitter */}
          {props.enableFire && (
            <Flex direction="column" gap="2">
              <PitchListSidebarSectionTitle label="hitters.hitter" />
              <SearchHitter />
            </Flex>
          )}

          {/* video */}
          <CommonVideoPreview
            previewPx={aimed?.ms.px}
            previewPz={aimed?.ms.pz}
            playback={videoPlayback}
            disabled={disabled}
            selectConfig={{
              px: aimed?.pitch.bs.px ?? 0,
              video_id: aimed?.pitch.video_id,
              onChange: (video_id) => {
                // will clear the video when X is clicked
                updateVideo(video_id);

                // parent needs to know (e.g. to update the pitch record in db)
                props.onVideoChanged(video_id);
              },
            }}
          />

          {/* controls */}
          {props.enableFire && (
            <PitchListSidebarControls
              ignoreAutoFireKey={props.ignoreAutoFireKey}
              machineBtnClassName="btn-block text-titlecase"
              handleTrainPitches={props.handleTrainPitches}
            />
          )}

          {props.enableAddToList && (
            <Button
              disabled={!aimed?.pitch}
              onClick={() => {
                if (!aimed) {
                  return;
                }

                if (!aimed.pitch) {
                  return;
                }

                if (!props.handleSave) {
                  NotifyHelper.debug({
                    message_md: 'There is no handleSave callback configured!',
                  });
                  return;
                }

                if (authCx.gameStatus === GameStatus.InProgress) {
                  NotifyHelper.warning({
                    message_md: GAME_STATUS_DISABLE_ACTION_MSG,
                  });
                  return;
                }

                props.handleSave(aimed.pitch);
              }}
            >
              <AddPitchIcon /> {t('pd.add-to-pitch-list')}
            </Button>
          )}
        </Flex>
      </Card>

      {dialogSaveAs && aimed && (
        <CopyPitchesDialogHoC
          key={dialogSaveAs}
          title={t('pd.add-to-pitch-list')}
          identifier="PitchListSidebarSavePitchDialog"
          reloadPitches={listStore.reloadPitches}
          description={t('pl.complete-form-to-save-pitch-to-list').toString()}
          pitches={[aimed.pitch]}
          onCreated={() => setDialogSaveAs(undefined)}
          onClose={() => setDialogSaveAs(undefined)}
        />
      )}
    </ErrorBoundary>
  );
};
