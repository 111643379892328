import { Card, Flex, Heading } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { BallPreview } from 'components/common/ball-preview';
import { ErrorBoundary } from 'components/common/error-boundary';
import { GROUP_TARGET } from 'components/common/trajectory-view/helpers';
import { CommonVideoPreview } from 'components/common/video/preview';
import { AuthContext } from 'contexts/auth.context';
import { PitchDesignContext } from 'contexts/pitch-lists/pitch-design.context';
import { VideosContext } from 'contexts/videos/videos.context';
import { t } from 'i18next';
import { VideoHelper } from 'lib_ts/classes/video.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IVideoPlayback } from 'lib_ts/interfaces/i-video';
import { useCallback, useContext, useEffect, useState } from 'react';

const COMPONENT_NAME = 'PitchDesignSidebar';

export const PitchDesignSidebar = () => {
  const { getCachedPlayback, getVideo } = useContext(VideosContext);
  const { ball, videoID, workingKey, workingChars, setVideoID } =
    useContext(PitchDesignContext);

  const [loading, setLoading] = useState(false);
  const [playback, setPlayback] = useState<IVideoPlayback>();

  useEffect(() => {
    if (!videoID) {
      setPlayback(undefined);
      return;
    }

    // prevent changing again while still loading a request
    setLoading(true);

    getCachedPlayback(videoID)
      .then((result) => {
        setPlayback(result);
      })
      .finally(() => setLoading(false));
  }, [videoID]);

  /** provide undefined video_id to clear selection */
  const _handleVideoChange = useCallback(
    async (video_id: string | undefined) => {
      try {
        if (!video_id) {
          // clear selection and playback
          setVideoID(undefined);
          return;
        }

        // validate the video
        const video = getVideo(video_id);

        if (!video) {
          NotifyHelper.error({
            message_md: t('videos.video-x-not-in-context', { x: video_id }),
          });
          return;
        }

        const warnings = VideoHelper.validateSelection({
          position: {
            px: ball.px ?? 0,
            py: ball.py ?? 0,
            pz: ball.pz ?? 0,
          },
          video: video,
        });

        if (warnings.length > 0) {
          NotifyHelper.warning({
            message_md: warnings[0],
            inbox: true,
          });
        }

        setVideoID(video_id);
      } catch (e) {
        console.error(e);
        setVideoID(undefined);
      }
    },
    []
  );

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
        <Card size="2">
          <Heading size={RADIX.HEADING.SIZE.MD}>{t('pd.ball-preview')}</Heading>

          <AuthContext.Consumer>
            {(authCx) => {
              if (
                ball &&
                workingChars &&
                workingChars.traj &&
                workingChars.bs
              ) {
                return (
                  <BallPreview
                    authCx={authCx}
                    animateKey={workingKey}
                    spin={{
                      wx: ball.wx ?? 0,
                      wy: ball.wy ?? 0,
                      wz: ball.wz ?? 0,
                    }}
                    orientation={{
                      latitude_deg: ball.latitude_deg ?? 0,
                      longitude_deg: ball.longitude_deg ?? 0,
                    }}
                    trajModels={[
                      {
                        guid: 'main',
                        description: 'common.target-trajectory-of-the-pitch',
                        button_group: GROUP_TARGET,
                        color: GROUP_TARGET.color,
                        traj: workingChars.traj,
                        bs: workingChars.bs,
                      },
                    ]}
                  />
                );
              }
            }}
          </AuthContext.Consumer>
        </Card>

        <Card size="2">
          <CommonVideoPreview
            disabled={loading}
            previewPx={ball.px}
            previewPz={ball.pz}
            playback={playback}
            selectConfig={{
              px: ball.px ?? 0,
              video_id: videoID,
              onChange: _handleVideoChange,
            }}
          />
        </Card>
      </Flex>
    </ErrorBoundary>
  );
};
