import { Flex, Heading, Strong, Table, Text } from '@radix-ui/themes';
import { ModelPredictionHelper } from 'classes/helpers/model-prediction.helper';
import { HELP_URLS } from 'classes/helpers/url.helper';
import { CommonCallout } from 'components/common/callouts';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTooltip } from 'components/common/tooltip';
import { PassFail } from 'components/sections/machine-calibration/steps/review-metric';
import { t } from 'i18next';
import { PassResult } from 'lib_ts/enums/machine.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IEvalModelResult } from 'lib_ts/interfaces/modelling/i-eval-models';
import React from 'react';

const COMPONENT_NAME = 'ModelScorecard';

const COL_WIDTH = '150px';

const TOOLTIP_TEXT =
  'This table is only visible to super admins (e.g. technicians).';

interface IRowConfig {
  label: string;
  units: string;
  checkResult: PassResult;
  mean: number;
  max: number;
  precision: number;
}

const Pass = (text: string) => <Text color={RADIX.COLOR.SUCCESS}>{text}</Text>;

const Fail = (text: string) => (
  <Text color={RADIX.COLOR.DANGER}>
    <Strong>{text}</Strong>
  </Text>
);

interface IProps {
  modelEval: IEvalModelResult;
}

interface IState {}

export class ModelScorecard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.renderRow = this.renderRow.bind(this);
  }

  private renderRow(config: IRowConfig, index: number) {
    return (
      <Table.Row key={index}>
        <Table.Cell>
          {config.label}{' '}
          <Text size={RADIX.TEXT.SIZE.SM} color={RADIX.COLOR.SECONDARY}>
            {config.units}
          </Text>
        </Table.Cell>
        <Table.Cell>
          {config.checkResult === PassResult.Pass ? Pass('PASS') : Fail('FAIL')}
        </Table.Cell>
        <Table.Cell align="right">
          {([PassResult.Mean, PassResult.Both].includes(config.checkResult)
            ? Fail
            : Pass)(config.mean.toFixed(config.precision))}
        </Table.Cell>
        <Table.Cell align="right">
          {([PassResult.Max, PassResult.Both].includes(config.checkResult)
            ? Fail
            : Pass)(config.max.toFixed(config.precision))}
        </Table.Cell>
      </Table.Row>
    );
  }

  render() {
    const performance = this.props.modelEval.model_performance;
    const overallPass = ModelPredictionHelper.checkOverall(performance);

    const rows: IRowConfig[] = [
      {
        label: 'common.speed',
        units: 'mph',
        checkResult: ModelPredictionHelper.checkSpeedY(performance),
        mean: performance.mean_absolute_err.vy,
        max: performance.max_absolute_err.vy,
        precision: 1,
      },
      {
        label: 'common.spin-x',
        units: 'rpm',
        checkResult: ModelPredictionHelper.checkSpinDimension(
          performance.mean_absolute_err.wx,
          performance.max_absolute_err.wx
        ),
        mean: performance.mean_absolute_err.wx,
        max: performance.max_absolute_err.wx,
        precision: 0,
      },
      {
        label: 'common.spin-y',
        units: 'rpm',
        checkResult: ModelPredictionHelper.checkSpinDimension(
          performance.mean_absolute_err.wy,
          performance.max_absolute_err.wy
        ),
        mean: performance.mean_absolute_err.wy,
        max: performance.max_absolute_err.wy,
        precision: 0,
      },
      {
        label: 'common.spin-z',
        units: 'rpm',
        checkResult: ModelPredictionHelper.checkSpinDimension(
          performance.mean_absolute_err.wz,
          performance.max_absolute_err.wz
        ),
        mean: performance.mean_absolute_err.wz,
        max: performance.max_absolute_err.wz,
        precision: 0,
      },
      {
        label: 'common.vertical-break',
        units: 'ft',
        checkResult: ModelPredictionHelper.checkBreakDimension(
          performance.mean_absolute_err.break_z_ft,
          performance.max_absolute_err.break_z_ft
        ),
        mean: performance.mean_absolute_err.break_z_ft,
        max: performance.max_absolute_err.break_z_ft,
        precision: 1,
      },
      {
        label: 'common.horizontal-break',
        units: 'ft',
        checkResult: ModelPredictionHelper.checkBreakDimension(
          performance.mean_absolute_err.break_x_ft,
          performance.max_absolute_err.break_x_ft
        ),
        mean: performance.mean_absolute_err.break_x_ft,
        max: performance.max_absolute_err.break_x_ft,
        precision: 1,
      },
    ];

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
          <Heading size={RADIX.HEADING.SIZE.SM}>
            {t('common.new-model-performance')}: {PassFail(overallPass)}
          </Heading>

          <CommonTooltip
            trigger={
              <Table.Root>
                <Table.Header>
                  <Table.Row>
                    <Table.ColumnHeaderCell>
                      {t('common.field')}
                    </Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell width={COL_WIDTH}>
                      {t('common.success')}
                    </Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell width={COL_WIDTH} align="right">
                      {t('common.mean')}
                    </Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell width={COL_WIDTH} align="right">
                      {t('common.max')}
                    </Table.ColumnHeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {rows.map((r, i) => this.renderRow(r, i))}
                </Table.Body>
              </Table.Root>
            }
            text_md={TOOLTIP_TEXT}
          />

          {!overallPass && (
            <CommonCallout
              text_md={[
                `Model performance is not within specifications.`,
                `Please [calibrate Rapsodo](${
                  t('common.intercom-url') + HELP_URLS.RAPSODO_HOW_TO
                }).`,
                `Next, complete the Ball Type Calibration list (6 shots per pitch).`,
                `This will take up to 2 hours.`,
              ].join(' ')}
            />
          )}
        </Flex>
      </ErrorBoundary>
    );
  }
}
