import * as Tooltip from '@radix-ui/react-tooltip';
import { t } from 'i18next';
import React from 'react';
import ReactMarkdown from 'react-markdown';

interface IProps {
  /** trigger for tooltip */
  trigger: React.ReactNode;

  /** what gets rendered inside the bubble */
  content?: React.ReactNode;

  /** will be translated via i18next */
  text?: string;

  /** will be rendered via react markdown, not used when rendered as title */
  text_md?: string;

  /** disabled just renders the children without any tooltip wrapper */
  disabled?: boolean;
}

interface IState {
  open: boolean;
}

export class CommonTooltip extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      open: false,
    };
  }

  render() {
    if (this.props.disabled) {
      return this.props.trigger;
    }

    const content = (
      <>
        {this.props.text && t(this.props.text)}
        {this.props.text_md && (
          <ReactMarkdown>{t(this.props.text_md)}</ReactMarkdown>
        )}
        {this.props.content}
      </>
    );

    return (
      <Tooltip.Root
        open={this.state.open}
        onOpenChange={(open) => this.setState({ open: open })}
      >
        <Tooltip.Trigger onClick={() => this.setState({ open: true })} asChild>
          {this.props.trigger}
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content className="rt-TooltipContent">
            {content}
            <Tooltip.Arrow className="rt-TooltipArrow" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    );
  }
}
