import { GlobeIcon } from '@radix-ui/react-icons';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { ErrorBoundary } from 'components/common/error-boundary';
import { SidebarNavigationTrigger } from 'components/main/sidebar/nav-trigger';
import env from 'config';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { MainService } from 'services/main.service';

const INTERVAL_SECONDS = env.identifier === 'local' ? 5 : 60;

// smaller value => more aggressive warnings
const MAX_MS_BEFORE_WARN = 500;

export const ConnectionWarning = () => {
  const [seconds, setSeconds] = useState(0);

  const [show, setShow] = useState(false);

  // once dismissed, the banner won't re-appear for the rest of the session
  // note: not currently used
  const [dismissed, setDismissed] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (dismissed) {
        clearInterval(interval);
        return;
      }

      setSeconds((seconds) => seconds + INTERVAL_SECONDS);
    }, INTERVAL_SECONDS * 1_000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // even if banner is showing, we will still check again in case the connection improves and we can hide
    const start = Date.now();

    MainService.getInstance()
      .checkServer()
      .finally(() => {
        const end = Date.now();

        console.debug(`connectivity ping: ${end - start}ms`);

        const ms = end - start;
        setShow(ms >= MAX_MS_BEFORE_WARN);
      });
  }, [seconds]);

  const [dialog, setDialog] = useState<number>();

  if (!show) {
    return <></>;
  }

  return (
    <ErrorBoundary componentName="ConnectionWarning">
      <SidebarNavigationTrigger
        label="common.slow-connection-detected"
        icon={<GlobeIcon />}
        color={RADIX.COLOR.WARNING}
        onClick={() => setDialog(Date.now())}
        small
      />

      {dialog && (
        <CommonConfirmationDialog
          key={dialog}
          identifier="ConnectionWarningDescription"
          title="common.slow-connection-detected"
          content={<ReactMarkdown children={t('common.slow-connection-msg')} />}
          cancel={{
            label: 'common.dismiss',
            color: RADIX.COLOR.ACCENT,
          }}
          action={{
            invisible: true,
          }}
        />
      )}
    </ErrorBoundary>
  );
};
