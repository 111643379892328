import {
  Badge,
  Box,
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  Skeleton,
  Text,
} from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonSliderInput } from 'components/common/form/slider';
import { CommonTextInput } from 'components/common/form/text';
import { CommonVideoPreview } from 'components/common/video/preview';
import { MachineCalibrateButton } from 'components/machine/buttons/calibrate';
import { MachineFireButton } from 'components/machine/buttons/fire';
import { MachineInspectButton } from 'components/machine/buttons/inspect';
import { AimingContext, IAimingContext } from 'contexts/aiming.context';
import { CookiesContext } from 'contexts/cookies.context';
import { IMachineContext, MachineContext } from 'contexts/machine.context';
import { IVideosContext, VideosContext } from 'contexts/videos/videos.context';
import { addSeconds, isPast, lightFormat } from 'date-fns';
import { t } from 'i18next';
import { IBaseDialog } from 'interfaces/i-dialogs';
import { getMergedMSDict } from 'lib_ts/classes/ms.helper';
import { WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { MS_LIMITS } from 'lib_ts/enums/machine.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import { IReadyData, IReadyMsg } from 'lib_ts/interfaces/i-machine-msg';
import { DEFAULT_MACHINE_STATE } from 'lib_ts/interfaces/i-machine-state';
import { IMachineStateMsg } from 'lib_ts/interfaces/machine-msg/i-machine-state';
import { SpecialMsPosition } from 'lib_ts/interfaces/machine-msg/i-special-mstarget';
import React, { useContext } from 'react';
import { WebSocketService } from 'services/web-socket.service';

const COMPONENT_NAME = 'MSDebugDialog';

/** how old mscurrent needs to be before updating with newer mscurrent */
const UPDATE_MSCURRENT_SEC = 2;

/** how old msready needs to be before updating with newer msready */
const UPDATE_MSREADY_SEC = 1;

interface IBaseProps extends IBaseDialog {
  onClose: () => void;
}

interface IProps extends IBaseProps {
  aimingCx: IAimingContext;
  machineCx: IMachineContext;
  videosCx: IVideosContext;
}

interface IState {
  machine: IMachine;

  msready?: IReadyData;
  msready_updated?: Date;

  mstarget: IMachineStateMsg;
  mstarget_sent?: Date;

  mscurrent?: Partial<IMachineStateMsg>;
  mscurrent_updated?: Date;

  dialog: boolean;
}

const getDefaultMS = (py: number): IMachineStateMsg => {
  const output: IMachineStateMsg = {
    ...DEFAULT_MACHINE_STATE,
    video_uuid: '',
    training: false,
    w1: 2100,
    w2: 1800,
    w3: 1800,
    a1: 0,
    a2: 0,
    a3: 0,
    tilt: 2.5,
    yaw: 0,
    px: 0,
    py: py,
    pz: 4.5,
  };

  return output;
};

const CARD_SIZE = '3';
const SUBHEADER_SIZE = '2';

const DECIMALS = {
  ALPHAS: 1,
  POSITION: 1,
  TILT: 1,
  WHEELS: 0,
  YAW: 1,
};

const STEPS = {
  ALPHAS: Math.pow(10, -DECIMALS.ALPHAS),
  POSITION: Math.pow(10, -DECIMALS.POSITION),
  TILT: Math.pow(10, -DECIMALS.TILT),
  WHEELS: Math.pow(10, -DECIMALS.WHEELS),
  YAW: Math.pow(10, -DECIMALS.YAW),
};

export const MSDebugDialogHoC = (props: IBaseProps) => {
  const aimingCx = useContext(AimingContext);
  const machineCx = useContext(MachineContext);
  const videosCx = useContext(VideosContext);

  return (
    <MSDebugDialog
      {...props}
      aimingCx={aimingCx}
      machineCx={machineCx}
      videosCx={videosCx}
    />
  );
};

class MSDebugDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      machine: { ...props.machineCx.machine },
      mstarget: getDefaultMS(props.machineCx.machine.plate_distance),

      dialog: false,
    };

    this.setMSTarget = this.setMSTarget.bind(this);
    this.handleMSCurrent = this.handleMSCurrent.bind(this);
    this.handleReadyToFire = this.handleReadyToFire.bind(this);
  }

  private async setMSTarget(value: Partial<IMachineStateMsg>) {
    const newTarget: IMachineStateMsg = {
      ...this.state.mstarget,
      ...value,
    };

    /** reset video if illegal for given px value */
    if (value.px !== undefined) {
      const video = this.props.videosCx
        .getVideosByReleaseSide(value.px)
        .find((v) => v.value === this.state.mstarget.video_uuid);

      if (!video) {
        newTarget.video_uuid = '';
      }
    }

    await this.props.aimingCx.setPitch({
      ...this.props.machineCx.getDefaultPitch(),
      msDict: getMergedMSDict(this.props.machineCx.machine, [newTarget]),
      video_id: newTarget.video_uuid,
    });

    this.setState({
      mstarget: newTarget,
      mstarget_sent: undefined,
    });
  }

  private handleMSCurrent(event: CustomEvent) {
    const data: IMachineStateMsg = event.detail;
    if (
      !this.state.mscurrent_updated ||
      isPast(addSeconds(this.state.mscurrent_updated, UPDATE_MSCURRENT_SEC))
    ) {
      this.setState({
        mscurrent: data,
        mscurrent_updated: new Date(),
      });
    }
  }

  private handleReadyToFire(event: CustomEvent) {
    const data: IReadyMsg = event.detail;
    if (
      !this.state.msready_updated ||
      isPast(addSeconds(this.state.msready_updated, UPDATE_MSREADY_SEC))
    ) {
      this.setState({
        msready: data.data,
        msready_updated: new Date(),
      });
    }
  }

  componentDidMount() {
    WebSocketHelper.on(WsMsgType.M2U_ReadyToFire, this.handleReadyToFire);
    WebSocketHelper.on(WsMsgType.M2U_MsCurrent, this.handleMSCurrent);
  }

  componentWillUnmount() {
    WebSocketHelper.remove(WsMsgType.M2U_ReadyToFire, this.handleReadyToFire);
    WebSocketHelper.remove(WsMsgType.M2U_MsCurrent, this.handleMSCurrent);
  }

  private renderContent() {
    const { mstarget, mscurrent } = this.state;

    return (
      <Grid columns="2" gap={RADIX.FLEX.GAP.FORM}>
        <Box gridColumn="span 2">
          <Text size={RADIX.TEXT.SIZE.XS}>
            Last Updated:{' '}
            {this.state.mscurrent_updated
              ? lightFormat(this.state.mscurrent_updated, 'h:mm:ss a')
              : '???'}
          </Text>
        </Box>

        <Card size={CARD_SIZE}>
          <Flex direction="column" gap={RADIX.FLEX.GAP.FORM}>
            <Heading size={SUBHEADER_SIZE}>Wheels</Heading>

            <CommonSliderInput
              id="w1"
              value={mstarget.w1}
              min={MS_LIMITS.WHEEL_SPEED.MIN}
              max={MS_LIMITS.WHEEL_SPEED.MAX}
              step={STEPS.WHEELS}
              onChange={(v) => this.setMSTarget({ w1: v })}
              hint_md={`Current: ${
                mscurrent?.w1?.toFixed(DECIMALS.WHEELS) ?? '???'
              }`}
              stepperLabel="w1"
              stepperDecimals={DECIMALS.WHEELS}
              showStepper
            />
            <CommonSliderInput
              id="w2"
              value={mstarget.w2}
              min={MS_LIMITS.WHEEL_SPEED.MIN}
              max={MS_LIMITS.WHEEL_SPEED.MAX}
              step={STEPS.WHEELS}
              onChange={(v) => this.setMSTarget({ w2: v })}
              hint_md={`Current: ${
                mscurrent?.w2?.toFixed(DECIMALS.WHEELS) ?? '???'
              }`}
              stepperLabel="w2"
              stepperDecimals={DECIMALS.WHEELS}
              showStepper
            />
            <CommonSliderInput
              id="w3"
              value={mstarget.w3}
              min={MS_LIMITS.WHEEL_SPEED.MIN}
              max={MS_LIMITS.WHEEL_SPEED.MAX}
              step={STEPS.WHEELS}
              onChange={(v) => this.setMSTarget({ w3: v })}
              hint_md={`Current: ${
                mscurrent?.w3?.toFixed(DECIMALS.WHEELS) ?? '???'
              }`}
              stepperLabel="w3"
              stepperDecimals={DECIMALS.WHEELS}
              showStepper
            />
          </Flex>
        </Card>

        <Card size={CARD_SIZE}>
          <Flex direction="column" gap={RADIX.FLEX.GAP.FORM}>
            <Heading size={SUBHEADER_SIZE}>Alphas</Heading>

            <CommonSliderInput
              id="a1"
              value={mstarget.a1}
              min={MS_LIMITS.ALPHAS.MIN}
              max={MS_LIMITS.ALPHAS.MAX}
              step={STEPS.ALPHAS}
              onChange={(v) => this.setMSTarget({ a1: v })}
              hint_md={`Current: ${
                mscurrent?.a1?.toFixed(DECIMALS.ALPHAS) ?? '???'
              }`}
              stepperLabel="a1"
              stepperDecimals={DECIMALS.ALPHAS}
              showStepper
            />
            <CommonSliderInput
              id="a2"
              value={mstarget.a2}
              min={MS_LIMITS.ALPHAS.MIN}
              max={MS_LIMITS.ALPHAS.MAX}
              step={STEPS.ALPHAS}
              onChange={(v) => this.setMSTarget({ a2: v })}
              hint_md={`Current: ${
                mscurrent?.a2?.toFixed(DECIMALS.ALPHAS) ?? '???'
              }`}
              stepperLabel="a2"
              stepperDecimals={DECIMALS.ALPHAS}
              showStepper
            />
            <CommonSliderInput
              id="a3"
              value={mstarget.a3}
              min={MS_LIMITS.ALPHAS.MIN}
              max={MS_LIMITS.ALPHAS.MAX}
              step={STEPS.ALPHAS}
              onChange={(v) => this.setMSTarget({ a3: v })}
              hint_md={`Current: ${
                mscurrent?.a3?.toFixed(DECIMALS.ALPHAS) ?? '???'
              }`}
              stepperLabel="a3"
              stepperDecimals={DECIMALS.ALPHAS}
              showStepper
            />
          </Flex>
        </Card>

        <Card size={CARD_SIZE}>
          <Flex direction="column" gap={RADIX.FLEX.GAP.FORM}>
            <Heading size={SUBHEADER_SIZE}>Tilt & Yaw</Heading>

            <CommonSliderInput
              id="tilt"
              value={mstarget.tilt}
              min={MS_LIMITS.TILT.MIN}
              max={MS_LIMITS.TILT.MAX}
              step={STEPS.TILT}
              onChange={(v) => this.setMSTarget({ tilt: v })}
              hint_md={`Current: ${
                mscurrent?.tilt?.toFixed(DECIMALS.TILT) ?? '???'
              }`}
              stepperLabel="tilt"
              stepperDecimals={DECIMALS.TILT}
              showStepper
            />

            <CommonSliderInput
              id="yaw"
              value={mstarget.yaw}
              min={MS_LIMITS.YAW.MIN}
              max={MS_LIMITS.YAW.MAX}
              step={STEPS.YAW}
              onChange={(v) => this.setMSTarget({ yaw: v })}
              hint_md={`Current: ${
                mscurrent?.yaw?.toFixed(DECIMALS.YAW) ?? '???'
              }`}
              stepperLabel="yaw"
              stepperDecimals={DECIMALS.YAW}
              showStepper
            />
          </Flex>
        </Card>

        <Card size={CARD_SIZE}>
          <Flex direction="column" gap={RADIX.FLEX.GAP.FORM}>
            <Heading size={SUBHEADER_SIZE}>Position</Heading>

            <CommonSliderInput
              id="px"
              value={mstarget.px}
              min={MS_LIMITS.POSITION.X.MIN}
              max={MS_LIMITS.POSITION.X.MAX}
              step={STEPS.POSITION}
              onChange={(v) => this.setMSTarget({ px: v })}
              hint_md={`Current: ${
                mscurrent?.px?.toFixed(DECIMALS.POSITION) ?? '???'
              }`}
              stepperLabel="px"
              stepperDecimals={DECIMALS.POSITION}
              showStepper
            />
            <CommonSliderInput
              id="py"
              value={mstarget.py}
              min={0}
              max={80}
              step={STEPS.POSITION}
              onChange={(v) => this.setMSTarget({ py: v })}
              hint_md={`Current: ${
                mscurrent?.py?.toFixed(DECIMALS.POSITION) ?? '???'
              }`}
              stepperLabel="py"
              stepperDecimals={DECIMALS.POSITION}
              showStepper
              disabled
            />
            <CommonSliderInput
              id="pz"
              value={mstarget.pz}
              min={MS_LIMITS.POSITION.Z.MIN}
              max={MS_LIMITS.POSITION.Z.MAX}
              step={STEPS.POSITION}
              onChange={(v) => this.setMSTarget({ pz: v })}
              hint_md={`Current: ${
                mscurrent?.pz?.toFixed(DECIMALS.POSITION) ?? '???'
              }`}
              stepperLabel="pz"
              stepperDecimals={DECIMALS.POSITION}
              showStepper
            />
          </Flex>
        </Card>

        <Box gridColumn="span 2">
          <Card size={CARD_SIZE}>
            <Flex direction="column" gap={RADIX.FLEX.GAP.FORM}>
              <Heading size={SUBHEADER_SIZE}>Orientation</Heading>

              <Grid columns="4" gap={RADIX.FLEX.GAP.FORM}>
                <CommonTextInput
                  id="qw"
                  label="qw"
                  value={mstarget.qw.toString()}
                  onNumericChange={(v) => this.setMSTarget({ qw: v })}
                  hint_md={`Current: ${mscurrent?.qw ?? '???'}`}
                />

                <CommonTextInput
                  id="qx"
                  label="qx"
                  value={mstarget.qx.toString()}
                  onNumericChange={(v) => this.setMSTarget({ qx: v })}
                  hint_md={`Current: ${mscurrent?.qx ?? '???'}`}
                />

                <CommonTextInput
                  id="qy"
                  label="qy"
                  value={mstarget.qy.toString()}
                  onNumericChange={(v) => this.setMSTarget({ qy: v })}
                  hint_md={`Current: ${mscurrent?.qy ?? '???'}`}
                />

                <CommonTextInput
                  id="qz"
                  label="qz"
                  value={mstarget.qz.toString()}
                  onNumericChange={(v) => this.setMSTarget({ qz: v })}
                  hint_md={`Current: ${mscurrent?.qz ?? '???'}`}
                />
              </Grid>
            </Flex>
          </Card>
        </Box>

        <Box gridColumn="span 2">
          <Card size={CARD_SIZE}>
            <Flex direction="column" gap={RADIX.FLEX.GAP.FORM}>
              <Heading size={SUBHEADER_SIZE}>Other Controls</Heading>

              <CommonFormGrid columns={4}>
                <Box>
                  <CommonSelectInput
                    id="msdebug-training"
                    name="training"
                    label="Training"
                    options={['true', 'false'].map((b) => {
                      return {
                        label: b,
                        value: b,
                      };
                    })}
                    value={mstarget.training.toString()}
                    onBooleanChange={(v) => this.setMSTarget({ training: v })}
                    hint_md={`Current: ${mscurrent?.training ?? '???'}`}
                  />
                </Box>

                <Box>
                  <CommonVideoPreview
                    previewPx={mstarget.px}
                    previewPz={mstarget.pz}
                    selectConfig={{
                      px: mstarget.px ?? 0,
                      video_id: mstarget.video_uuid,
                      onChange: (id) => this.setMSTarget({ video_uuid: id }),
                    }}
                    headerAsInput
                    hidePreviewButton
                    hidePlayback
                  />
                </Box>

                <Box gridColumn="span 2">&nbsp;</Box>

                <Box>
                  <Button
                    className="btn-block"
                    color={RADIX.COLOR.WARNING}
                    onClick={() =>
                      this.setState({
                        mstarget: getDefaultMS(
                          this.props.machineCx.machine.plate_distance
                        ),
                      })
                    }
                  >
                    Reset Target
                  </Button>
                </Box>

                <Box>
                  <Button
                    className="btn-block"
                    color={RADIX.COLOR.SEND_PITCH}
                    onClick={() => {
                      this.props.machineCx.sendRawTarget(
                        this.state.mstarget,
                        'ms debug',
                        false
                      );
                      this.setState({ mstarget_sent: new Date() });
                    }}
                  >
                    {t('common.load-pitch')}
                  </Button>
                </Box>

                {!this.props.machineCx.lastStatus.calibrated && (
                  <Box>
                    <MachineCalibrateButton className="btn-block" />
                  </Box>
                )}

                {this.props.machineCx.lastStatus.calibrated && (
                  <Box>
                    <CookiesContext.Consumer>
                      {(cookiesCx) => (
                        <MachineFireButton
                          cookiesCx={cookiesCx}
                          machineCx={this.props.machineCx}
                          aimingCx={this.props.aimingCx}
                          className="btn-block"
                          tags="MS_DEBUG"
                          firing
                        />
                      )}
                    </CookiesContext.Consumer>
                  </Box>
                )}

                <Box>
                  <Button
                    className="btn-block"
                    color={RADIX.COLOR.DANGER}
                    onClick={() =>
                      WebSocketService.send(
                        WsMsgType.U2S_Fire,
                        {},
                        COMPONENT_NAME
                      )
                    }
                  >
                    Raw Fire
                  </Button>
                </Box>

                <MachineInspectButton className="btn-block" />

                <CommonSelectInput
                  id="msdebug-special-ms"
                  placeholder="Special MS Target"
                  options={Object.values(SpecialMsPosition).map((o) => ({
                    label: o,
                    value: o,
                  }))}
                  name={'special-mstarget'}
                  onChange={(v) => {
                    const position = v as SpecialMsPosition;
                    if (position) {
                      NotifyHelper.success({
                        message_md: `Sending special mstarget \`${position}\` to machine!`,
                      });

                      this.props.machineCx.specialMstarget(position);
                    }
                  }}
                  optional
                />
              </CommonFormGrid>
            </Flex>
          </Card>
        </Box>

        <Box gridColumn="span 2">
          <Card>
            <Heading size={SUBHEADER_SIZE}>Raw JSON of Current MS</Heading>
            <Skeleton loading={!mscurrent}>
              <pre>{JSON.stringify(mscurrent, null, 2)}</pre>
            </Skeleton>
          </Card>
        </Box>
      </Grid>
    );
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog
          identifier={this.props.identifier}
          width={RADIX.DIALOG.WIDTH.XL}
          title={
            <Flex gap={RADIX.FLEX.GAP.SM}>
              <Heading size={RADIX.HEADING.SIZE.MD}>Machine Debugger</Heading>
              <Badge color={RADIX.COLOR.WARNING}>
                {this.props.machineCx.machine.machineID}
              </Badge>
            </Flex>
          }
          loading={this.props.machineCx.loading}
          content={this.renderContent()}
          onClose={() => this.props.onClose()}
        />
      </ErrorBoundary>
    );
  }
}
