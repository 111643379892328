import { Box } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { getPitchYearOptions } from 'classes/helpers/pitch-list.helper';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import { ManageListDialog } from 'components/common/pitch-lists/manage-list';
import {
  PitchListState,
  SEARCH_ID,
} from 'components/sections/pitch-list/store/pitch-list-store';
import env from 'config';
import { AuthContext, IAuthContext } from 'contexts/auth.context';
import {
  IPitchListsContext,
  PitchListsContext,
} from 'contexts/pitch-lists/lists.context';
import { ISectionsContext, SectionsContext } from 'contexts/sections.context';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { t } from 'i18next';
import { ISessionCookie } from 'interfaces/cookies/i-session.cookie';
import { DEFAULT_ACCEPT_BTN, IBaseDialog } from 'interfaces/i-dialogs';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { PitchListOwner } from 'lib_ts/enums/pitch-list.enums';
import {
  PITCH_TYPE_OPTIONS,
  PitchListExtType,
  PitchType,
} from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IPitch, IPitchList, IPitchMetadata } from 'lib_ts/interfaces/pitches';
import React, { ReactNode, useContext } from 'react';
import { PitchListsService } from 'services/pitch-lists.service';
import { SessionEventsService } from 'services/session-events.service';

const COMPONENT_NAME = 'CopyPitchesDialog';

const CREATE_NEW_LIST_ID = 'create-new-list';

interface IListsDict {
  [key: string]: IPitchList[];
}

const getListOptions = (
  lists: IPitchList[],
  auth: ISessionCookie
): IOption[] => {
  const parentDict: IListsDict = {};

  const basicLists = lists.filter((l) => !l.type);

  parentDict['constants.pitch-list-personal'] = basicLists.filter(
    (l) => l._parent_def === PitchListOwner.User
  );

  if (auth?.machine_lists) {
    parentDict[
      t('constants.pitch-list-x-lists', {
        x: auth.machineID,
      }).toString()
    ] = basicLists.filter((l) => l._parent_def === PitchListOwner.Machine);
  }

  if (auth?.team_lists) {
    parentDict['constants.pitch-list-team'] = basicLists.filter(
      (l) => l._parent_def === PitchListOwner.Team
    );
  }

  const extendedLists = lists.filter((l) => !!l.type);

  if (env.enable.sample_lists && auth.role === 'admin') {
    parentDict['constants.pitch-list-samples'] = extendedLists.filter(
      (l) => l.type === PitchListExtType.Sample
    );
  }

  if (auth.role === 'admin') {
    parentDict['constants.pitch-list-references'] = extendedLists.filter(
      (l) => l.type === PitchListExtType.Reference
    );
    parentDict['constants.pitch-list-cards'] = extendedLists.filter(
      (l) => l.type === PitchListExtType.Card
    );
  }

  const options: IOption[] = [
    {
      label: t('common.add-a-new-list'),
      value: CREATE_NEW_LIST_ID,
    },
  ];

  Object.keys(parentDict).forEach((parent) => {
    const lists = parentDict[parent];
    if (lists.length === 0) {
      return;
    }

    options.push(
      ...lists.map((l) => {
        const o: IOption = {
          label: l.name,
          value: l._id,
          group: `${t(parent)}${l.folder ? `: ${l.folder}` : ''}`,
        };

        return o;
      })
    );
  });

  return options;
};

interface IBaseProps extends IBaseDialog {
  title: ReactNode;
  description?: string;

  pitches: Partial<IPitch>[];

  /** ids of lists that should not be shown in the dropdown */
  excludedListIDs?: string[];

  // should also close the dialog when ready
  onCreated: () => void;

  reloadPitches?: PitchListState['reloadPitches'];
}

interface IProps extends IBaseProps {
  authCx: IAuthContext;
  listsCx: IPitchListsContext;
  sectionsCx: ISectionsContext;
}

interface IState extends Partial<IPitchMetadata> {
  loading: boolean;
  listID?: string;
  listKey: number;
  dialogCreateList?: number;

  yearOptions: IOption[];
}

export const CopyPitchesDialogHoC = (props: IBaseProps) => {
  const authCx = useContext(AuthContext);
  const listsCx = useContext(PitchListsContext);
  const sectionsCx = useContext(SectionsContext);

  return (
    <CopyPitchesDialog
      authCx={authCx}
      listsCx={listsCx}
      sectionsCx={sectionsCx}
      {...props}
    />
  );
};

/** also used for saving new pitches */
class CopyPitchesDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const firstPitch = props.pitches[0];

    this.state = {
      loading: false,

      /** set name and type based on first pitch, but if only exactly one pitch was provided */
      name: props.pitches.length === 1 ? firstPitch?.name : undefined,
      type: props.pitches.length === 1 ? firstPitch?.type : undefined,
      year: props.pitches.length === 1 ? firstPitch?.year : undefined,

      // set list based on first pitch
      listID: firstPitch?._parent_id,
      listKey: Date.now(),

      yearOptions: getPitchYearOptions(),
    };

    this.renderForm = this.renderForm.bind(this);
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog
          identifier={this.props.identifier}
          width={RADIX.DIALOG.WIDTH.MD}
          title={this.props.title}
          loading={this.state.loading}
          description={this.props.description}
          content={this.renderForm()}
          buttons={[
            {
              ...DEFAULT_ACCEPT_BTN,
              onClick: async () => {
                try {
                  /** validate inputs */
                  if (!this.state.listID) {
                    NotifyHelper.error({
                      message_md: 'Please select a pitch list and try again.',
                    });
                    return;
                  }

                  const payloads = (() => {
                    const single = this.props.pitches.length === 1;
                    if (
                      single &&
                      (!this.state.name || this.state.name.trim().length === 0)
                    ) {
                      NotifyHelper.error({
                        message_md: 'Please check your inputs and try again.',
                      });
                      return [];
                    }

                    return this.props.pitches.map((p) => {
                      const payload: Partial<IPitch> = {
                        ...p,
                        _parent_id: this.state.listID,
                      };

                      // only update metadata from form if saving a single pitch
                      if (single) {
                        payload.name = this.state.name?.trim();
                        payload.type = this.state.type;
                        payload.year = this.state.year;
                      }

                      // always set plate_loc_backup based on given traj
                      if (p.traj) {
                        payload.plate_loc_backup = TrajHelper.getPlateLoc(
                          p.traj
                        );
                      }

                      return payload;
                    });
                  })();

                  if (payloads.length === 0) {
                    return;
                  }

                  /** should disable accept/cancel buttons until the following is complete */
                  this.setState({ loading: true });

                  const postResult = await PitchListsService.getInstance()
                    .postPitchesToList({
                      listID: this.state.listID,
                      data: payloads,
                    })
                    .finally(() => this.setState({ loading: false }));

                  if (!postResult.success) {
                    NotifyHelper.warning({
                      message_md:
                        postResult.error ??
                        `${
                          this.props.pitches.length > 1
                            ? 'Pitches'
                            : this.state.name
                        } could not be created.`,
                    });
                    return;
                  }

                  SessionEventsService.postEvent({
                    category: 'pitch',
                    tags: 'create',
                    data: {
                      count: this.props.pitches.length,
                    },
                  });

                  /** update the active pitches to show newly added entries */
                  if (
                    this.props.listsCx.active &&
                    this.state.listID === this.props.listsCx.active._id &&
                    this.props.reloadPitches
                  ) {
                    this.props.reloadPitches();
                  }

                  NotifyHelper.success({
                    message_md: t('pu.pitches-created-msg'),
                    buttons: [
                      {
                        label: 'common.go-to-pitch-list',
                        dismissAfterClick: true,
                        onClick: () =>
                          this.props.sectionsCx.tryChangeSection({
                            trigger: `${COMPONENT_NAME} > added pitches > toast`,
                            section: SectionName.Pitches,
                            subsection: SubSectionName.List,
                            fragments: this.state.listID
                              ? [this.state.listID]
                              : undefined,
                            ignoreDirty: true,
                          }),
                      },
                    ],
                  });

                  this.props.onCreated?.();
                  this.props.onClose();
                } catch (e) {
                  console.error(e);
                  NotifyHelper.error({
                    message_md: `${
                      this.props.pitches.length > 1
                        ? 'Pitches'
                        : this.state.name
                    } could not be created.`,
                  });
                }
              },
            },
          ]}
          onClose={this.props.onClose}
        />

        {this.state.dialogCreateList && (
          <ManageListDialog
            key={this.state.dialogCreateList}
            identifier="CopyPitchesCreateListDialog"
            mode="create"
            onCreated={(created) => {
              setTimeout(() => {
                this.setState({
                  // hide the list creation dialog
                  dialogCreateList: undefined,
                  // find and select the new list
                  listID: created._id,
                  // reset search input
                  listKey: Date.now(),
                });
              }, 500);
            }}
            onClose={() =>
              this.setState({
                dialogCreateList: undefined,
              })
            }
          />
        )}
      </ErrorBoundary>
    );
  }

  private renderForm() {
    const visible = this.props.listsCx.lists.filter(
      (l) =>
        !this.props.excludedListIDs ||
        !this.props.excludedListIDs.includes(l._id)
    );

    return (
      <CommonFormGrid columns={3}>
        {this.props.pitches.length === 1 && (
          <>
            <CommonTextInput
              id="copy-pitches-name"
              label="common.pitch-name"
              name="pitch_name"
              value={this.state.name}
              disabled={this.props.listsCx.loading}
              placeholder="Type in new name"
              onChange={(v) => this.setState({ name: v })}
            />
            <CommonSearchInput
              id="copy-pitches-type"
              name="type"
              label="common.pitch-type"
              options={PITCH_TYPE_OPTIONS}
              values={this.state.type ? [this.state.type] : []}
              onChange={(v) =>
                this.setState({
                  type: v[0] as PitchType,
                })
              }
              disabled={this.props.listsCx.loading}
              labelOptional
              optional
            />
            <CommonSelectInput
              id="copy-pitches-year"
              name="year"
              label="common.year"
              options={this.state.yearOptions}
              value={this.state.year}
              onChange={(v) => this.setState({ year: v })}
              disabled={this.props.listsCx.loading}
              labelOptional
              optional
            />
          </>
        )}

        <Box gridColumn="span 3">
          <CommonSearchInput
            key={this.state.listKey}
            id="copy-pitches-listID"
            name="listID"
            label="common.pitch-list"
            options={getListOptions(visible, this.props.authCx.current)}
            values={
              this.state.listID !== SEARCH_ID && this.state.listID
                ? [this.state.listID]
                : []
            }
            onChange={(v) => {
              const id = v[0];

              if (id === CREATE_NEW_LIST_ID) {
                this.setState({ dialogCreateList: Date.now() });
                return;
              }

              this.setState({
                listID: id,
              });
            }}
            disabled={this.props.listsCx.loading}
            optional
          />
        </Box>
      </CommonFormGrid>
    );
  }
}
