import { MinusIcon, PlusIcon } from '@radix-ui/react-icons';
import { Box, Button, Flex, IconButton, Slider } from '@radix-ui/themes';
import { CommonInputHint } from 'components/common/form/hint';
import { CommonInputLabel } from 'components/common/form/label';
import { CommonInputWrapper } from 'components/common/form/wrapper';
import { t } from 'i18next';
import { ISliderInput } from 'interfaces/forms/slider';
import { RADIX } from 'lib_ts/enums/radix-ui';

export const CommonSliderInput = (props: ISliderInput) => (
  <CommonInputWrapper {...props}>
    <CommonInputLabel {...props} />

    {props.showStepper && (
      <Flex gap={RADIX.FLEX.GAP.SM} justify="center">
        <Box>
          <IconButton
            color={RADIX.COLOR.NEUTRAL}
            variant="soft"
            disabled={props.disabled || props.value <= props.min}
            onClick={() => {
              const next = props.value - props.step;
              props.onChange?.(next);
            }}
          >
            <MinusIcon />
          </IconButton>
        </Box>

        {props.stepperLabel && (
          <Box flexGrow="1">
            <Button
              color={RADIX.COLOR.NEUTRAL}
              variant="soft"
              disabled={props.disabled}
              className="btn-block"
            >
              {t(props.stepperLabel)}:{' '}
              {props.value.toFixed(props.stepperDecimals ?? 0)}
            </Button>
          </Box>
        )}

        <Box>
          <IconButton
            color={RADIX.COLOR.NEUTRAL}
            variant="soft"
            disabled={props.disabled || props.value >= props.max}
            onClick={() => {
              const next = props.value + props.step;
              props.onChange?.(next);
            }}
          >
            <PlusIcon />
          </IconButton>
        </Box>
      </Flex>
    )}

    <Slider
      color={props.inputColor}
      min={props.min}
      max={props.max}
      step={props.step}
      value={[props.value]}
      disabled={props.disabled}
      onValueChange={(v) => {
        props.onChange?.(v[0]);
      }}
    />

    <CommonInputHint {...props} />
  </CommonInputWrapper>
);
