import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { Box, Flex, Text } from '@radix-ui/themes';
import { StringHelper } from 'classes/helpers/string.helper';
import { CommonMenu } from 'components/common/menu';
import { CookiesContext } from 'contexts/cookies.context';
import { SidebarState } from 'enums/cookies.enums';
import { t } from 'i18next';
import { IMenuAction } from 'interfaces/i-menus';
import { RadixColor } from 'lib_ts/enums/radix-ui';
import { ReactNode, useContext, useMemo } from 'react';

interface ISidebarNavigation {
  icon: JSX.Element;
  color?: RadixColor;

  label: string;
  title?: string;

  disabled?: boolean;
  active?: boolean;

  onClick?: () => void;

  actions?: IMenuAction[];

  suffix?: ReactNode;

  small?: boolean;
}

export const SidebarNavigationTrigger = (props: ISidebarNavigation) => {
  const { app } = useContext(CookiesContext);

  const isFull = app.sidebar_state === SidebarState.full;

  const className = useMemo(
    () =>
      StringHelper.classNames([
        'NavigationMenuTrigger',
        props.active ? 'active' : 'inactive',
      ]),
    [props.active]
  );

  const vPadding = props.small ? '1' : '2';
  const textSize = props.small ? '1' : '2';

  return (
    <NavigationMenu.Item style={{ display: 'block' }}>
      <NavigationMenu.Trigger
        data-testid="SidebarSection"
        className={className}
        title={props.title ?? t(props.label).toString()}
        disabled={props.disabled}
        onClick={props.onClick}
      >
        <CommonMenu
          // if (visible) actions is empty, only the trigger will be rendered
          actions={props.actions ?? []}
          title={props.label}
          trigger={
            <Flex
              pt={vPadding}
              pb={vPadding}
              pr="2"
              pl="2"
              gap="2"
              align="center"
            >
              <Box className="NavigationMenuIcon">
                <Text size={textSize} color={props.color}>
                  {props.icon}
                </Text>
              </Box>

              {/* min width for truncation */}
              {isFull && (
                <Box minWidth="0">
                  <Text
                    color={props.color}
                    size={textSize}
                    style={{ display: 'block' }}
                    truncate
                  >
                    {t(props.label)}
                  </Text>
                </Box>
              )}

              {isFull && (
                <Text color={props.color} size={textSize}>
                  {props.suffix}
                </Text>
              )}
            </Flex>
          }
          skipSort
        />
      </NavigationMenu.Trigger>
    </NavigationMenu.Item>
  );
};
