import { Box, Button, Card, Flex, Grid, Text } from '@radix-ui/themes';
import { HELP_URLS } from 'classes/helpers/url.helper';
import { CustomIcon } from 'components/common/custom-icon';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonLink } from 'components/common/link';
import { CommonStepHeader } from 'components/machine/dialogs/installation/header';
import { MachineContext } from 'contexts/machine.context';
import { CustomIconPath } from 'enums/custom.enums';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { ReactNode, useContext } from 'react';

const COMPONENT_NAME = 'StepProjectorConfig';
const DELTA = 0.005;

interface IProps {
  started: boolean;
}

const ButtonFlexed = (props: {
  label: string;
  icon: ReactNode;
  onClick: () => void;
}) => (
  <Button className="width-120px" onClick={props.onClick}>
    <Flex align="center" justify="between" style={{ width: '100%' }}>
      <Box>{t(props.label)}</Box>
      {props.icon}
    </Flex>
  </Button>
);
export const StepProjectorConfig = (props: IProps) => {
  const { adjustKeystone } = useContext(MachineContext);

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <Flex direction="column" gap="4">
        <CommonStepHeader label="Projector Config" />

        <Box>
          <Text color={RADIX.COLOR.SECONDARY}>
            <ol className="no-margin" style={{ paddingInlineStart: '20px' }}>
              <li>Toggle the rulers ON.</li>
              <li>
                Use a measuring tape to compare the projected measurements to
                actual ones.
              </li>
              <li>
                If the projection appears too large or too small, use the
                buttons to adjust the frame until it fits on the panel.
              </li>
            </ol>
          </Text>
        </Box>

        <Box>
          <Text color={RADIX.COLOR.SECONDARY}>
            Adjust until the projection matches the real measurements
            accurately.
          </Text>
        </Box>

        <Box>
          <Text color={RADIX.COLOR.SECONDARY}>
            You can read additional information on setting up the projector{' '}
            <CommonLink url={HELP_URLS.PROJECTOR}>here</CommonLink>.
          </Text>
        </Box>

        {props.started && (
          <Card>
            <Flex justify="center">
              <Grid columns="2" gap="2">
                <Flex justify="center">
                  <Box>Horizontal</Box>
                </Flex>
                <Flex justify="center">
                  <Box>Vertical</Box>
                </Flex>

                <Box>
                  <ButtonFlexed
                    data-id="h-stretch"
                    icon={<CustomIcon icon={CustomIconPath.ColumnStretch} />}
                    label="Stretch"
                    onClick={() =>
                      adjustKeystone(COMPONENT_NAME, {
                        delta_h: DELTA,
                      })
                    }
                  />
                </Box>

                <Box>
                  <ButtonFlexed
                    data-id="v-stretch"
                    icon={<CustomIcon icon={CustomIconPath.RowStretch} />}
                    label="Stretch"
                    onClick={() =>
                      adjustKeystone(COMPONENT_NAME, {
                        delta_v: DELTA,
                      })
                    }
                  />
                </Box>

                <Box>
                  <ButtonFlexed
                    data-id="h-compress"
                    icon={<CustomIcon icon={CustomIconPath.ColumnCompress} />}
                    label="Compress"
                    onClick={() =>
                      adjustKeystone(COMPONENT_NAME, {
                        delta_h: -DELTA,
                      })
                    }
                  />
                </Box>

                <Box>
                  <ButtonFlexed
                    data-id="v-compress"
                    icon={<CustomIcon icon={CustomIconPath.RowCompress} />}
                    label="Compress"
                    onClick={() =>
                      adjustKeystone(COMPONENT_NAME, {
                        delta_v: -DELTA,
                      })
                    }
                  />
                </Box>
              </Grid>
            </Flex>
          </Card>
        )}

        {!props.started && (
          <Flex justify="center">
            <Box>
              <img
                style={{
                  width: '351px',
                }}
                src="/img/renders/projector-setup.svg"
              />
            </Box>
          </Flex>
        )}
      </Flex>
    </ErrorBoundary>
  );
};
