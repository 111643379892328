import { Box, Flex, Spinner, TabNav } from '@radix-ui/themes';
import {
  BetaIcon,
  SuperAdminIcon,
  VoidIcon,
} from 'components/common/custom-icon/shorthands';
import { CommonDialog } from 'components/common/dialogs';
import { PitchDetailsTab } from 'components/common/dialogs/pitch-data/details.tab';
import { PitchSummaryTab } from 'components/common/dialogs/pitch-data/summary.tab';
import { ErrorBoundary } from 'components/common/error-boundary';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { MSInspectorView } from 'components/common/ms-inspector-view';
import { TableContext } from 'components/common/table/context';
import { TrainingDataTable } from 'components/common/training-data-table';
import { TrajectoryView } from 'components/common/trajectory-view';
import {
  IInputModel,
  getRenderGroupDict,
} from 'components/common/trajectory-view/helpers';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { MachineContext } from 'contexts/machine.context';
import { MatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { t } from 'i18next';
import { IBaseDialog } from 'interfaces/i-dialogs';
import { getMSFromMSDict } from 'lib_ts/classes/ms.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import { IPitch } from 'lib_ts/interfaces/pitches';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import React, { ReactNode } from 'react';
import { ShotsService } from 'services/shots.service';

const COMPONENT_NAME = 'PitchDataDialog';

const MAX_TRAJ_VIEW_SHOTS = 4;

enum TabKey {
  Summary = 'common.summary',
  Details = 'common.details',
  TrainingData = 'common.training-data',
  Trajectory = 'common.trajectory',
  MsInspector = 'common.ms-inspector',
}

interface IProps extends IBaseDialog {
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;

  pitch: IPitch;
  machine: IMachine;

  // disabled for pitches from game data feature
  showTrainingData?: boolean;
}

interface IState {
  activeTab: TabKey;

  shots?: IMachineShot[];

  /** compute this and set sparingly to avoid spamming the animation component */
  trajModels?: IInputModel[];

  animateKey: number;
}

const DEFAULT_STATE: IState = {
  activeTab: TabKey.Summary,
  animateKey: Date.now(),
};

export class PitchDataDialog extends React.Component<IProps, IState> {
  private init = false;

  constructor(props: IProps) {
    super(props);

    this.state = DEFAULT_STATE;

    this.initialize = this.initialize.bind(this);
    this.renderTabBar = this.renderTabBar.bind(this);
    this.renderTabContent = this.renderTabContent.bind(this);
  }

  componentDidMount() {
    if (this.init) {
      return;
    }

    this.init = true;
    this.initialize();
  }

  private async initialize() {
    const ms = getMSFromMSDict(this.props.pitch, this.props.machine).ms;

    if (!ms?.matching_hash) {
      console.error(
        `${COMPONENT_NAME}: cannot load matches without a valid matching_hash`
      );
      return;
    }

    const hash = ms.matching_hash;

    const shots = await ShotsService.getInstance()
      .getMatches(
        {
          matching_hashes: [hash],
          machineID: this.props.machine.machineID,
          ball_type: this.props.machine.ball_type,
          includeHitterPresent: true,
          includeLowConfidence: true,
        },
        -1
      )
      .then((result) => {
        const entry = result[hash];
        if (!entry) {
          return [];
        }

        return entry.sort((a, b) => (a._created < b._created ? 1 : -1));
      });

    const trajModels = Object.values(
      getRenderGroupDict({
        shots: shots.filter((_, i) => i < MAX_TRAJ_VIEW_SHOTS),
        bs: this.props.pitch.bs,
        traj: this.props.pitch.traj,
        ms: ms,
        plate_distance: this.props.machine.plate_distance,
        includeTarget: true,
        // includeEachActual: true,
        // includeAvgRotated: true,
        includeEachRotated: true,
      })
    ).flatMap((v) => v);

    this.setState({
      shots: shots,
      trajModels: trajModels,
    });
  }

  private renderTabContent() {
    switch (this.state.activeTab) {
      case TabKey.Summary: {
        if (!this.state.shots) {
          return <Spinner />;
        }

        return (
          <PitchSummaryTab
            authCx={this.props.authCx}
            pitch={this.props.pitch}
            machine={this.props.machine}
            shots={this.state.shots}
          />
        );
      }

      case TabKey.Details: {
        if (!this.state.shots) {
          return <Spinner />;
        }

        return (
          <PitchDetailsTab
            authCx={this.props.authCx}
            pitch={this.props.pitch}
            machine={this.props.machine}
            shots={this.state.shots}
          />
        );
      }

      case TabKey.TrainingData: {
        return (
          <MachineContext.Consumer>
            {(machineCx) => (
              <MatchingShotsContext.Consumer>
                {(matchingCx) => (
                  <TableContext.Consumer>
                    {(tableCx) => (
                      <TrainingDataTable
                        cookiesCx={this.props.cookiesCx}
                        authCx={this.props.authCx}
                        machineCx={machineCx}
                        matchingCx={matchingCx}
                        pitch={this.props.pitch}
                        shots={this.state.shots}
                        afterDiscard={(success) => {
                          if (success) {
                            this.initialize();
                            tableCx.updateTableKey();
                          }
                        }}
                      />
                    )}
                  </TableContext.Consumer>
                )}
              </MatchingShotsContext.Consumer>
            )}
          </MachineContext.Consumer>
        );
      }

      case TabKey.Trajectory: {
        if (!this.state.trajModels) {
          return;
        }

        return (
          <TrajectoryView
            animateKey={this.state.animateKey}
            models={this.state.trajModels}
            startingZoom={1.75}
          />
        );
      }

      case TabKey.MsInspector: {
        return (
          <MSInspectorView
            machine={this.props.machine}
            pitch={this.props.pitch}
          />
        );
      }

      default: {
        return <Spinner />;
      }
    }
  }

  private renderTabBar(): React.ReactNode {
    const tabs: {
      label: string;
      value: TabKey;
      invisible?: boolean;
      icon?: ReactNode;
    }[] = [
      {
        label: 'common.summary',
        value: TabKey.Summary,
      },
      {
        label: 'common.details',
        value: TabKey.Details,
      },
      {
        label: 'common.training-data',
        value: TabKey.TrainingData,
        invisible: !this.props.showTrainingData,
      },
      {
        label: 'common.trajectory',
        value: TabKey.Trajectory,
        invisible: !this.props.authCx.showBeta,
        icon: <BetaIcon />,
      },
      {
        label: 'common.ms-inspector',
        value: TabKey.MsInspector,
        invisible:
          this.props.authCx.current.role !== UserRole.admin &&
          this.props.authCx.current.mode !== 'impostor',
        icon: <SuperAdminIcon />,
      },
    ];

    return (
      <TabNav.Root>
        {tabs
          .filter((m) => !m.invisible)
          .map((m, i) => (
            <TabNav.Link
              key={i}
              active={this.state.activeTab === m.value}
              onClick={() => {
                this.setState({ activeTab: m.value });
              }}
            >
              <Flex gap={RADIX.FLEX.GAP.SM} align="center">
                <Box>{t(m.label)}</Box>
                <Box mt="1">{m.icon ?? <VoidIcon />}</Box>
              </Flex>
            </TabNav.Link>
          ))}
      </TabNav.Root>
    );
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog
          identifier={this.props.identifier}
          width={RADIX.DIALOG.WIDTH.XL}
          vFlexHeight={
            [TabKey.Details, TabKey.TrainingData].includes(this.state.activeTab)
              ? RADIX.DIALOG.HEIGHT.MD
              : undefined
          }
          title={t('pl.pitch-data-for-x', {
            x: this.props.pitch.name ?? t('pl.unnamed-pitch'),
          }).toString()}
          content={
            <FlexTableWrapper
              gap={RADIX.FLEX.GAP.SECTION}
              header={this.renderTabBar()}
              table={this.renderTabContent()}
            />
          }
          onClose={this.props.onClose}
        />
      </ErrorBoundary>
    );
  }
}
