import { MinusIcon, PlusIcon } from '@radix-ui/react-icons';
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  IconButton,
  RadioCards,
} from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSliderInput } from 'components/common/form/slider';
import { PitchDesignContext } from 'contexts/pitch-lists/pitch-design.context';
import { t } from 'i18next';
import {
  Orientation,
  ORIENTATION_SEAM_ALT_AZ,
  SEAM_ORIENTATIONS,
} from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IBallChar } from 'lib_ts/interfaces/i-ball-char';
import { useCallback, useContext, useState } from 'react';

const COMPONENT_NAME = 'SeamOrientation';

const LIMIT_LATITUDE_DEG = 90;
const LIMIT_LONGITUDE_DEG = 180;

export const SeamOrientation = (props: { showRef: boolean }) => {
  const { ball, referenceKey, mergeBall } = useContext(PitchDesignContext);

  const _mergeBallHelper = useCallback(
    (ball: Partial<IBallChar>, trigger: string) =>
      mergeBall({
        trigger: trigger,
        ball: ball,
        markDirty: true,
        rebuild: true,
      }),
    [mergeBall]
  );

  const [refBall, _setRefBall] = useState<Partial<IBallChar>>({ ...ball });

  const [latitude, setLatitude] = useState(ball.latitude_deg ?? 0);
  const [longitude, setLongitude] = useState(ball.longitude_deg ?? 0);

  const [activeOrientation, setActiveOrientation] = useState(
    ball.orientation ?? Orientation.FF4
  );

  return (
    <ErrorBoundary componentName="PDSeamOrientation">
      <Flex direction="column" gap={RADIX.FLEX.GAP.FORM}>
        <Heading size={RADIX.HEADING.SIZE.SM}>
          {t('pd.seam-orientation')}
        </Heading>

        <RadioCards.Root
          key={`orientation-${referenceKey}`}
          size="1"
          gap={RADIX.FLEX.GAP.SM}
          defaultValue={activeOrientation}
          onValueChange={(value) => {
            // changes visible tab
            setActiveOrientation(value as Orientation);

            const definition = ORIENTATION_SEAM_ALT_AZ[value];

            if (!definition) {
              _mergeBallHelper(
                {
                  orientation: value as Orientation,
                },
                `${COMPONENT_NAME} orientation`
              );
              return;
            }

            setLatitude(definition.latitude_deg);
            setLongitude(definition.longitude_deg);

            _mergeBallHelper(
              {
                orientation: value as Orientation,
                latitude_deg: definition.latitude_deg,
                longitude_deg: definition.longitude_deg,
              },
              `${COMPONENT_NAME} orientation`
            );
          }}
        >
          {SEAM_ORIENTATIONS.map((o, i) => (
            <RadioCards.Item key={i} value={o.value}>
              {t(o.label)}
              {props.showRef && refBall.orientation === o.value ? '*' : ''}
            </RadioCards.Item>
          ))}
        </RadioCards.Root>

        {activeOrientation === Orientation.CUS && (
          <Grid columns="2" gap={RADIX.FLEX.GAP.SM}>
            <Flex gap={RADIX.FLEX.GAP.SM} justify="center">
              <Box>
                <IconButton
                  color={RADIX.COLOR.NEUTRAL}
                  variant="soft"
                  disabled={latitude <= -LIMIT_LATITUDE_DEG}
                  onClick={() => {
                    const next = latitude - 1;
                    setLatitude(next);
                    _mergeBallHelper(
                      { latitude_deg: next },
                      `${COMPONENT_NAME} lat-long controls`
                    );
                  }}
                >
                  <MinusIcon />
                </IconButton>
              </Box>
              <Box flexGrow="1">
                <Button
                  key={`lat-${referenceKey}`}
                  color={RADIX.COLOR.NEUTRAL}
                  variant="soft"
                  className="btn-block"
                  onClick={() => {
                    const next = props.showRef ? refBall.latitude_deg ?? 0 : 0;
                    setLatitude(next);
                    _mergeBallHelper(
                      { latitude_deg: next },
                      `${COMPONENT_NAME} lat-long controls`
                    );
                  }}
                >
                  {t('pd.latitude')}: {(ball.latitude_deg ?? 0).toFixed(0)}
                </Button>
              </Box>
              <Box>
                <IconButton
                  color={RADIX.COLOR.NEUTRAL}
                  variant="soft"
                  disabled={latitude >= LIMIT_LATITUDE_DEG}
                  onClick={() => {
                    const next = latitude + 1;
                    setLatitude(next);
                    _mergeBallHelper(
                      { latitude_deg: next },
                      `${COMPONENT_NAME} lat-long controls`
                    );
                  }}
                >
                  <PlusIcon />
                </IconButton>
              </Box>
            </Flex>

            <Flex gap={RADIX.FLEX.GAP.SM} justify="center">
              <Box>
                <IconButton
                  color={RADIX.COLOR.NEUTRAL}
                  variant="soft"
                  disabled={longitude <= -LIMIT_LONGITUDE_DEG}
                  onClick={() => {
                    const next = longitude - 1;
                    setLongitude(next);
                    _mergeBallHelper(
                      { longitude_deg: next },
                      `${COMPONENT_NAME} lat-long controls`
                    );
                  }}
                >
                  <MinusIcon />
                </IconButton>
              </Box>
              <Box flexGrow="1">
                <Button
                  key={`long-${referenceKey}`}
                  color={RADIX.COLOR.NEUTRAL}
                  variant="soft"
                  className="btn-block"
                  onClick={() => {
                    const next = props.showRef ? refBall.longitude_deg ?? 0 : 0;
                    setLongitude(next);
                    _mergeBallHelper(
                      { longitude_deg: next },
                      `${COMPONENT_NAME} lat-long controls`
                    );
                  }}
                >
                  {t('pd.longitude')}: {(ball.longitude_deg ?? 0).toFixed(0)}
                </Button>
              </Box>
              <Box>
                <IconButton
                  color={RADIX.COLOR.NEUTRAL}
                  variant="soft"
                  disabled={longitude >= LIMIT_LONGITUDE_DEG}
                  onClick={() => {
                    const next = longitude + 1;
                    setLongitude(next);
                    _mergeBallHelper(
                      { longitude_deg: next },
                      `${COMPONENT_NAME} lat-long controls`
                    );
                  }}
                >
                  <PlusIcon />
                </IconButton>
              </Box>
            </Flex>

            <Box p="2">
              <CommonSliderInput
                key={`lat-deg-${referenceKey}`}
                id="PDSeamOrientationLatitude"
                min={-LIMIT_LATITUDE_DEG}
                max={LIMIT_LATITUDE_DEG}
                step={1}
                value={latitude}
                onChange={(v) => {
                  setLatitude(v);
                  _mergeBallHelper(
                    { latitude_deg: v },
                    `${COMPONENT_NAME} lat-long sliders`
                  );
                }}
                hint_md={
                  props.showRef
                    ? t('pd.current-x', {
                        x: (refBall.latitude_deg ?? 0).toFixed(0),
                      }).toString()
                    : undefined
                }
              />
            </Box>
            <Box p="2">
              <CommonSliderInput
                key={`long-deg-${referenceKey}`}
                id="PDSeamOrientationLongitude"
                min={-LIMIT_LONGITUDE_DEG}
                max={LIMIT_LONGITUDE_DEG}
                step={1}
                value={longitude}
                onChange={(v) => {
                  setLongitude(v);
                  _mergeBallHelper(
                    { longitude_deg: v },
                    `${COMPONENT_NAME} lat-long sliders`
                  );
                }}
                hint_md={
                  props.showRef
                    ? t('pd.current-x', {
                        x: (refBall.longitude_deg ?? 0).toFixed(0),
                      }).toString()
                    : undefined
                }
              />
            </Box>
          </Grid>
        )}
      </Flex>
    </ErrorBoundary>
  );
};
