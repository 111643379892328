import { RouteHelper } from 'classes/helpers/route.helper';
import { SectionsHelper } from 'classes/helpers/sections.helper';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { AuthContext } from 'contexts/auth.context';
import { MachineContext } from 'contexts/machine.context';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { HOME, ISection, ISectionDef } from 'interfaces/i-section';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { GAME_STATUS_BLACKLIST } from 'lib_ts/enums/mlb.enums';
import { SpecialMsPosition } from 'lib_ts/interfaces/machine-msg/i-special-mstarget';
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const CONTEXT_NAME = 'SectionsContext';

export enum DirtyForm {
  AdminVideos = 'AdminVideos',
  PitchDesign = 'PitchDesign',
  PitchUploader = 'PitchUploader',
  VideoLibrary = 'VideoLibrary',
}

interface IChangeSectionConfig extends ISectionDef {
  trigger: string;
  beforeNavCallback?: () => Promise<void>;

  // resets dirty forms and skips prompts
  ignoreDirty?: boolean;
}

export interface ISectionsContext {
  active: ISectionDef;
  userSections?: ISection[];
  adminSections?: ISection[];

  // todo: get rid of this shim for a better breadcrumb management method
  lastListID?: string;

  dirtyForms: DirtyForm[];
  readonly markDirtyForm: (context: DirtyForm) => void;
  readonly clearDirtyForm: (context: DirtyForm) => void;

  readonly tryGoHome: () => boolean;
  readonly tryChangeSection: (config: IChangeSectionConfig) => boolean;
}

const DEFAULT: ISectionsContext = {
  active: {
    section: SectionName.Pitches,
    subsection: SubSectionName.Library,
  },
  userSections: [],

  dirtyForms: [],
  markDirtyForm: () => console.error(`${CONTEXT_NAME}: not init`),
  clearDirtyForm: () => console.error(`${CONTEXT_NAME}: not init`),

  tryGoHome: () => false,
  tryChangeSection: () => false,
};

export const SectionsContext = createContext(DEFAULT);

interface IProps {
  children: ReactNode;
}

export const SectionsProvider: FC<IProps> = (props) => {
  const authCx = useContext(AuthContext);
  const machineCx = useContext(MachineContext);

  const [_restored, _setRestored] = useState(false);

  const [_userSections, _setUserSections] = useState(DEFAULT.userSections);
  const [_adminSections, _setAdminSections] = useState(DEFAULT.adminSections);

  const [_active, _setActive] = useState(DEFAULT.active);
  const [_pendingConfig, _setPendingConfig] = useState<
    IChangeSectionConfig | undefined
  >(undefined);

  const [_lastListID, _setLastListID] = useState(DEFAULT.lastListID);

  useEffect(() => {
    if (_active.section !== SectionName.Pitches) {
      _setLastListID(undefined);
      return;
    }

    switch (_active.subsection) {
      case SubSectionName.Lists:
      case SubSectionName.Library: {
        // e.g. PD and PU breadcrumbs will go back to library
        _setLastListID(undefined);
        break;
      }

      case SubSectionName.List: {
        // e.g. PD and PU breadcrumbs will go back to this particular list
        _setLastListID(_active.fragments?.[0]);
        break;
      }

      default: {
        break;
      }
    }
  }, [_active]);

  const [_dirtyForms, _setDirtyForms] = useState(DEFAULT.dirtyForms);
  const [_confirm, _setConfirm] = useState<number | undefined>();

  const location = useLocation();
  const navigate = useNavigate();

  const _changeSection = async (config: IChangeSectionConfig) => {
    if (config.beforeNavCallback) {
      await config.beforeNavCallback();
    }

    /** clear the dirty contexts whenever changing sections so it doesn't get stuck */
    _setDirtyForms([]);

    /** attempt to start screensaver (only if moving to different section, e.g. list to list won't trigger this) */
    if (machineCx.machine.enable_auto_reset_ms) {
      machineCx.specialMstarget(SpecialMsPosition.lowered);
    }

    _setActive({
      section: config.section,
      subsection: config.subsection,
      fragments: config.fragments,
    });

    navigate(
      RouteHelper.getSlug([config.section, config.subsection], config.fragments)
    );
  };

  const _tryChangeSection = useCallback(
    (config: IChangeSectionConfig): boolean => {
      console.debug(
        `trying to change sections with ${_dirtyForms.length} dirty forms`,
        _dirtyForms
      );

      if (config.ignoreDirty || _dirtyForms.length === 0) {
        _changeSection(config);
        return true;
      }

      /** store the selection */
      _setPendingConfig(config);

      /** show confirmation */
      _setConfirm(Date.now());

      return false;
    },
    [_dirtyForms]
  );

  const state: ISectionsContext = {
    active: _active,

    userSections: _userSections,
    adminSections: _adminSections,

    lastListID: _lastListID,

    dirtyForms: _dirtyForms,
    markDirtyForm: (form) => {
      console.debug(`adding form ${form} to dirty list`);
      _setDirtyForms(ArrayHelper.unique([..._dirtyForms, form]));
    },
    clearDirtyForm: (form) => {
      console.debug(`removing form ${form} from dirty list`);
      _setDirtyForms(_dirtyForms.filter((c) => c !== form));
    },

    tryGoHome: () => {
      return _tryChangeSection({
        ...HOME,
        trigger: 'go home',
      });
    },
    tryChangeSection: _tryChangeSection,
  };

  /** after launch, once user and admin sections have loaded
   * do a one-time restore (if possible) of the active section and subsection
   * based on the window location */
  useEffect(() => {
    if (_restored) {
      return;
    }

    if (!_userSections) {
      return;
    }

    if (!_adminSections) {
      return;
    }

    /**
     * 0-index => empty, since the paths start with /
     * 1-index => section name slugified
     * 2-index => (optional) subsection name slugified OR fragment
     * 3-index => (optional) fragment(s) (only if subsection exists)
     */
    const locationParts = location.pathname.split('/');

    const sectionSlug = RouteHelper.getSlug([locationParts[1]]);

    const section = [..._userSections, ..._adminSections].find(
      (s) => s.slug === sectionSlug
    );

    if (!section) {
      _tryChangeSection({
        ...HOME,
        trigger: 'failed from location at startup, go home',
      });
      return;
    }

    const subsectionSlug = section.subsections
      ? RouteHelper.getSlug([locationParts[2]])
      : undefined;

    const subsection = section?.subsections?.find(
      (ss) => ss.slug === subsectionSlug
    );

    const fragments = locationParts.slice(subsection ? 3 : 2);

    _tryChangeSection({
      trigger: 'from location at startup',
      section: section.value,
      subsection: subsection?.value,
      fragments: fragments,
    });

    _setRestored(true);
  }, [_restored, _userSections, _adminSections]);

  /** go home upon starting impersonation */
  useEffect(() => {
    if (authCx.current.mode !== 'impostor') {
      // skip if the user isn't impersonating
      return;
    }

    _tryChangeSection({
      ...HOME,
      trigger: 'started impersonation, go home',
    });
  }, [authCx.current.mode]);

  /** update sidebar options and potentially active section whenever user role and/or game status changes */
  useEffect(() => {
    const userOptions = SectionsHelper.getUserSections({
      permissions: authCx.current,
      restricted: authCx.restrictedGameStatus,
    });
    _setUserSections(userOptions);

    const adminOptions = SectionsHelper.getAdminSections({
      role: authCx.current.role,
      restricted: authCx.restrictedGameStatus,
      mode: authCx.current.mode,
    });
    _setAdminSections(adminOptions);

    if (
      [...userOptions, ...adminOptions].findIndex(
        (s) => !s.invisible && s.value === _active.section
      ) !== -1
    ) {
      /** active section is still available */
      return;
    }

    if (
      authCx.gameStatus &&
      GAME_STATUS_BLACKLIST.includes(authCx.gameStatus)
    ) {
      _tryChangeSection({
        trigger: `user on section ${_active} redirected due to game status ${authCx.gameStatus}`,
        section: SectionName.GameInProgress,
        ignoreDirty: true,
      });
      return;
    }
  }, [authCx.current, authCx.gameStatus]);

  return (
    <SectionsContext.Provider value={state}>
      {props.children}

      {_confirm && (
        <CommonConfirmationDialog
          // every new value of _confirm causes the alert dialog to re-mount
          key={_confirm}
          identifier="ConfirmChangeSectionDialog"
          title="common.warning"
          description="common.unsaved-changes-msg"
          action={{
            label: 'common.proceed',
            onClick: () => {
              _setDirtyForms([]);

              if (_pendingConfig) {
                _changeSection(_pendingConfig);
              }
            },
          }}
          cancel={{
            onClick: () => {
              // do nothing
            },
          }}
        />
      )}
    </SectionsContext.Provider>
  );
};
