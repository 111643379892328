import { Box, Button, Flex, Text } from '@radix-ui/themes';
import { CommonCallout } from 'components/common/callouts';
import { ErrorBoundary } from 'components/common/error-boundary';
import { MachineFireButton } from 'components/machine/buttons/fire';
import { CommonStepHeader } from 'components/machine/dialogs/installation/header';
import { AimingContext, AimingProvider } from 'contexts/aiming.context';
import { CookiesContext } from 'contexts/cookies.context';
import { MachineContext } from 'contexts/machine.context';
import { t } from 'i18next';
import { StaticVideoType } from 'lib_ts/enums/machine-msg.enum';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { DEFAULT_PITCH, DEFAULT_PLATE } from 'lib_ts/interfaces/pitches';
import { useContext, useEffect, useState } from 'react';

export const StepTestShotHoC = () => {
  return (
    <AimingProvider>
      <StepTestShot />
    </AimingProvider>
  );
};

const StepTestShot = () => {
  const machineCx = useContext(MachineContext);
  const cookiesCx = useContext(CookiesContext);
  const aimingCx = useContext(AimingContext);

  const [firing, setFiring] = useState(false);

  // just so that the fire button doesn't complain
  useEffect(() => {
    aimingCx.setPitch(DEFAULT_PITCH);
    aimingCx.setPlate(DEFAULT_PLATE);
  }, []);

  return (
    <ErrorBoundary componentName="StepTestShot">
      <Flex direction="column" gap="4">
        <CommonStepHeader label="Test Shot Firing" />

        <Box>
          <Text color={RADIX.COLOR.SECONDARY}>
            To ensure the machine is physically well-positioned, you'll fire a
            test shot.
          </Text>
        </Box>

        <Box>
          <Text color={RADIX.COLOR.SECONDARY}>
            <ol className="no-margin" style={{ paddingInlineStart: '20px' }}>
              <li>Click Load Pitch, then press Fire when prompted.</li>
              <li>The machine will pitch a straight ball at 75 mph.</li>
              <li>
                Observe where the ball crosses home plate and adjust the machine
                track as needed (12 inches off = 1 degree adjustment).
              </li>
              <li>Repeat until the ball passes directly over home plate.</li>
              <li>
                Please take a video recording of the pitch with your personal
                device.
              </li>
            </ol>
          </Text>
        </Box>

        <CommonCallout text="The machine will move into position. Ensure that all hands, tools, and other objects are out of the way before proceeding." />

        <Flex gap="2" justify="end">
          <Button
            className="width-100px"
            onClick={() => {
              machineCx.sendRawTarget(
                {
                  video_uuid: StaticVideoType.training_short,
                  model_id: '',
                  model_key: '',
                  ball_type: machineCx.machine.ball_type,
                  training: false,
                  // wheels
                  w1: 2100,
                  w2: 1800,
                  w3: 1800,
                  // alphas
                  a1: 0,
                  a2: 0,
                  a3: 0,
                  // orientation
                  qw: 1,
                  qx: 0,
                  qy: 0,
                  qz: 0,
                  // aim
                  tilt: 0,
                  yaw: 0,
                  // position
                  px: 0,
                  py: machineCx.machine.plate_distance,
                  pz: 4.5,
                },
                'installation test shot',
                false
              );

              setFiring(true);
            }}
          >
            {t('common.load-pitch')}
          </Button>

          <MachineFireButton
            className="width-100px"
            cookiesCx={cookiesCx}
            machineCx={machineCx}
            aimingCx={aimingCx}
            tags="INSTALL TEST"
            firing={firing}
          />
        </Flex>
      </Flex>
    </ErrorBoundary>
  );
};
