import { InfoCircledIcon } from '@radix-ui/react-icons';
import { StringHelper } from 'classes/helpers/string.helper';
import { PlateCanvas } from 'classes/plate-canvas';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTooltip } from 'components/common/tooltip';
import { SEEK_RESULT_STEPS } from 'components/machine/dialogs/preset-training/controls';
import { ICookiesContext } from 'contexts/cookies.context';
import { IMachineContext } from 'contexts/machine.context';
import { isAfter, parseISO } from 'date-fns';
import { DOT_SIZE_MD } from 'enums/canvas';
import { PTStep } from 'enums/training.enums';
import { isAppearanceDark } from 'index';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { IPitch, IPlateLoc, IPlateLocExt } from 'lib_ts/interfaces/pitches';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import React, { CSSProperties } from 'react';
import Slider from 'react-input-slider';

const COMPONENT_NAME = 'PresetTrainingPlateView';

interface IProps {
  cookiesCx: ICookiesContext;
  machineCx: IMachineContext;

  step: PTStep;

  centeredPitch: IPitch;
  shots: IMachineShot[];
  newShotID?: string;

  // irrelevant for manual training
  onUpdateShot?: (shot: IMachineShot | undefined) => void;

  // does not draw any debug stuff
  simple?: boolean;
  disabled?: boolean;

  /** ft */
  defaultX?: number;
  /** ft */
  defaultY?: number;

  sliderStyle?: CSSProperties;
  sizerStyle?: CSSProperties;
}

interface IState {
  /** ft */
  sliderX: number;
  /** ft */
  sliderY: number;

  shotIDs: string[];

  sortedShots: IMachineShot[];
}

export const getPovTooltip = () => (
  <CommonTooltip trigger={<InfoCircledIcon />} text_md="pd.batters-pov" />
);

export class PresetTrainingPlateView extends React.Component<IProps, IState> {
  private mountDate = new Date();

  private plate_canvas = PlateCanvas.makeLandscape(isAppearanceDark());

  private mainCanvasNode?: HTMLCanvasElement;
  private shotsCanvasNode?: HTMLCanvasElement;

  constructor(props: IProps) {
    super(props);

    this.state = {
      sliderX: props.defaultX ?? 0,
      sliderY: props.defaultY ?? 0,

      shotIDs: props.shots.map((s) => s._id).sort(),

      sortedShots: [],
    };

    this.drawComplete = this.drawComplete.bind(this);
    this.drawMain = this.drawMain.bind(this);
    this.drawShots = this.drawShots.bind(this);
    this.getPlateLoc = this.getPlateLoc.bind(this);
    this.getSortedShots = this.getSortedShots.bind(this);
    this.renderSlider = this.renderSlider.bind(this);
  }

  componentDidMount(): void {
    this.drawMain('componentDidMount');
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const nextState: Partial<IState> = {};

    const enteredComplete =
      prevProps.step !== PTStep.Complete && this.props.step === PTStep.Complete;

    const enteredOptimized =
      !SEEK_RESULT_STEPS.includes(prevProps.step) &&
      SEEK_RESULT_STEPS.includes(this.props.step);

    if (enteredComplete || enteredOptimized) {
      // reset position in case it changed somehow
      nextState.sliderX = this.plate_canvas.CONFIG.x.default_ft;
      nextState.sliderY = this.plate_canvas.CONFIG.y.default_ft;
    }

    const currentShots = this.props.shots;
    const currentShotIDs = currentShots.map((s) => s._id).sort();

    if (
      this.props.centeredPitch._id !== prevProps.centeredPitch?._id ||
      MiscHelper.hashify(this.state.shotIDs) !==
        MiscHelper.hashify(currentShotIDs)
    ) {
      nextState.shotIDs = currentShotIDs;
    }

    if (Object.keys(nextState).length > 0) {
      this.setState(nextState as any, () => {
        if (enteredComplete) {
          this.drawComplete();
        }
      });
    }
  }

  getPlateLoc(): IPlateLoc {
    const output: IPlateLoc = {
      plate_x: this.state.sliderX,
      plate_z: this.state.sliderY,
    };

    return output;
  }

  private drawMain(source: string) {
    console.debug(`drawMain (${source})`);

    const canvas = this.mainCanvasNode;
    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    this.plate_canvas.drawStrikeZoneAutoAdjustArea(ctx);
    this.plate_canvas.drawStrikeZone(ctx);
    this.plate_canvas.drawGround(ctx);

    if (this.props.disabled) {
      // draw the target instead of showing the slider thumb
      this.plate_canvas.drawTarget(ctx, {
        xFt: this.state.sliderX,
        zFt: this.state.sliderY,
      });
    }
  }

  private drawComplete() {
    const canvas = this.shotsCanvasNode;
    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    this.plate_canvas.drawCheckmark(ctx);
  }

  drawShots(source: string) {
    console.debug(`drawShots (${source})`);

    this.setState(
      {
        ...this.state,
        sortedShots: this.getSortedShots(),
      },
      () => {
        const canvas = this.shotsCanvasNode;
        if (!canvas) {
          return;
        }

        const ctx = canvas.getContext('2d');
        if (!ctx) {
          return;
        }

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        if (this.props.step === PTStep.Complete) {
          return;
        }

        const locs = this.state.sortedShots.map((shot) => {
          const o: IPlateLocExt = {
            ...TrajHelper.getPlateLoc(shot.traj),
            _created: shot._created,
            _id: shot._id,
          };

          return o;
        });

        locs.forEach((loc, i) => {
          const isNew = this.props.newShotID === loc._id;

          this.plate_canvas.drawTrainingDot(ctx, loc, {
            isNew: isNew,
            size: DOT_SIZE_MD,
            index: i + 1,
          });
        });
      }
    );
  }

  /** sort newest first */
  private getSortedShots() {
    return [...this.props.shots]
      .filter((m) => isAfter(parseISO(m._created), this.mountDate))
      .sort((a, b) => a._created.localeCompare(b._created));
  }

  render() {
    /** width and height on the canvas element are not the same as its style width and height
     * affects zoom/scaling of the drawings when there are differences
     */
    if (this.props.simple) {
      return (
        <ErrorBoundary componentName={COMPONENT_NAME}>
          {this.renderSlider()}
        </ErrorBoundary>
      );
    }

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        {this.renderSlider()}
      </ErrorBoundary>
    );
  }

  private renderSlider() {
    return (
      <div
        data-x={this.state.sliderX}
        data-z={this.state.sliderY}
        className="slider"
        style={this.props.sliderStyle}
      >
        <div
          className="sizer"
          style={
            this.props.sizerStyle ?? {
              aspectRatio: '1.6',
              maxHeight: '400px',
              minHeight: '100px',
            }
          }
        >
          <canvas
            ref={(node) => (this.mainCanvasNode = node as HTMLCanvasElement)}
            data-identifier="main-canvas"
            width={this.plate_canvas.CONFIG.canvas.width_px}
            height={this.plate_canvas.CONFIG.canvas.height_px}
          />
          <canvas
            ref={(node) => (this.shotsCanvasNode = node as HTMLCanvasElement)}
            data-identifier="shots-canvas"
            width={this.plate_canvas.CONFIG.canvas.width_px}
            height={this.plate_canvas.CONFIG.canvas.height_px}
          />
          <div
            className={StringHelper.classNames([
              'slider-wrapper',
              this.props.disabled ? undefined : 'animate-fade',
            ])}
          >
            <Slider
              data-testid="QuickTrainPlateLocation"
              data-shots={JSON.stringify(
                this.state.sortedShots.map((shot) => {
                  const plateLoc = TrajHelper.getPlateLoc(shot.traj);
                  return { x: plateLoc.plate_x, z: plateLoc.plate_z };
                })
              )}
              axis="xy"
              xstep={this.plate_canvas.CONFIG.x.step}
              xmin={this.plate_canvas.CONFIG.x.min_ft}
              xmax={this.plate_canvas.CONFIG.x.max_ft}
              x={this.state.sliderX}
              ystep={this.plate_canvas.CONFIG.y.step}
              ymin={this.plate_canvas.CONFIG.y.min_ft}
              ymax={this.plate_canvas.CONFIG.y.max_ft}
              y={this.state.sliderY}
              disabled={this.props.disabled}
              styles={{
                track: {
                  backgroundColor: 'rgba(0, 0, 255, 0)',
                  width: '100%',
                  height: '100%',
                },
                thumb: {
                  // draw the crosshair as svg instead of showing the slider thumb
                  display: this.props.disabled ? 'none' : undefined,
                  backgroundColor: 'rgba(0, 255, 0, 0)',
                  backgroundImage: 'url(/img/crosshair.svg)',
                  backgroundSize: 'cover',
                  boxShadow: 'none',
                  height: 32,
                  width: 32,
                  margin: '-16px',
                },
              }}
              yreverse
            />
          </div>
        </div>
      </div>
    );
  }
}
