export enum CustomIconPath {
  AddAverages = 'AddAverages',
  AddPitch = 'AddPitch',
  ArrowAxis = 'ArrowAxis',
  Baseball = 'Baseball',
  Beta = 'Beta',
  BetaBeaker = 'BetaBeaker',
  CalibrateDevice = 'CalibrateDevice',
  CheckCircledFilled = 'CheckCircledFilled',
  CollapseAll = 'CollapseAll',
  ColumnCompress = 'ColumnCompress',
  ColumnStretch = 'ColumnStretch',
  FolderClosed = 'FolderClosed',
  FolderOpen = 'FolderOpen',
  Helmet = 'Helmet',
  R2F = 'R2F',
  RepeatOne = 'RepeatOne',
  RowCompress = 'RowCompress',
  RowsAdd = 'RowsAdd',
  RowStretch = 'RowStretch',
  SuperAdmin = 'SuperAdmin',
  SwitchOff = 'SwitchOff',
  SwitchOn = 'SwitchOn',
  TeamAdmin = 'TeamAdmin',
  Void = 'Void',

  // machine states
  BusyMachine = 'BusyMachine',
  ConnectedMachine = 'ConnectedMachine',
  DisconnectedMachine = 'DisconnectedMachine',
  LowerMachine = 'LowerMachine',
  Machine = 'Machine',
  ResetMachine = 'ResetMachine',
}
