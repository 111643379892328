import { Button, Flex, Text } from '@radix-ui/themes';
import { MlbStatsHelper } from 'classes/helpers/mlb-stats.helper';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PitchDesignHelper } from 'classes/helpers/pitch-design.helper';
import { CommonCallout } from 'components/common/callouts';
import { AddAveragesIcon } from 'components/common/custom-icon/shorthands';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTooltip } from 'components/common/tooltip';
import { GameDataContext } from 'contexts/game-data.context';
import { t } from 'i18next';
import { FT_TO_INCHES } from 'lib_ts/classes/math.utilities';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMlbPitchExt } from 'lib_ts/interfaces/mlb-stats-api/i-pitch';
import { ITrajektRefBreak } from 'lib_ts/interfaces/pitches';
import _ from 'lodash';
import { useContext, useMemo } from 'react';

interface IRepertoire {
  type: string;
  // 0-100%
  usage: number;
  speedMPH: number;

  breaks: ITrajektRefBreak;
}

export const GameDataPitcherRepertoire = (props: {
  pitches: IMlbPitchExt[];
}) => {
  const { openAddDialog } = useContext(GameDataContext);

  const repertoire: IRepertoire[] = useMemo(() => {
    const averages = MlbStatsHelper.averagePitches(props.pitches) ?? [];

    const total = _.sum(averages.map((m) => m.samples ?? 0));

    const output: IRepertoire[] = averages.map((avg) => {
      const o: IRepertoire = {
        type: avg.type,
        usage: ((avg.samples ?? 0) / total) * 100,
        speedMPH: avg.releaseData.releaseSpeed,

        breaks: {
          // to match how pitch list reports things (i.e. consistent after saving to PL)
          xInches: -avg.trajectoryData.horizontalBreak * FT_TO_INCHES,
          zInches: avg.trajectoryData.verticalBreakInduced * FT_TO_INCHES,
        },
      };

      return o;
    });

    output.sort((a, b) => (a.usage > b.usage ? -1 : 1));

    return output;
  }, [props.pitches]);

  return (
    <ErrorBoundary componentName="GameDataPitcherRepertoire">
      <Flex p="2" gap="4" direction="column" align="end">
        {repertoire.length === 0 && (
          <CommonCallout text="common.no-results-found" />
        )}

        {repertoire.length > 0 && (
          <table style={{ borderSpacing: '8px 4px' }}>
            <tbody>
              <tr>
                <td>&nbsp;</td>
                <td>
                  <Text size="1" color={RADIX.COLOR.SECONDARY}>
                    Usage <sub>%</sub>
                  </Text>
                </td>
                <td>
                  <Text size="1" color={RADIX.COLOR.SECONDARY}>
                    Vel. <sub>mph</sub>
                  </Text>
                </td>
                <td>
                  <Text size="1" color={RADIX.COLOR.SECONDARY}>
                    VB <sub>in</sub>
                  </Text>
                </td>
                <td>
                  <Text size="1" color={RADIX.COLOR.SECONDARY}>
                    HB <sub>in</sub>
                  </Text>
                </td>
              </tr>

              {repertoire.map((m, i) => (
                <tr key={i}>
                  <td>
                    <Text size="1">{m.type}</Text>
                  </td>
                  <td align="right">
                    <Text size="1">{m.usage.toFixed(1)}</Text>
                  </td>
                  <td align="right">
                    <Text size="1">{m.speedMPH.toFixed(1)}</Text>
                  </td>
                  <td align="right">
                    <Text size="1">{m.breaks.zInches.toFixed(1)}</Text>
                  </td>
                  <td align="right">
                    <CommonTooltip
                      text={PitchDesignHelper.HB_TOOLTIP_TEXT}
                      trigger={
                        <Text size="1">{(-m.breaks.xInches).toFixed(1)}</Text>
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        <Button
          variant={RADIX.BUTTON.VARIANT.SECONDARY}
          onClick={() => {
            const averages = MlbStatsHelper.averagePitches(props.pitches);

            if (!averages || averages.length === 0) {
              NotifyHelper.warning({
                message_md: `There was a problem calculating average pitches. Please check your selections and try again.`,
              });
              return;
            }

            openAddDialog({
              guids: averages,
              pitcherPk: averages[0].pitcherPk,
              isAverage: true,
            });
          }}
        >
          <AddAveragesIcon /> {t('pd.add-averages-to-pitch-list')}
        </Button>
      </Flex>
    </ErrorBoundary>
  );
};
