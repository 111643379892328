import {
  Cross2Icon,
  MagnifyingGlassIcon,
  PlusIcon,
  ReloadIcon,
} from '@radix-ui/react-icons';
import { Box, Flex, Grid, IconButton, Text } from '@radix-ui/themes';
import { CollapseAllIcon } from 'components/common/custom-icon/shorthands';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonTextInput } from 'components/common/form/text';
import { CommonTooltip } from 'components/common/tooltip';
import { PitchListsContext } from 'contexts/pitch-lists/lists.context';
import { SectionsContext } from 'contexts/sections.context';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { t } from 'i18next';
import {
  PitchListCategory,
  PitchListOwner,
} from 'lib_ts/enums/pitch-list.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext } from 'react';

export const Controls = (props: {
  onCreate: () => void;
  onRefresh: () => void;
}) => {
  const { tryChangeSection } = useContext(SectionsContext);
  const { sidebarFilters, setSidebarFilters, collapseFolders } =
    useContext(PitchListsContext);

  return (
    <ErrorBoundary componentName="Controls">
      <Flex direction="column" gap="1" p="2" pt="1">
        <Flex gap="2" justify="between" align="center">
          <Box flexGrow="1" minWidth="0">
            <Text
              size="1"
              className="cursor-pointer"
              onClick={() =>
                tryChangeSection({
                  trigger: 'sidebar pitch lists menu',
                  section: SectionName.Pitches,
                  subsection: SubSectionName.Lists,
                })
              }
              truncate
            >
              {t('main.pitch-lists')}
            </Text>
          </Box>

          <Box>
            <CommonTooltip
              text="pl.collapse-all"
              trigger={
                <IconButton
                  className="btn-floating"
                  size="1"
                  variant={RADIX.BUTTON.VARIANT.BORDERLESS}
                  color={RADIX.COLOR.NEUTRAL}
                  onClick={collapseFolders}
                >
                  <CollapseAllIcon />
                </IconButton>
              }
            />
          </Box>
          <Box>
            <CommonTooltip
              text="pl.create-new-list"
              trigger={
                <IconButton
                  className="btn-floating"
                  size="1"
                  variant={RADIX.BUTTON.VARIANT.BORDERLESS}
                  color={RADIX.COLOR.NEUTRAL}
                  onClick={props.onCreate}
                >
                  <PlusIcon />
                </IconButton>
              }
            />
          </Box>
          <Box>
            <CommonTooltip
              text="pl.refresh-lists"
              trigger={
                <IconButton
                  className="btn-floating"
                  size="1"
                  variant={RADIX.BUTTON.VARIANT.BORDERLESS}
                  color={RADIX.COLOR.NEUTRAL}
                  onClick={props.onRefresh}
                >
                  <ReloadIcon />
                </IconButton>
              }
            />
          </Box>
        </Flex>

        <Grid columns="2" gap="2" align="center">
          {/* search bar */}
          <Box>
            <CommonTextInput
              id="pitch-lists-search"
              size="1"
              leftSlot={
                <CommonTooltip
                  text="pl.search-lists"
                  trigger={<MagnifyingGlassIcon className="cursor-pointer" />}
                />
              }
              rightSlot={
                sidebarFilters.search ? (
                  <Cross2Icon
                    className="cursor-pointer"
                    onClick={() => setSidebarFilters({ search: '' })}
                  />
                ) : undefined
              }
              value={sidebarFilters.search}
              onChange={(v) => setSidebarFilters({ search: v ?? '' })}
            />
          </Box>

          {/* root dropdown */}
          <Box>
            <CommonSearchInput
              id="pitch-lists-root"
              size="1"
              btnSize="1"
              values={sidebarFilters.category ? [sidebarFilters.category] : []}
              onChange={(values) =>
                setSidebarFilters({ category: values[0] as PitchListCategory })
              }
              placeholder="All Lists"
              options={[
                {
                  label: 'common.personal',
                  value: PitchListOwner.User,
                },
                {
                  label: 'common.team',
                  value: PitchListOwner.Team,
                },
                {
                  label: 'common.machine',
                  value: PitchListOwner.Machine,
                },
                // todo: add options for filtering by list type, if necessary
              ]}
              hideSearch
              optional
            />
          </Box>
        </Grid>
      </Flex>
    </ErrorBoundary>
  );
};
