import { CalendarIcon, CaretUpIcon, Cross2Icon } from '@radix-ui/react-icons';
import { Popover, TextField } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonDateWidget } from 'components/common/form/date/date-widget';
import {
  CommonTimeWidget,
  NEAREST_MINUTE,
} from 'components/common/form/date/time-widget';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonInputHint } from 'components/common/form/hint';
import { CommonInputLabel } from 'components/common/form/label';
import { CommonInputWrapper } from 'components/common/form/wrapper';
import { addHours, startOfDay } from 'date-fns';
import { format } from 'date-fns-tz';
import enCA from 'date-fns/locale/en-CA';
import {
  LOCAL_DATE_FORMAT,
  LOCAL_DATETIME_FORMAT,
  LOCAL_DATETIME_TIMEZONE_FORMAT,
} from 'enums/env';
import { t } from 'i18next';
import { getTimeFromDate, IDateInput } from 'interfaces/forms/date';
import React from 'react';

const SHOW_TIMEZONE = true;

interface IState {
  open: boolean;
  selectedDate?: Date;
}

export class CommonDateInput extends React.Component<IDateInput, IState> {
  private trigger?: HTMLInputElement;

  constructor(props: IDateInput) {
    super(props);

    this.state = {
      open: false,
      selectedDate: props.defaultValue,
    };

    this.getValueDisplay = this.getValueDisplay.bind(this);
  }

  private getValueDisplay(): string {
    if (!this.state.selectedDate) {
      return '';
    }

    if (this.props.showTime && SHOW_TIMEZONE) {
      // full, with timezone; locale is necessary to ensure TZ prints as the abbreviations instead of GMT[+/-]#
      return format(this.state.selectedDate, LOCAL_DATETIME_TIMEZONE_FORMAT, {
        locale: enCA,
      });
    }

    if (this.props.showTime) {
      // only time, no timezone
      return format(this.state.selectedDate, LOCAL_DATETIME_FORMAT);
    }

    // only date, no time or timezone
    return format(this.state.selectedDate, LOCAL_DATE_FORMAT);
  }

  render() {
    const triggerSlot = (() => {
      if (this.props.optional && this.state.selectedDate) {
        return (
          <Cross2Icon
            onClick={() => {
              if (this.props.disabled) {
                return;
              }

              this.setState({ selectedDate: undefined });
              this.props.onChange(undefined);
            }}
          />
        );
      }

      if (this.state.open) {
        return <CaretUpIcon />;
      }

      return (
        <CalendarIcon
          onClick={() => {
            if (this.props.disabled) {
              return;
            }

            this.setState({ open: true });
          }}
        />
      );
    })();

    const placeholder = t(
      this.props.placeholder ?? 'common.select-date-placeholder'
    ).toString();

    return (
      <CommonInputWrapper {...this.props}>
        <CommonInputLabel {...this.props} />

        <Popover.Root
          open={this.state.open}
          onOpenChange={(open) => {
            if (this.props.disabled) {
              return;
            }

            this.setState({ open: open });
          }}
        >
          <Popover.Trigger disabled={this.props.disabled}>
            <TextField.Root
              ref={(elem) => (this.trigger = elem as HTMLInputElement)}
              size={this.props.size}
              className={`cursor-pointer ${this.props.className ?? ''}`}
              disabled={this.props.disabled}
              color={this.props.inputColor}
              name={this.props.name}
              placeholder={placeholder}
              required={!this.props.optional}
              value={this.getValueDisplay()}
              onChange={() => {
                // onChange provided to suppress error re: value w/o onChange
              }}
              onFocus={() => this.trigger?.blur()}
              type="text"
            >
              <TextField.Slot side="right">{triggerSlot}</TextField.Slot>
            </TextField.Root>
          </Popover.Trigger>
          <Popover.Content className="BorderedContent">
            <CommonFormGrid columns={1}>
              <CommonDateWidget
                minDate={this.props.minDate}
                maxDate={this.props.maxDate}
                allowedDates={this.props.allowedDates}
                defaultDate={this.props.defaultValue}
                onChangeDate={(v) => {
                  if (!v) {
                    this.setState({ selectedDate: undefined }, () => {
                      this.props.onChange(this.state.selectedDate);
                    });
                    return;
                  }

                  // leaving any time values alone
                  const nextValue = this.state.selectedDate ?? v;

                  nextValue.setFullYear(v.getFullYear());
                  nextValue.setMonth(v.getMonth());
                  nextValue.setDate(v.getDate());

                  this.setState({ selectedDate: nextValue }, () => {
                    this.props.onChange(this.state.selectedDate);
                  });
                }}
              />

              {this.props.showTime && (
                <CommonTimeWidget
                  defaultTime={
                    this.props.defaultValue
                      ? getTimeFromDate(this.props.defaultValue, NEAREST_MINUTE)
                      : undefined
                  }
                  onChangeTime={(v) => {
                    if (!this.state.selectedDate) {
                      NotifyHelper.warning({
                        message_md: `Please select a date first.`,
                      });
                      return;
                    }

                    if (!v) {
                      this.setState(
                        {
                          // reset time stuff
                          selectedDate: startOfDay(this.state.selectedDate),
                        },
                        () => {
                          this.props.onChange(this.state.selectedDate);
                        }
                      );
                      return;
                    }

                    // reset all other time values to 0 before setting H and M
                    const raw = startOfDay(this.state.selectedDate);

                    raw.setHours(v.h);
                    raw.setMinutes(v.m);

                    // convert from minutes to negative hours
                    const local = -(new Date().getTimezoneOffset() / 60);
                    const delta = local - v.offset;

                    const adjusted = addHours(raw, delta);

                    this.setState({ selectedDate: adjusted }, () => {
                      this.props.onChange(this.state.selectedDate);
                    });
                  }}
                />
              )}
            </CommonFormGrid>
          </Popover.Content>
        </Popover.Root>

        <CommonInputHint {...this.props} />
      </CommonInputWrapper>
    );
  }
}
