import { Box, Flex, Heading, Spinner, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { TrainingControls } from 'components/machine/dialogs/training/controls';
import { AimingContext, IAimingContext } from 'contexts/aiming.context';
import { AuthContext } from 'contexts/auth.context';
import { CookiesContext, ICookiesContext } from 'contexts/cookies.context';
import {
  IMachineCalibrationContext,
  MachineCalibrationContext,
  MAX_REF_LIST_SHOTS,
} from 'contexts/machine-calibration.context';
import { IMachineContext, MachineContext } from 'contexts/machine.context';
import {
  IMatchingShotsContext,
  MatchingShotsContext,
} from 'contexts/pitch-lists/matching-shots.context';
import { TrainingContext, TrainingProvider } from 'contexts/training.context';
import { isAfter } from 'date-fns';
import { CookieKey } from 'enums/cookies.enums';
import { CalibrationStep } from 'enums/machine.enums';
import { ProgressDirection } from 'enums/training.enums';
import { t } from 'i18next';
import { IButton } from 'interfaces/i-buttons';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { MetricInterval } from 'lib_ts/enums/machine-models.enums';
import { TrainingMode } from 'lib_ts/enums/machine.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IGatherShotDataQuery } from 'lib_ts/interfaces/modelling/i-gather-shot-data';
import { IPitch } from 'lib_ts/interfaces/pitches';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import React, { useContext } from 'react';
import { AdminMachineModelsService } from 'services/admin/machine-models.service';

const COMPONENT_NAME = 'CollectData';

interface IProps {
  aimingCx: IAimingContext;
  calibrationCx: IMachineCalibrationContext;
  cookiesCx: ICookiesContext;
  machineCx: IMachineContext;
  matchingCx: IMatchingShotsContext;
}

interface IState {
  index?: number;
  error?: boolean;
}

export const CollectDataHoC = () => {
  const props: IProps = {
    aimingCx: useContext(AimingContext),
    cookiesCx: useContext(CookiesContext),
    machineCx: useContext(MachineContext),
    matchingCx: useContext(MatchingShotsContext),
    calibrationCx: useContext(MachineCalibrationContext),
  };

  return <CollectData {...props} />;
};

class CollectData extends React.Component<IProps, IState> {
  private init = false;

  constructor(props: IProps) {
    super(props);

    this.state = {};

    this.changePitch = this.changePitch.bind(this);
    this.handleMakeMetric = this.handleMakeMetric.bind(this);
    this.initializeAndResume = this.initializeAndResume.bind(this);
    this.renderControls = this.renderControls.bind(this);
    this.shotsSinceStart = this.shotsSinceStart.bind(this);
    this.trainedSinceStart = this.trainedSinceStart.bind(this);
  }

  componentDidMount(): void {
    if (this.init) {
      return;
    }

    this.init = true;
    this.initializeAndResume();
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>
  ): void {
    if (prevState.index !== this.state.index) {
      if (this.state.index === undefined || this.state.index === -1) {
        this.props.aimingCx.setPitch(undefined);
        return;
      }

      const nextPitch = this.props.calibrationCx.pitches[this.state.index];

      if (this.trainedSinceStart(nextPitch)) {
        // this pitch is already trained, skip to the next pitch
        this.changePitch(ProgressDirection.Next);
        return;
      }

      this.props.aimingCx.setPitch(nextPitch, { loadShots: true });
    }
  }

  componentWillUnmount(): void {
    this.props.machineCx.onEndTraining();
  }

  private trainedSinceStart(pitch: Partial<IPitch>): boolean {
    const shots = this.shotsSinceStart(pitch);
    return shots.filter((s) => s.training_complete).length > 0;
  }

  private shotsSinceStart(pitch: Partial<IPitch>): IMachineShot[] {
    const startDate = this.props.cookiesCx.machineCalibration.start_date
      ? new Date(this.props.cookiesCx.machineCalibration.start_date)
      : undefined;

    return this.props.matchingCx
      .safeGetShotsByPitch(pitch)
      .filter((s) => !startDate || isAfter(new Date(s._created), startDate));
  }

  /**
   * - populate the matching shots context for pitches in this list
   * - use start_date of this model builder session for matching query
   * - find first pitch where matching shots is less than the chosen threshold
   * - resume training from there
   */
  private async initializeAndResume() {
    if (this.props.calibrationCx.pitches.length === 0) {
      NotifyHelper.warning({
        message_md: t('common.request-failed-msg'),
      });
      return;
    }

    if (!this.props.cookiesCx.machineCalibration.start_date) {
      /** shouldn't trigger but fix it in case */
      await this.props.cookiesCx.setCookie(CookieKey.machineCalibration, {
        start_date: new Date().toISOString(),
      });
    }

    await this.props.matchingCx.updatePitches({
      pitches: this.props.calibrationCx.pitches,
      newerThan: this.props.cookiesCx.machineCalibration.start_date,
      includeHitterPresent: false,
      includeLowConfidence: true,
      limit:
        // ensures that sufficient shots are fetched to proceed, e.g. calibration list count exceeds quick shot requirement
        (this.props.cookiesCx.machineCalibration.shots ?? MAX_REF_LIST_SHOTS) +
        1,
    });

    const skippedIDs = this.props.cookiesCx.machineCalibration.skippedPitchIDs;
    const resumeIndex = this.props.calibrationCx.pitches.findIndex(
      (p) => !skippedIDs.includes(p._id) && !this.trainedSinceStart(p)
    );

    this.setState({ index: resumeIndex }, () => {
      if (this.state.index === -1) {
        this.handleMakeMetric();
      }
    });
  }

  private async changePitch(delta: ProgressDirection) {
    if (this.state.index === undefined || this.state.index === -1) {
      return;
    }

    this.setState({
      index: this.state.index + delta,
    });
  }

  private renderControls() {
    if (this.state.index === undefined || this.state.index === -1) {
      return <Spinner />;
    }

    if (!this.props.cookiesCx.machineCalibration.start_date) {
      return (
        <Text>
          Invalid value detected for calibration start date. Please refresh and
          try again.
        </Text>
      );
    }

    const threshold = this.props.cookiesCx.machineCalibration.shots ?? 0;

    if (!threshold) {
      return (
        <Text>
          Invalid value detected for shots per pitch. Please select a valid
          value and try again.
        </Text>
      );
    }

    if (!this.props.aimingCx.pitch) {
      return;
    }

    const prevButton: IButton = {
      label: 'common.back',
      disabled: this.props.calibrationCx.loading,
      onClick: () => this.props.calibrationCx.setStep(CalibrationStep.Setup),
    };

    return (
      <AuthContext.Consumer>
        {(authCx) => (
          <TrainingProvider mode={TrainingMode.Quick}>
            <TrainingContext.Consumer>
              {(trainingCx) => (
                <TrainingControls
                  aimingCx={this.props.aimingCx}
                  defaultIndex={this.state.index}
                  cookiesCx={this.props.cookiesCx}
                  machineCx={this.props.machineCx}
                  matchingCx={this.props.matchingCx}
                  authCx={authCx}
                  trainingCx={trainingCx}
                  pitches={this.props.calibrationCx.pitches}
                  threshold={threshold}
                  left_button={prevButton}
                  beforeNext={() => {
                    const pitch = this.props.aimingCx.pitch;
                    if (!pitch) {
                      // shouldn't trigger, but do nothing if the pitch can't be found
                      return;
                    }

                    if (this.props.matchingCx.isPitchTrained(pitch)) {
                      // do nothing if the pitch was successfully trained
                      return;
                    }

                    // make a note of the pitch that was skipped to avoid returning to it later
                    this.props.cookiesCx.setCookie(
                      CookieKey.machineCalibration,
                      {
                        skippedPitchIDs: ArrayHelper.unique([
                          ...this.props.cookiesCx.machineCalibration
                            .skippedPitchIDs,
                          pitch._id,
                        ]),
                      }
                    );
                  }}
                  onFinish={() => {
                    // move to completed screen
                    this.setState({ index: -1 }, () => {
                      // record moment when all data was collected (for use in model training payload)
                      this.props.cookiesCx
                        .setCookie(CookieKey.machineCalibration, {
                          end_date: new Date().toISOString(),
                        })
                        .then(() => this.handleMakeMetric());
                    });
                  }}
                  showProgress
                  calibrating
                />
              )}
            </TrainingContext.Consumer>
          </TrainingProvider>
        )}
      </AuthContext.Consumer>
    );
  }

  private async handleMakeMetric() {
    const cookie = this.props.cookiesCx.machineCalibration;

    if (!cookie.machineID) {
      NotifyHelper.warning({
        message_md: 'Machine is not specified in model builder.',
        inbox: true,
      });
      return;
    }

    if (!cookie.ball_type) {
      NotifyHelper.warning({
        message_md: 'Ball type is not specified in model builder.',
        inbox: true,
      });
      return;
    }

    const payload: IGatherShotDataQuery = {
      machineID: cookie.machineID,
      ball_type: cookie.ball_type,
      start_date: cookie.start_date,
      end_date: cookie.end_date,
    };

    NotifyHelper.success({
      message_md: 'Evaluating your data, please wait...',
      inbox: true,
    });

    const metric =
      await AdminMachineModelsService.getInstance().createMachineMetric({
        query: payload,
        job_interval: MetricInterval.Calibration,
        list_length: this.props.calibrationCx.pitches.length,
      });

    if (!metric) {
      /** shouldn't trigger */
      NotifyHelper.error({
        message_md: 'Received empty result from metric creation.',
        inbox: true,
      });

      this.props.calibrationCx.setStep(CalibrationStep.TrainError);
      return;
    }

    this.props.calibrationCx.setRealMachineMetric(metric);
    this.props.calibrationCx.setStep(CalibrationStep.ReviewMetric);
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
          <Heading size={RADIX.HEADING.SIZE.MD}>
            {this.state.index === -1
              ? 'Data Collection Complete'
              : 'Data Collection In Progress'}
          </Heading>

          <Box>{this.renderControls()}</Box>
        </Flex>
      </ErrorBoundary>
    );
  }
}
