import { Box, Flex, Heading, Skeleton, Text } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { InstallationContext } from 'components/machine/dialogs/installation/context';
import { appearanceImgPath } from 'index';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { InstallStepStatus } from 'lib_ts/enums/machine.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext, useEffect, useMemo, useState } from 'react';
import { AdminMachinesService } from 'services/admin/machines.service';
import slugify from 'slugify';

interface IProps {
  machineID: string;
}

export const MachinePerformanceTab = (props: IProps) => {
  const {
    details,
    machinePerformance: perf,
    setMachinePerformance,
  } = useContext(InstallationContext);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (perf) {
      // we already loaded the data before
      return;
    }

    if (details.step_repeatability !== InstallStepStatus.Complete) {
      return;
    }

    if (!details.repeatability_list_id) {
      return;
    }

    setLoading(true);

    AdminMachinesService.getInstance()
      .getRepeatabilityImages({
        machineID: props.machineID,
        ball_type: details.ball_type,
        collectionID: details.repeatability_id,
        aiming: false,
        pitch_list_ids: [details.repeatability_list_id],
        exclude_low_confidence: false,
        exclude_inactive: false,
        center_groups: true,
      })
      .then((results) => {
        console.debug(
          `loaded machine performance results for ${details.repeatability_id}`,
          results
        );

        setMachinePerformance(results);
      })
      .finally(() => setLoading(false));
  }, [props.machineID, details]);

  const content = useMemo(() => {
    if (loading) {
      return <Skeleton height="400px" />;
    }

    if (!perf || perf.images.length === 0) {
      return (
        <Flex pt="5" pb="5" align="center" direction="column" gap="2">
          <img
            className="select-none"
            src={appearanceImgPath('no-data.svg')}
            alt="no data"
            style={{
              width: '80%',
              maxWidth: '462px',
              height: 'auto',
            }}
          />

          <Heading size="3">No Data to Display</Heading>

          <Text color={RADIX.COLOR.SECONDARY}>
            Please complete data collection to explore the results here.
          </Text>
        </Flex>
      );
    }

    return (
      <Flex direction="column" gap="2">
        {perf.images.map((img, i) => (
          <Box key={`image-${i}`}>
            <img
              src={`data:image/png;base64, ${img.base64}`}
              style={{ width: '100%', height: 'auto' }}
              title="Click to save the graph as an image"
              alt={img.filename}
              className="cursor-pointer"
              onClick={async () => {
                const slug = slugify(img.filename, {
                  replacement: '_',
                  trim: true,
                });

                const url = `data:image/${img.extension};base64, ${img.base64}`;
                const res = await fetch(url);
                const blob = await res.blob();

                MiscHelper.saveAs(blob, `${props.machineID}-${slug}`);
              }}
            />
          </Box>
        ))}
      </Flex>
    );
  }, [loading, perf]);

  return (
    <ErrorBoundary componentName="MachinePerformanceTab">
      {content}
    </ErrorBoundary>
  );
};
