import { CheckIcon, DownloadIcon } from '@radix-ui/react-icons';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import {
  Badge,
  Box,
  Flex,
  Grid,
  Heading,
  IconButton,
  Text,
} from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { StringHelper } from 'classes/helpers/string.helper';
import { TrainingHelper } from 'classes/helpers/training-helper';
import { CommonDialog } from 'components/common/dialogs';
import { DialogButton } from 'components/common/dialogs/button';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonNavMenu } from 'components/common/layout/nav-menu';
import {
  InstallationContext,
  InstallationProvider,
} from 'components/machine/dialogs/installation/context';
import { InstallationDetails } from 'components/machine/dialogs/installation/details';
import {
  StepBallTypeCalibration,
  StepEnd,
  StepGeneralConfig,
  StepProjectorConfig,
  StepRepeatability,
  StepResults,
  StepTestShotHoC,
} from 'components/machine/dialogs/installation/steps';
import { MachineContext } from 'contexts/machine.context';
import { InstallStep, ResultsTabKey, SubStepState } from 'enums/installation';
import { t } from 'i18next';
import { IButton } from 'interfaces/i-buttons';
import { IBaseDialog } from 'interfaces/i-dialogs';
import { IInstallStepDef } from 'interfaces/i-installation';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { getModelKey } from 'lib_ts/classes/ms.helper';
import { MachineModelType } from 'lib_ts/enums/machine-models.enums';
import { InstallStepStatus } from 'lib_ts/enums/machine.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IInstallationDetails } from 'lib_ts/interfaces/i-machine';
import _, { clamp } from 'lodash';
import {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AdminMachineModelsService } from 'services/admin/machine-models.service';
import slugify from 'slugify';

// CS says they don't need a download button for model perf results
const ENABLE_MODEL_PERF_REPORTS = false;

const squircleCSS: CSSProperties = {
  borderRadius: '6px',
  width: '20px',
  height: '20px',
  justifyContent: 'center',
  alignItems: 'center',
};

interface IProps extends IBaseDialog {}

export const InstallationDialogHoC = (props: IProps) => {
  return (
    <InstallationProvider>
      <InstallationDialog {...props} />
    </InstallationProvider>
  );
};

const InstallationDialog = (props: IProps) => {
  const {
    details,
    updateDetails,
    resultsTab,
    modelPerformance,
    machinePerformance,
  } = useContext(InstallationContext);

  const {
    machine,
    update: updateMachine,
    setAutoFire,
  } = useContext(MachineContext);

  // automatically mark the install as completed as soon as results step is completed
  useEffect(() => {
    if (details.step_results !== InstallStepStatus.Complete) {
      return;
    }

    if (details.end_date) {
      return;
    }

    updateDetails({
      end_date: new Date().toISOString(),
    });
  }, [details.step_results, details.end_date]);

  const [dialogConfirm, setDialogConfirm] = useState<number>();

  // check ball type alignment once, at mount (e.g. changed ball type before resuming)
  useEffect(() => {
    if (details.ball_type !== machine.ball_type) {
      NotifyHelper.warning({
        message_md: t('settings.change-ball-type-msg', {
          x: details.ball_type,
        }),
      });

      updateMachine({
        _id: machine._id,
        ball_type: details.ball_type,
      });
    }
  }, []);

  const [steps, setSteps] = useState<IInstallStepDef[]>(
    TrainingHelper.getInstallationSteps(details)
  );

  const [activeIndex, setActiveIndex] = useState(0);

  // only runs once, on mount
  useEffect(() => {
    const maxTouched = _.findLastIndex(steps, (s) =>
      [InstallStepStatus.Complete, InstallStepStatus.Skipped].includes(s.status)
    );

    const maxStatus = steps[maxTouched]?.status;

    const nextIndex =
      maxStatus === InstallStepStatus.Complete
        ? // if the max thing was completed, then try to go to the next step
          maxTouched + 1
        : // if the max thing was skipped, then see if the user would like to do it instead of skipping
          maxTouched;

    setActiveIndex(clamp(nextIndex, 0, steps.length - 1));
  }, []);

  const activeStep = useMemo<IInstallStepDef>(
    () => steps[activeIndex],
    [steps, activeIndex]
  );

  const [maxStep, setMaxStep] = useState(activeIndex);

  useEffect(() => {
    // disable auto-fire whenever changing steps
    setAutoFire(false);

    // to allow users to return to any completed or skipped step but not future steps
    if (activeIndex > maxStep) {
      setMaxStep(activeIndex);
    }
  }, [activeIndex]);

  // mark the active step as either skipped or completed
  const updateStepStatus = useCallback(
    (status: InstallStepStatus) => {
      const step = steps[activeIndex];

      if (!step) {
        // couldn't find the step, shouldn't trigger
        console.warn(`failed to find install step w/ index ${activeIndex}`);
        return;
      }

      // completed steps remain completed even if they are skipped later
      const safeStatus =
        status === InstallStepStatus.Skipped &&
        step.status === InstallStepStatus.Complete
          ? InstallStepStatus.Complete
          : status;

      // save changes to the machine record
      const payload: Partial<IInstallationDetails> = {};

      switch (step.id) {
        case InstallStep.TestShot: {
          payload.step_test_shot = safeStatus;
          break;
        }

        case InstallStep.GeneralConfig: {
          payload.step_general_config = safeStatus;
          break;
        }

        case InstallStep.ProjectorConfig: {
          payload.step_projector_config = safeStatus;
          break;
        }

        case InstallStep.BallType: {
          payload.step_ball_type = safeStatus;
          break;
        }

        case InstallStep.Repeatability: {
          payload.step_repeatability = safeStatus;
          break;
        }

        case InstallStep.Results: {
          payload.step_results = safeStatus;
          break;
        }

        default: {
          console.warn(`encountered unexpected install step ${step.id}`);
          break;
        }
      }

      updateDetails(payload);

      // locally mark the step as completed
      step.status = safeStatus;
      setSteps([...steps]);

      // go to next step if possible
      if (activeIndex + 1 < steps.length) {
        setActiveIndex(activeIndex + 1);
      }
    },
    [machine, details, steps, activeIndex]
  );

  const [startedProjector, setStartedProjector] = useState(false);
  const [completedResults, setCompletedResults] = useState(false);

  const [calibrationState, setCalibrationState] = useState(
    SubStepState.NotStarted
  );
  const [repeatabilityState, setRepeatabilityState] = useState(
    SubStepState.NotStarted
  );

  const buttons = useMemo((): IButton[] => {
    switch (activeStep.id) {
      case InstallStep.TestShot: {
        return [
          {
            label: 'Mark as Complete',
            variant: RADIX.BUTTON.VARIANT.PRIMARY,
            onClick: () => updateStepStatus(InstallStepStatus.Complete),
          },
        ];
      }

      case InstallStep.GeneralConfig: {
        return [
          {
            label: 'Skip Step',
            variant: RADIX.BUTTON.VARIANT.SECONDARY,
            onClick: () => updateStepStatus(InstallStepStatus.Skipped),
          },
          {
            label: 'Mark as Complete',
            variant: RADIX.BUTTON.VARIANT.PRIMARY,
            onClick: () => updateStepStatus(InstallStepStatus.Complete),
          },
        ];
      }

      case InstallStep.ProjectorConfig: {
        return [
          {
            label: 'Skip Step',
            variant: RADIX.BUTTON.VARIANT.SECONDARY,
            onClick: () => updateStepStatus(InstallStepStatus.Skipped),
          },
          {
            invisible: startedProjector,
            label: 'Start',
            onClick: () => setStartedProjector(true),
          },
          {
            invisible: !startedProjector,
            label: 'Mark as Complete',
            variant: RADIX.BUTTON.VARIANT.PRIMARY,
            onClick: () => updateStepStatus(InstallStepStatus.Complete),
          },
        ];
      }

      case InstallStep.BallType: {
        return [
          {
            label: 'Skip Step',
            variant: RADIX.BUTTON.VARIANT.SECONDARY,
            onClick: () => updateStepStatus(InstallStepStatus.Skipped),
          },
          {
            invisible: calibrationState !== SubStepState.NotStarted,
            label: 'Start',
            onClick: () => setCalibrationState(SubStepState.InProgress),
          },
          {
            invisible: calibrationState !== SubStepState.Completed,
            label: 'Mark as Complete',
            variant: RADIX.BUTTON.VARIANT.PRIMARY,
            onClick: () => updateStepStatus(InstallStepStatus.Complete),
          },
          {
            invisible: calibrationState !== SubStepState.Error,
            label: 'Restart',
            onClick: () => setDialogConfirm(Date.now()),
            variant: RADIX.BUTTON.VARIANT.PRIMARY,
          },
        ];
      }

      case InstallStep.Repeatability: {
        return [
          {
            label: 'Skip Step',
            variant: RADIX.BUTTON.VARIANT.SECONDARY,
            onClick: () => updateStepStatus(InstallStepStatus.Skipped),
          },
          {
            invisible: repeatabilityState !== SubStepState.NotStarted,
            label: 'Start',
            onClick: () => setRepeatabilityState(SubStepState.InProgress),
          },
          {
            invisible: repeatabilityState !== SubStepState.Completed,
            label: 'Mark as Complete',
            variant: RADIX.BUTTON.VARIANT.PRIMARY,
            onClick: () => updateStepStatus(InstallStepStatus.Complete),
          },
          {
            invisible: repeatabilityState !== SubStepState.Error,
            label: 'Restart',
            variant: RADIX.BUTTON.VARIANT.PRIMARY,
            onClick: () => setDialogConfirm(Date.now()),
          },
        ];
      }

      case InstallStep.Results: {
        return [
          {
            // model performance
            label: 'Download Reports',
            variant: RADIX.BUTTON.VARIANT.SECONDARY,
            invisible:
              !ENABLE_MODEL_PERF_REPORTS ||
              resultsTab !== ResultsTabKey.ModelPerformance,
            disabled: !modelPerformance,
            icon: <DownloadIcon />,
            onClick: async () => {
              if (!modelPerformance) {
                return;
              }

              MiscHelper.saveAs(
                new Blob([
                  JSON.stringify(
                    {
                      details: details,
                      performance: modelPerformance,
                    },
                    null,
                    2
                  ),
                ]),
                `${machine.machineID}-model-performance.json`
              );
            },
          },
          {
            // machine performance
            label: 'Download Reports',
            variant: RADIX.BUTTON.VARIANT.SECONDARY,
            invisible: resultsTab !== ResultsTabKey.MachinePerformance,
            disabled:
              !machinePerformance || machinePerformance.images.length === 0,
            icon: <DownloadIcon />,
            onClick: async () => {
              if (!machinePerformance) {
                return;
              }

              for (const img of machinePerformance.images) {
                const slug = slugify(img.filename, {
                  replacement: '_',
                  trim: true,
                });

                const url = `data:image/${img.extension};base64, ${img.base64}`;
                const res = await fetch(url);
                const blob = await res.blob();

                MiscHelper.saveAs(blob, `${machine.machineID}-${slug}`);
              }
            },
          },
          {
            label: 'Complete Machine Install',
            variant: RADIX.BUTTON.VARIANT.PRIMARY,
            onClick: () => updateStepStatus(InstallStepStatus.Complete),
          },
        ];
      }

      case InstallStep.End: {
        return [
          {
            label: 'Finish',
            variant: RADIX.BUTTON.VARIANT.PRIMARY,
            onClick: props.onClose,
          },
        ];
      }

      default: {
        return [];
      }
    }
  }, [
    activeStep,
    startedProjector,
    calibrationState,
    repeatabilityState,
    completedResults,
    resultsTab,
    machinePerformance,
    modelPerformance,
    updateStepStatus,
  ]);

  const content = useMemo(() => {
    switch (activeStep.id) {
      case InstallStep.TestShot: {
        return <StepTestShotHoC />;
      }

      case InstallStep.GeneralConfig: {
        return <StepGeneralConfig />;
      }

      case InstallStep.ProjectorConfig: {
        return <StepProjectorConfig started={startedProjector} />;
      }

      case InstallStep.BallType: {
        return (
          <StepBallTypeCalibration
            state={calibrationState}
            onComplete={async () => {
              if (details.model_id) {
                // just move on
                setCalibrationState(SubStepState.Completed);
                return;
              }

              NotifyHelper.warning({
                message_md:
                  'Creating model, please wait as this may take some time...',
              });

              // create a new model from the collected data
              const model =
                await AdminMachineModelsService.getInstance().trainCollectionModel(
                  {
                    model_type: MachineModelType.LinearRansac,
                    collectionID: details.calibration_id,
                    machineID: machine.machineID,
                    ball_type: details.ball_type,
                  }
                );

              if (!model) {
                setCalibrationState(SubStepState.Error);
                return;
              }

              // activate the model for the installation ball type
              updateMachine({
                _id: machine._id,
                model_ids: {
                  ...machine.model_ids,
                  [getModelKey(machine)]: model._id,
                },
              });

              // record this model for the installation
              updateDetails({
                model_id: model._id,
              });

              setCalibrationState(SubStepState.Completed);
            }}
          />
        );
      }

      case InstallStep.Repeatability: {
        return (
          <StepRepeatability
            state={repeatabilityState}
            onComplete={() => setRepeatabilityState(SubStepState.Completed)}
          />
        );
      }

      case InstallStep.Results: {
        return <StepResults />;
      }

      case InstallStep.End: {
        return <StepEnd />;
      }

      default: {
        return (
          <Box className={RADIX.VFLEX.GROW}>
            {/* active step content */}
            You are on step {activeIndex + 1} (max is {maxStep + 1})
          </Box>
        );
      }
    }
  }, [
    activeIndex,
    maxStep,
    activeStep,
    startedProjector,
    calibrationState,
    repeatabilityState,
  ]);

  return (
    <ErrorBoundary componentName="MachineInstallationDialog">
      {dialogConfirm && (
        <CommonConfirmationDialog
          key={dialogConfirm}
          identifier="ConfirmDataCollectorReset"
          title="common.warning"
          content="This will reset your data collection progress and cannot be undone. Are you sure you want to proceed?"
          action={{
            label: 'common.reset',
            // variant: RADIX.BUTTON.VARIANT.PRIMARY,
            color: RADIX.COLOR.DANGER,
            onClick: () => {
              switch (activeStep.id) {
                case InstallStep.BallType: {
                  // getting a new collection ID will reset the data collection process
                  updateDetails({
                    calibration_id: StringHelper.getCollectionID(),
                  });

                  setCalibrationState(SubStepState.NotStarted);

                  updateStepStatus(InstallStepStatus.Incomplete);
                  return;
                }

                case InstallStep.Repeatability: {
                  // getting a new collection ID will reset the data collection process
                  updateDetails({
                    repeatability_id: StringHelper.getCollectionID(),
                  });

                  setRepeatabilityState(SubStepState.NotStarted);

                  updateStepStatus(InstallStepStatus.Incomplete);
                  return;
                }

                default: {
                  return;
                }
              }
            },
          }}
        />
      )}

      <CommonDialog
        {...props}
        modal
        width={RADIX.DIALOG.WIDTH.LG}
        vFlexHeight={RADIX.DIALOG.HEIGHT.LG}
        title={
          <Flex gap="2" align="center">
            <Heading size="5">Machine Install</Heading>
            <Badge size="1" variant="soft">
              {machine.machineID}
            </Badge>
          </Flex>
        }
        leftFooter={
          <Flex gap={RADIX.FLEX.GAP.SM}>
            {activeIndex > 0 && (
              <DialogButton
                label="common.back"
                color={RADIX.COLOR.SECONDARY}
                onClick={() => setActiveIndex(activeIndex - 1)}
              />
            )}

            <Box>
              <InstallationDetails />
            </Box>
          </Flex>
        }
        buttons={buttons}
        content={
          <Grid
            columns="4"
            className={RADIX.VFLEX.WRAPPER}
            gap={RADIX.FLEX.GAP.MD}
          >
            <Box>
              {/* sidebar */}
              <CommonNavMenu orientation="vertical">
                {steps.map((s, i) => {
                  if (s.id === InstallStep.End) {
                    // don't draw the end step as a menu option
                    return <></>;
                  }

                  const isActive = activeIndex === i;

                  const showCheckmark = s.status === InstallStepStatus.Complete;

                  const labelColor = isActive
                    ? RADIX.COLOR.ACCENT
                    : i > maxStep
                    ? RADIX.COLOR.NEUTRAL
                    : undefined;

                  const squircleColor =
                    s.status === InstallStepStatus.Complete
                      ? RADIX.COLOR.SUCCESS
                      : RADIX.COLOR.NEUTRAL;
                  return (
                    <NavigationMenu.Item key={i}>
                      <NavigationMenu.Trigger
                        className={StringHelper.classNames([
                          'NavigationMenuTrigger',
                          isActive ? 'active' : 'inactive',
                        ])}
                        disabled={i > maxStep}
                        onClick={() => {
                          setActiveIndex(i);
                        }}
                      >
                        <Flex p="2" gap="2" align="center">
                          {showCheckmark ? (
                            <IconButton
                              size="1"
                              color={squircleColor}
                              style={squircleCSS}
                              variant="soft"
                            >
                              <CheckIcon />
                            </IconButton>
                          ) : (
                            <Badge
                              size="1"
                              color={squircleColor}
                              style={squircleCSS}
                            >
                              {i + 1}
                            </Badge>
                          )}

                          <Text size="2" color={labelColor}>
                            {t(s.label)}
                          </Text>
                        </Flex>
                      </NavigationMenu.Trigger>
                    </NavigationMenu.Item>
                  );
                })}
              </CommonNavMenu>
            </Box>
            <Flex
              gridColumn="span 3"
              overflowX="hidden"
              overflowY="auto"
              className={RADIX.VFLEX.COLUMN}
              gap={RADIX.FLEX.GAP.MD}
              pr="2"
            >
              {content}
            </Flex>
          </Grid>
        }
      />
    </ErrorBoundary>
  );
};
