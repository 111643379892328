import { Button } from '@radix-ui/themes';
import { MachineContext, MachineDialogMode } from 'contexts/machine.context';
import { t } from 'i18next';
import { QueueState } from 'lib_ts/enums/machine-msg.enum';
import { RADIX, RadixBtnSize } from 'lib_ts/enums/radix-ui';
import { useContext } from 'react';

interface IProps {
  size?: RadixBtnSize;
  /** applied to the button tag */
  className?: string;
}

export const MachineUnavailableButton = (props: IProps) => {
  const { lastStatus, setDialog } = useContext(MachineContext);

  if (lastStatus.queueState === QueueState.Busy) {
    return (
      <Button
        size={props.size}
        color={RADIX.COLOR.WARNING}
        className={props.className}
        onClick={() => setDialog(MachineDialogMode.RequestControl)}
      >
        {t('common.busy')}
      </Button>
    );
  }

  return (
    <Button
      size={props.size}
      color={RADIX.COLOR.DANGER}
      className={props.className}
      onClick={() => setDialog(MachineDialogMode.Disconnected)}
    >
      {t('common.disconnected')}
    </Button>
  );
};
