import { CaretDownIcon } from '@radix-ui/react-icons';
import { Box, Flex, Text } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { t } from 'i18next';
import {
  RADIX,
  RadixColor,
  RadixFlexGap,
  RadixFlexJustify,
} from 'lib_ts/enums/radix-ui';
import React, { useState } from 'react';
import './index.scss';

interface IProps {
  className?: string;

  summary?: string;
  summaryContent?: React.ReactNode;

  summaryColor?: RadixColor;

  // provide to limit the height of details and add animation effect
  maxHeight?: string;

  children: React.ReactNode;
  defaultOpen?: boolean;

  // between summary and children
  gap?: RadixFlexGap;
  justify?: RadixFlexJustify;
}

export const CommonDetails = (props: IProps) => {
  const [open, setOpen] = useState(!!props.defaultOpen);

  const summaryColor = props.summaryColor ?? RADIX.COLOR.ACCENT;

  return (
    <ErrorBoundary componentName="CommonDetails">
      <Flex
        data-identifier="CommonDetails"
        direction="column"
        gap={props.gap ?? RADIX.FLEX.GAP.MD}
        className={props.className}
      >
        <Flex
          data-identifier="CommonDetailsSummary"
          gap={RADIX.FLEX.GAP.SM}
          justify={props.justify}
          className="cursor-pointer"
          onClick={() => setOpen(!open)}
        >
          {props.summary && (
            <>
              <Box minWidth="0">
                <Text color={summaryColor} truncate>
                  {t(props.summary)}
                </Text>
              </Box>
              <Box data-open={open} className="CommonDetailsIndicator">
                <Text color={summaryColor}>
                  <CaretDownIcon />
                </Text>
              </Box>
            </>
          )}

          {props.summaryContent}
        </Flex>

        <Box
          data-identifier="CommonDetailsContent"
          className="CommonDetailsContent"
          data-open={open}
          overflowX="hidden"
          overflowY="auto"
          style={{
            maxHeight: open ? props.maxHeight ?? undefined : '0px',
          }}
        >
          {props.children}
        </Box>
      </Flex>
    </ErrorBoundary>
  );
};
