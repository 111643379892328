import { Box, Flex, Text } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonTabs } from 'components/common/tabs';
import { CommonStepHeader } from 'components/machine/dialogs/installation/header';
import { MachinePerformanceTab } from 'components/machine/dialogs/installation/steps/results/machine-performance-tab';
import { ModelPerformanceTab } from 'components/machine/dialogs/installation/steps/results/model-performance-tab';
import { MachineContext } from 'contexts/machine.context';
import { ResultsTabKey } from 'enums/installation';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { useContext } from 'react';
import { InstallationContext } from '../../context';

const COMPONENT_NAME = 'StepResults';

export const StepResults = () => {
  const { resultsTab, setResultsTab } = useContext(InstallationContext);
  const { machine } = useContext(MachineContext);

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      {/* provider used for training msg listener and failed shots warnings */}

      <Flex direction="column" gap="4">
        <CommonStepHeader label="Results" />

        <Box>
          <Text color={RADIX.COLOR.SECONDARY}>
            This section will be updated as data is collected.
          </Text>
        </Box>

        <CommonTabs
          value={resultsTab}
          onValueChange={(value) => setResultsTab(value as ResultsTabKey)}
          tabs={[
            {
              value: ResultsTabKey.ModelPerformance,
              label: 'Model Performance',
              content: <ModelPerformanceTab machineID={machine.machineID} />,
            },
            {
              value: ResultsTabKey.MachinePerformance,
              label: 'Machine Performance',
              content: <MachinePerformanceTab machineID={machine.machineID} />,
            },
          ]}
        />
      </Flex>
    </ErrorBoundary>
  );
};
