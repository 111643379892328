import { CustomIcon } from 'components/common/custom-icon';
import { CustomIconPath } from 'enums/custom.enums';
import { t } from 'i18next';
import { RADIX } from 'lib_ts/enums/radix-ui';

export const BetaIcon = () => (
  <CustomIcon
    title="BETA"
    icon={CustomIconPath.BetaBeaker}
    color={RADIX.COLOR.BETA}
  />
);

export const VoidIcon = () => <CustomIcon icon={CustomIconPath.Void} />;

export const SuperAdminIcon = () => (
  <CustomIcon
    title="Super Admin"
    icon={CustomIconPath.SuperAdmin}
    color={RADIX.COLOR.SUPER_ADMIN}
  />
);

export const AdminIcon = () => (
  <CustomIcon
    title="Team Admin"
    icon={CustomIconPath.TeamAdmin}
    color={RADIX.COLOR.ANY_ADMIN}
  />
);
export const LowerMachineIcon = () => (
  <CustomIcon
    title={t('common.lower').toString()}
    icon={CustomIconPath.LowerMachine}
  />
);

export const ResetMachineIcon = () => (
  <CustomIcon
    title={t('common.reset').toString()}
    icon={CustomIconPath.ResetMachine}
  />
);

export const MachineIcon = () => <CustomIcon icon={CustomIconPath.Machine} />;

export const ConnectedMachineIcon = () => (
  <CustomIcon
    title={t('common.active').toString()}
    icon={CustomIconPath.ConnectedMachine}
  />
);

export const DisconnectedMachineIcon = () => (
  <CustomIcon
    title={t('common.disconnected').toString()}
    icon={CustomIconPath.DisconnectedMachine}
  />
);

export const BusyMachineIcon = () => (
  <CustomIcon
    title={t('common.busy').toString()}
    icon={CustomIconPath.BusyMachine}
  />
);

export const R2FIcon = () => (
  <CustomIcon
    title={t('common.ready-status').toString()}
    icon={CustomIconPath.R2F}
  />
);

export const CalibrateIcon = () => (
  <CustomIcon
    title={t('common.calibrate-machine').toString()}
    icon={CustomIconPath.CalibrateDevice}
  />
);

export const FolderOpenIcon = () => (
  <CustomIcon icon={CustomIconPath.FolderOpen} />
);

export const FolderClosedIcon = () => (
  <CustomIcon icon={CustomIconPath.FolderClosed} />
);

export const BaseballIcon = () => <CustomIcon icon={CustomIconPath.Baseball} />;

export const HelmetIcon = () => <CustomIcon icon={CustomIconPath.Helmet} />;

export const CollapseAllIcon = () => (
  <CustomIcon icon={CustomIconPath.CollapseAll} />
);

export const AddPitchIcon = () => <CustomIcon icon={CustomIconPath.AddPitch} />;

export const RowsAddIcon = () => <CustomIcon icon={CustomIconPath.RowsAdd} />;

export const AddAveragesIcon = () => (
  <CustomIcon icon={CustomIconPath.AddAverages} />
);
