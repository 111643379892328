import { Box, Flex, Text } from '@radix-ui/themes';
import { CommonCallout } from 'components/common/callouts';
import { ErrorBoundary } from 'components/common/error-boundary';
import { InstallationContext } from 'components/machine/dialogs/installation/context';
import { CommonStepHeader } from 'components/machine/dialogs/installation/header';
import { DataCollectorHoC } from 'components/machine/dialogs/installation/steps/data-collector';
import { MachineContext } from 'contexts/machine.context';
import { SubStepState } from 'enums/installation';
import { ReferenceListType } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IPitch } from 'lib_ts/interfaces/pitches';
import { useContext, useEffect, useState } from 'react';
import { PitchesService } from 'services/pitches.service';

const COMPONENT_NAME = 'StepBallTypeCalibration';

interface IProps {
  state: SubStepState;
  onComplete: () => void;
}

export const StepBallTypeCalibration = (props: IProps) => {
  const { details, updateDetails } = useContext(InstallationContext);
  const { machine } = useContext(MachineContext);
  const [pitches, setPitches] = useState<IPitch[]>();

  useEffect(() => {
    PitchesService.getInstance()
      .getReferenceListPitches(ReferenceListType.BallTypeCalibration)
      .then((result) => {
        setPitches(result);

        if (result.length === 0) {
          return;
        }

        if (!machine.last_installation) {
          return;
        }

        if (details.calibration_list_id) {
          return;
        }

        // fix the list id to match
        updateDetails({
          calibration_list_id: result[0]._parent_id,
        });
      });
  }, []);

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <Flex direction="column" gap="4">
        <CommonStepHeader label="Ball Type Calibration" />

        {props.state === SubStepState.NotStarted && (
          <Box>
            <Text color={RADIX.COLOR.SECONDARY}>
              You're about to fire the Arc to calibrate it for your selected
              ball type. This will ensure accurate pitch delivery with the
              selected ball type. Please make sure only {details.ball_type}{' '}
              balls are placed in the Arc. Please press fire when ready to start
              the calibration process.
            </Text>
          </Box>
        )}

        {props.state !== SubStepState.NotStarted &&
          pitches &&
          (pitches.length === 0 ? (
            <CommonCallout text="Failed to find any pitches for ball type calibration, please contact support." />
          ) : (
            <DataCollectorHoC
              // for drawing error instead of complete
              state={props.state}
              collectionID={details.calibration_id}
              pitches={pitches}
              refType={ReferenceListType.BallTypeCalibration}
              onComplete={props.onComplete}
            />
          ))}
      </Flex>
    </ErrorBoundary>
  );
};
