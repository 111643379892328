import { Box, Code, Flex, Heading } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { CommonCallout } from 'components/common/callouts';
import { CommonDialog } from 'components/common/dialogs';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonDateInput } from 'components/common/form/date';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import { PlotModelMetrics } from 'components/sections/admin-portal/machine-models/metrics/plot-model-metrics';
import { PrintMetric } from 'components/sections/admin-portal/machine-models/metrics/print-metric';
import { SummarizeModelMetrics } from 'components/sections/admin-portal/machine-models/metrics/summarize-model-metrics';
import { IMachineModelsContext } from 'contexts/admin/machine-models.context';
import { IMachinesContext } from 'contexts/admin/machines.context';
import { addMonths, endOfToday, startOfToday } from 'date-fns';
import { t } from 'i18next';
import { DEFAULT_ACCEPT_BTN, IFullDialog } from 'interfaces/i-dialogs';
import { ModelHelper } from 'lib_ts/classes/model.helper';
import { BallType } from 'lib_ts/enums/machine.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IEvalModelResult } from 'lib_ts/interfaces/modelling/i-eval-models';
import { IGatherShotDataQuery } from 'lib_ts/interfaces/modelling/i-gather-shot-data';
import { IMachineModel } from 'lib_ts/interfaces/modelling/i-machine-model';
import React from 'react';
import {
  DEFAULT_MIN_GROUPS,
  getDefaultDataFilter,
} from 'services/admin/machine-models.service';

const COMPONENT_NAME = 'ModelPerformanceDialog';

interface IProps {
  models: IMachineModel[];

  machinesCx: IMachinesContext;
  machineModelsCx: IMachineModelsContext;
  onClose: () => void;
}

interface IState {
  start: Date;
  end: Date;

  modelEvaluations?: IEvalModelResult[];

  names: string[];
  dataFilter: IGatherShotDataQuery;
}

// aka: compare model performance
export class ModelPerformanceDialog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const firstModel = props.models[0];

    const start = addMonths(startOfToday(), -3);
    const end = new Date();

    const defaultFilter = getDefaultDataFilter(start, end);

    const dataFilter: IGatherShotDataQuery = {
      machineID: firstModel?.machineID,
      ball_type: !firstModel ? undefined : ModelHelper.getBallType(firstModel),
      start_date: defaultFilter.start_date,
      end_date: defaultFilter.end_date,
      min_groups: defaultFilter.min_groups,
      max_groups: defaultFilter.max_groups,
      limit: defaultFilter.limit,
    };

    this.state = {
      names: props.models ? props.models.map((m) => m.name) : [],

      start: start,
      end: end,

      dataFilter: dataFilter,
    };

    this.renderContent = this.renderContent.bind(this);
    this.updateMetadata = this.updateMetadata.bind(this);
  }

  private updateMetadata(filter: IGatherShotDataQuery) {
    this.setState({
      dataFilter: {
        ...this.state.dataFilter,
        ...filter,
      },
    });
  }

  private renderContent() {
    if (this.state.modelEvaluations && this.state.modelEvaluations.length > 0) {
      return (
        <Flex direction="column" gap={RADIX.FLEX.GAP.MD}>
          <Heading size={RADIX.HEADING.SIZE.SM}>Results</Heading>

          <PlotModelMetrics metrics={this.state.modelEvaluations} />

          <SummarizeModelMetrics metrics={this.state.modelEvaluations} />

          {this.state.modelEvaluations.map((m, i) => (
            <PrintMetric key={`print-metric-${i}`} metric={m} />
          ))}
        </Flex>
      );
    }

    return (
      <CommonFormGrid columns={3}>
        {this.props.models.length > 1 && (
          <Box>
            <Heading size={RADIX.HEADING.SIZE.SM}>
              Models to be compared:
            </Heading>

            <ul>
              {this.props.models.map((m) => (
                <li key={`model-${m._id}`}>{m.name}</li>
              ))}
            </ul>
          </Box>
        )}

        {this.props.models.length === 1 && (
          <Box>
            <Heading size={RADIX.HEADING.SIZE.SM}>Model:</Heading>

            <ul>
              <li> Name: {this.props.models[0].name} </li>
              <li>
                ID: <Code>{this.props.models[0]._id}</Code>
              </li>
            </ul>
          </Box>
        )}

        <Box gridColumn="span 3">
          <CommonCallout
            text={`Use the form below to determine which pitches should be used for evaluating the ${
              this.props.models.length === 1 ? 'model' : 'models'
            }.`}
          />
        </Box>

        <Box gridColumn="1">
          <CommonSearchInput
            id="machineID"
            label="common.machine"
            values={
              this.state.dataFilter.machineID
                ? [this.state.dataFilter.machineID]
                : []
            }
            options={this.props.machinesCx.machines.map((o) => ({
              label: `${o.machineID} (${o.nickname ?? 'no nickname'})`,
              value: o.machineID,
            }))}
            onChange={(machineIDs) => {
              this.updateMetadata({
                machineID: machineIDs[0],
              });
            }}
          />
        </Box>
        <CommonSelectInput
          id="model-performance-ball"
          name="ball_type"
          label="common.ball-type"
          options={Object.values(BallType).map((m) => ({
            label: m,
            value: m,
          }))}
          optional
          value={this.state.dataFilter.ball_type}
          onChange={(v) => this.updateMetadata({ ball_type: v as BallType })}
        />
        <CommonTextInput
          id="create-model-collectionID"
          label="Collection ID"
          name="collectionID"
          value={this.state.dataFilter.collectionID}
          onChange={(v) =>
            this.updateMetadata({
              collectionID: v,
            })
          }
          hint_md='Like "AAAAAAAA"'
          optional
        />
        <CommonDateInput
          id="model-performance-start"
          label="common.start-date"
          defaultValue={this.state.start}
          maxDate={this.state.end}
          onChange={(date) => {
            if (!date) {
              NotifyHelper.warning({
                message_md: t('common.check-inputs-msg'),
              });
              return;
            }

            this.setState({
              start: date,
            });

            this.updateMetadata({
              start_date: date.toISOString(),
            });
          }}
          showTime
        />
        <CommonDateInput
          id="model-performance-end"
          label="common.end-date"
          defaultValue={this.state.end}
          minDate={this.state.start}
          maxDate={endOfToday()}
          onChange={(date) => {
            if (!date) {
              NotifyHelper.warning({
                message_md: t('common.check-inputs-msg'),
              });
              return;
            }

            this.setState({
              end: date,
            });

            this.updateMetadata({
              end_date: date.toISOString(),
            });
          }}
          showTime
        />
        <CommonTextInput
          id="model-performance-session"
          label="Session"
          name="session"
          value={this.state.dataFilter.session}
          onChange={(v) => this.updateMetadata({ session: v })}
          hint_md='Like "AAAAAAAA-...-BBBB.X"'
          optional
        />

        <Box gridColumn="1">
          <CommonTextInput
            id="model-performance-min-groups"
            label="Min Groups"
            type="number"
            name="min_groups"
            value={this.state.dataFilter?.min_groups?.toString()}
            placeholder="Type an integer value"
            onOptionalNumericChange={(v) => {
              this.updateMetadata({
                min_groups: v === undefined ? undefined : Math.round(v),
              });
            }}
            hint_md="Minimum number of unique target machine states."
          />
        </Box>
        <CommonTextInput
          id="model-performance-max-groups"
          label="Max Groups"
          type="number"
          name="max_groups"
          value={this.state.dataFilter?.max_groups?.toString()}
          placeholder="Type an integer value"
          onOptionalNumericChange={(e) => {
            this.updateMetadata({
              max_groups: e === undefined ? undefined : Math.round(e),
            });
          }}
          hint_md="Maximum number of unique target machine states."
          optional
        />
        <CommonTextInput
          id="model-performance-record-limit"
          label="Record Limit"
          type="number"
          name="limit"
          value={this.state.dataFilter?.limit?.toString()}
          placeholder="Type an integer value"
          onOptionalNumericChange={(e) => {
            this.updateMetadata({
              limit: e === undefined ? undefined : Math.round(e),
            });
          }}
          hint_md="Query limit on maximum number of records to use."
          optional
        />
      </CommonFormGrid>
    );
  }

  render() {
    const mergeProps: IFullDialog = {
      identifier: COMPONENT_NAME,
      title:
        this.props.models.length === 1 ? 'Model Performance' : 'Compare Models',
      width: RADIX.DIALOG.WIDTH.XL,
      loading: this.props.machineModelsCx.loading,
      content: this.renderContent(),
      buttons: [
        {
          ...DEFAULT_ACCEPT_BTN,
          invisible:
            this.state.modelEvaluations &&
            this.state.modelEvaluations.length > 0,
          onClick: () => {
            if (!this.state.dataFilter.machineID) {
              NotifyHelper.warning({
                message_md: 'Please select a machine and try again.',
              });
              return;
            }

            if (!this.state.dataFilter.ball_type) {
              NotifyHelper.warning({
                message_md: 'Please select a ball type and try again.',
              });
              return;
            }

            if (
              this.state.dataFilter.min_groups === undefined ||
              this.state.dataFilter.min_groups < DEFAULT_MIN_GROUPS
            ) {
              NotifyHelper.warning({
                message_md: `Please provide a min groups value that is at least ${DEFAULT_MIN_GROUPS} and try again.`,
              });
              return;
            }

            this.props.machineModelsCx
              .evalModelMetrics(
                this.props.models.map((m) => m._id),
                this.state.dataFilter
              )
              .then((results) => {
                this.setState({
                  modelEvaluations: results,
                });
              });
          },
        },
      ],
      onClose: () => this.props.onClose(),
    };

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <CommonDialog {...mergeProps} />
      </ErrorBoundary>
    );
  }
}
