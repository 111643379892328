import { Box, Flex } from '@radix-ui/themes';
import { TrainingHelper } from 'classes/helpers/training-helper';
import { ErrorBoundary } from 'components/common/error-boundary';
import { DataCollectorPlateView } from 'components/machine/dialogs/installation/steps/data-collector/plate';
import { DataCollectorStatusBarHoC } from 'components/machine/dialogs/installation/steps/data-collector/status-bar';
import { AimingContext, AimingProvider } from 'contexts/aiming.context';
import { MachineContext } from 'contexts/machine.context';
import { TrainingProvider } from 'contexts/training.context';
import { DataCollectionStep } from 'enums/data-collector.enums';
import { SubStepState } from 'enums/installation';
import { ResetPlateMode } from 'enums/machine.enums';
import { t } from 'i18next';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { getMSFromMSDict } from 'lib_ts/classes/ms.helper';
import { ReferenceListType } from 'lib_ts/enums/pitches.enums';
import { IPitch } from 'lib_ts/interfaces/pitches';
import {
  IAggregateCollectionResult,
  IMachineShot,
} from 'lib_ts/interfaces/training/i-machine-shot';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ShotsService } from 'services/shots.service';

const COMPONENT_NAME = 'DataCollector';

interface IProps {
  state: SubStepState;
  collectionID: string;
  pitches: IPitch[];
  refType: ReferenceListType;

  onComplete: () => void;
}

export const DataCollectorHoC = (props: IProps) => {
  return (
    <AimingProvider>
      <DataCollector {...props} />
    </AimingProvider>
  );
};

const DataCollector = (props: IProps) => {
  const { machine } = useContext(MachineContext);
  const { setPitch } = useContext(AimingContext);

  const reqAiming = useMemo(
    () => TrainingHelper.getDataCollectorShots(props.refType, true),
    [props.refType]
  );

  const reqAnalysis = useMemo(
    () => TrainingHelper.getDataCollectorShots(props.refType, false),
    [props.refType]
  );

  const [agg, setAgg] = useState<IAggregateCollectionResult[]>();

  // load up the aggregation results at mount (e.g. for resuming)
  useEffect(() => {
    ShotsService.getInstance()
      .getCollectionAggregate({
        machineID: machine.machineID,
        collectionID: props.collectionID,
      })
      .then((results) => {
        console.debug('loading agg results at launch', results);
        setAgg(results);
      });
  }, []);

  // pitches that have been manually skipped this session
  const [skippedIDs, setSkippedIDs] = useState<string[]>([]);

  // the index of the first pitch that hasn't been skipped and there is insufficient data
  const activeIndex = useMemo(() => {
    if (!agg) {
      // wait until the aggregation query finishes instead of loading each one in quick succession
      return -1;
    }

    const incompleteIndex = props.pitches.findIndex((p, i) => {
      if (skippedIDs.includes(p._id)) {
        return false;
      }

      const shots = agg?.find((a) => a.pitch_id === p._id);

      if (!shots) {
        // no shots collected yet
        console.debug(`no shots for pitch ${i} (${p._id})`);
        return true;
      }

      if (shots.aiming < reqAiming) {
        console.debug(
          `insufficient aiming shots for pitch ${i} (found ${shots.aiming}, needs ${reqAiming})`
        );
        return true;
      }

      if (shots.analysis < reqAnalysis) {
        console.debug(
          `insufficient analysis shots for pitch ${i} (found ${shots.analysis}, needs ${reqAnalysis})`
        );
        return true;
      }

      // pitch has sufficient shots
      return false;
    });

    if (incompleteIndex !== -1) {
      // there is at least one pitch that isn't done yet
      console.debug(`found incomplete pitch at ${incompleteIndex}`);
      return incompleteIndex;
    }

    // default to the final pitch
    return props.pitches.length - 1;
  }, [props.pitches, skippedIDs, agg, reqAiming, reqAnalysis]);

  const activePitch = useMemo<IPitch | undefined>(() => {
    const output = props.pitches[activeIndex];
    console.debug(`changing activePitch to ${output?.name} (${activeIndex})`);
    return output;
  }, [props.pitches, activeIndex]);

  // update this whenever a successful training msg comes through to trigger a reload
  const [lastFetched, setLastFetched] = useState(Date.now());

  const [loadingShots, setLoadingShots] = useState(false);

  // use state for this to be more selective if/when we update aimingShots
  // e.g. we reloaded shots for more analysis shots but shots for aiming didn't change
  const [allShots, setAllShots] = useState<IMachineShot[]>([]);
  const [aimingShots, setAimingShots] = useState<IMachineShot[]>([]);
  const [analysisShots, setAnalysisShots] = useState<IMachineShot[]>([]);

  useEffect(() => {
    if (!activePitch) {
      console.debug('no active pitch');
      setAllShots([]);
      setAimingShots([]);
      setAnalysisShots([]);
      return;
    }

    const activeHash = getMSFromMSDict(activePitch, machine).ms?.matching_hash;

    if (!activeHash) {
      console.debug('no hash for active pitch');
      setAllShots([]);
      setAimingShots([]);
      setAnalysisShots([]);
      return;
    }

    setLoadingShots(true);

    // load the shots for the new active pitch
    ShotsService.getInstance()
      .getCollectionMatches({
        machineID: machine.machineID,
        collectionID: props.collectionID,
        matching_hash: activeHash,
      })
      .then((results) => {
        console.debug({
          event: 'fetched shots for active pitch',
          pitch: activePitch.name,
          shots: results,
        });

        setAllShots(results);
        setAimingShots(results.filter((m) => m.aiming));
        setAnalysisShots(results.filter((m) => !m.aiming));

        // also updates agg after every reload
        const newEntry: IAggregateCollectionResult = {
          pitch_id: activePitch._id,
          matching_hash: activeHash,
          total: results.length,
          aiming: results.filter((s) => s.aiming).length,
          analysis: results.filter((s) => !s.aiming).length,
        };

        if (agg) {
          const nextAgg = [
            ...agg.filter((a) => a.pitch_id !== activePitch._id),
            newEntry,
          ];

          setAgg(nextAgg);
        }
      })
      .finally(() => setLoadingShots(false));
  }, [props.collectionID, machine, activePitch, lastFetched]);

  const shotCount = useMemo(() => {
    if (activeIndex === -1 || !activePitch) {
      // avoid showing pitch 0 of X
      return 1;
    }

    const completeOrSkipped = activeIndex * (reqAiming + reqAnalysis);

    const current =
      Math.min(reqAiming, aimingShots.length) +
      Math.min(reqAnalysis, analysisShots.length);

    return completeOrSkipped + (loadingShots ? 0 : current);
  }, [
    activeIndex,
    activePitch,
    loadingShots,
    aimingShots,
    analysisShots,
    reqAiming,
    reqAnalysis,
  ]);

  const totalShots = useMemo(
    () => (reqAiming + reqAnalysis) * props.pitches.length,
    [reqAiming, reqAnalysis, props.pitches]
  );

  const step = useMemo(() => {
    const pitch = props.pitches[activeIndex];

    if (!pitch) {
      return DataCollectionStep.AimingShots;
    }

    if (reqAiming > aimingShots.length) {
      return DataCollectionStep.AimingShots;
    }

    if (reqAnalysis > analysisShots.length) {
      return DataCollectionStep.AnalysisShots;
    }

    return DataCollectionStep.PitchComplete;
  }, [
    props.pitches,
    reqAiming,
    reqAnalysis,
    activeIndex,
    aimingShots,
    analysisShots,
  ]);

  const [loadKey, setLoadKey] = useState(Date.now());

  const _loadPitch = useCallback(
    (pitch: IPitch, shots: IMachineShot[], aiming: boolean) => {
      console.debug(`setting and sending aimed pitch to ${pitch.name}`, pitch);

      setPitch(pitch, {
        resetPlate: ResetPlateMode.Default,
        sendConfig: {
          collectionID: props.collectionID,
          aiming: aiming,
          training: true,
          skipPreview: false,
          trigger: `${COMPONENT_NAME} > loadPitch`,
          usingShots: shots,
        },
      });
    },
    [props.collectionID]
  );

  // user clicked to load pitch (e.g. after disconnection)
  useEffect(() => {
    if (!activePitch) {
      return;
    }

    if (loadingShots) {
      return;
    }

    _loadPitch(
      activePitch,
      aimingShots.filter((m) => m.pitch_id === activePitch._id),
      step === DataCollectionStep.AimingShots
    );
  }, [loadKey, aimingShots]);

  const isComplete = useMemo(() => {
    if (activeIndex + 1 < props.pitches.length) {
      // not on final pitch yet
      return false;
    }

    return step === DataCollectionStep.PitchComplete;
  }, [props.pitches, activeIndex, step]);

  // yuck but w/e
  useEffect(() => {
    if (!isComplete) {
      return;
    }

    props.onComplete();
  }, [isComplete]);

  const description = useMemo(() => {
    return isComplete
      ? 'common.completed-data-collection-msg'
      : 'common.wait-for-data-collection-msg';
  }, [isComplete]);

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      {/* provider used for training msg listener and failed shots warnings */}
      <TrainingProvider
        mode={undefined}
        afterTrainingMsg={(msg) => {
          console.debug('training msg received', msg);

          if (!msg.success) {
            return;
          }

          setLastFetched(Date.now());
        }}
      >
        <Flex direction="column" align="center">
          <Box>{t(description)}</Box>

          <DataCollectorPlateView
            state={props.state}
            complete={isComplete}
            shots={allShots}
          />

          {activePitch && (
            <DataCollectorStatusBarHoC
              isComplete={isComplete}
              pitch={activePitch}
              step={step}
              currentShot={shotCount}
              totalShots={totalShots}
              onLoadPitch={() => setLoadKey(Date.now())}
              onSkipPitch={() => {
                if (!activePitch) {
                  return;
                }

                setSkippedIDs(
                  ArrayHelper.unique([...skippedIDs, activePitch._id])
                );
              }}
            />
          )}
        </Flex>
      </TrainingProvider>
    </ErrorBoundary>
  );
};
